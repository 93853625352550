const tr = {
  newsletter: {
    genericError:
      'Abonelik isteğiniz işlenirken bir hata oluştu. Hata devam ederse lütfen support@fluentworlds.com ile iletişime geçin.',
    invalidEmail:
      'Geçersiz e-posta adresi. E-posta, @ içeren geçerli bir biçim olmalıdır (örneğin: e-posta@alan.com)',
    successfulSubscribe: 'Abonesin!',
    subscribeButton: 'Haberlere kayıt ol',
  },
  modalOffer: {
    title: 'Mutlu Noeller!',
    text:
      'Önde gelen dil öğrenme uygulamasında %50 indirim almak için çıkış yaptığınızda bu kuponu sağlayın!',
  },
  header: {
    home: 'Anasayfa',
    products: 'Ürünler',
    ambassador: 'Become An Ambassador',
    myAccount: 'Hesabım',
    greeting: 'Merhaba',
    signIn: 'Giriş Yap',
    signOut: 'Oturumu aç',
    signUp: 'Kayıt Ol',
    redeem: 'Redeem',
    blog: 'Blog',
  },
  blog: {
    title: 'FluentWorlds Blog',
    description: 'See what the FluentWorlds community has been talking about!',
    recent1: 'How to Introduce Yourself in English | Self Introductions',
    recent1Date: 'March 23, 2023',
    recentDescription1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    recent2: '10 Common Difficult Words to Pronounce in English',
    recent2Date: 'March 28, 2023',
    recentDescription2:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English.',
    recent3: 'English Vocabulary Practice | Thought vs. Thought',
    recent3Date: 'April 14, 2023',
    recentDescription3:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    recent4: 'Confusing English Words | Homophones Explained with Examples',
    recent4Date: 'May 19, 2023',
    recentDescription4:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand?',
    recent5: 'Confusing English Tongue Twisters',
    recent5Date: 'June 8, 2023',
    recentDescription5:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister.',
  },
  post1: {
    post1Title: 'How to Introduce Yourself in English | Self Introductions',
    post1Author: 'By Aleja Briles',
    post1Date: 'March 23, 2023',
    post1Paragraph1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    post1Paragraph2:
      'Today’s topic may seem a bit beginner, but self introductions are an essential skill that you need to know in any situation. Whether you’re meeting a person for the first time, or given an assignment and need to speak to a larger group of people, this post will help you learn the best ways to introduce yourself.',
    post1Paragraph3:
      'Let’s talk about the key elements of a self-introduction. Let’s start off with a greeting, something like, “It’s so nice to meet you” or “It’s a pleasure to meet you.” The difference between these two examples is that one is slightly more formal than the other. The first example, “It’s so nice to meet you,” is the more informal of the two and is what you’re going to hear and probably say the most often. “It’s so nice to meet you.” The second example is the more formal greeting, “It’s a pleasure to meet you.” In this instance you would say this if you’re meeting someone in a business setting or for the first time. This is especially true if it’s someone you’ve been wanting to meet for a long time. If the other person is the first person to say “It’s so nice to meet you,” you can reply with “It’s nice to meet you too.”',
    post1Paragraph4:
      'Next you will want to say your name to let them know who you are. You can say this in one of two ways. The first way is “My name is _____.” and the second is “I am _____.” So for myself, I would say, “My name is Aleja.” or I could say, “I am Aleja.” Either one of those will work in any scenario to introduce yourself.',
    post1Paragraph5:
      'Now is the fun part where you get into the details about yourself. You can share things like where you’re from, what you do for a living, what brings you to the area that you’re in etc. For example, to tell someone where you are from you can say, “I’m from ____, it’s a city in ____.” Using myself as an example, I would say, “I’m from Salt Lake City, it’s a city in Utah.”',
    post1Paragraph6:
      'The next thing you could say is, “I’m currently living in _____.” This is if you’re from a specific city but you’re not currently living there. For example “I’m from New York, but I’m currently living in Dallas.” ',
    post1Paragraph7:
      'To keep the conversation going, you can continue to ask more questions. To learn about their occupation or job you can ask “what do you do for work?” Let’s pretend they work in engineering. The ‘ing’ makes it an action so they might say, “I work in engineering” but they could also say “I work as an engineer.” Those are two ways to say the same thing. Another example is “I work in education.” Or I could say “I work as a teacher or educator.”',
    post1Paragraph8:
      '“What brings you to the area?” This is a great question to ask someone, especially if you‘re meeting them at a place where people typically vacation or travel to frequently. “What brings you to the area?” you could then respond with something like “I‘m here on vacation” or “I‘m just visiting.” That‘s a very simple answer that could include a vacation, work, visiting family etc. If you‘re in a one-on-one situation it‘s also vital that you ask the other person about themselves as well, that way it‘s a two-way conversation. If you‘re only talking about yourself it can be perceived as rude or uninterested.',
    post1Paragraph9:
      'Here are some basic questions that you can ask another person about themselves:',
    post1ListItem1: 'Where are you from?',
    post1ListItem2: 'Are you from around here?',
    post1ListItem3: 'How long have you lived here?',
    post1ListItem4: 'Could you remind me of your name?',
    post1ListItem5: 'Where do you work?',
    post1ListItem6: 'Do you like your job?',
    post1ListItem7: 'Do you like this area?',
    post1ListItem8: 'What brings you to the area?',
    post1ListItem9: 'What do you like to do for fun? ',
    post1Paragraph10:
      'Now we‘re going to learn how to exit from the conversation. You can say things like “It was so nice to meet you, I hope to see you again soon” or “It was so nice getting to know you, take care!” You want to reinforce that it was nice meeting the person, and that you hope to have interactions with them in the future.',
    post1Paragraph11:
      'Let‘s do a quick review. Your introduction or first time conversation should be somewhat short, but should open up the potential to have a longer conversation. My introduction would sound something like:',
    post1Paragraph12:
      '“Hi, my name is Aleja. It‘s so nice to meet you. I‘m here in Salt Lake City visiting some friends for fun. What brings you here? I live in Dallas, but I’m from New York. I‘ve lived there for about a year. Where are you from? It was so nice getting to know you, take care!”',
    post1Paragraph13:
      'I hope that you feel confident now in understanding the basics of a self-introduction. All of the skills we learned today can be used in multiple scenarios whether you‘re meeting someone one-on-one or giving a broader explanation about yourself. If you have any additional questions or want to hear me talk more about introductions, head on over to our youtube channel and watch our video all about how to introduce yourself!',
    post1VideoLinkText: 'https://youtu.be/Y8rfZjWlKbY',
  },
  post2: {
    post2Title: '10 Common Difficult Words to Pronounce in English',
    post2Author: 'By Aleja Briles',
    post2Date: 'March 28, 2023',
    post2Paragraph1:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English. Working on pronunciation of words is one of the most important steps to gaining confidence in speaking English fluently. If you want to hear how I pronounce these words, check out this youtube video here: ',
    post2VideoLinkText: 'https://youtu.be/UgZjlGBKi6s',
    post2Paragraph2:
      'In this post, we’re going to focus on 10 words in English that might seem very difficult to pronounce for ESL learners. My goal is to have you using these words more confidently by the end of today’s lesson. Let’s jump in!',
    post2Word1: 'Colonel (kur-nuhl):',
    post2ListItem1:
      '\nThis word is pronounced ‘kernel’. This is someone in the military. You‘re going to hear this word more often than you think, especially when referencing family, friends or acquaintances that you may know that are in the military.',
    post2Word2: 'Mischievous (mis-chee-vee-us):',
    post2ListItem2:
      '\nSometimes with this word you may hear different pronunciations depending on what region you‘re in or what country you might be in. In American English more often than not you‘re going to hear ‘mis-chee-vee-uhs’.  This is if someone is sneaky or a little bit naughty or misbehaves but in an endearing way. ',
    post2Word3: 'Drought (drowt):',
    post2ListItem3:
      '\nA drought is when there is a lack of rain or water, when the land is dry or if an area has not had rain in a long time. In this word the ‘gh’ sound is silent which makes it confusing for people trying to learn English. ',
    post2Word4: 'Scissors (si-zrz):',
    post2ListItem4:
      '\nIn the words scissors the C in this word appears to be silent and that double ‘s’ is going to give you more of a ‘z’ sound. Scissors are what we use to cut paper. I‘m sure you‘ve all seen scissors before.',
    post2Word5: 'Successful (suhk-seh-sfl): ',
    post2ListItem5:
      '\nThe word successful is definitely a mouthful. What I always encourage my students to do is to act as though you‘re smiling as you say this word. That will help you get out those letters more easily and fluently. That double C makes a ‘k’ sound. ',
    post2Word6: 'Sixth (siksth): ',
    post2ListItem6:
      '\nSixth is the number order  after fifth and before seventh. That ‘xth’ can be really difficult to pronounce. ',
    post2Word7: 'Rural (rur-uhl): ',
    post2ListItem7:
      '\nThis is a word that‘s actually pretty difficult for me to say as well so don‘t be hard on yourself if you can‘t get it. This word is ‘rural’.  This word has two of those difficult ‘er’ sounds that we have a lot in English. This is when something is out in the farmland or in ranch land. Open space is typically referred to as rural.',
    post2Word8: 'Specific (spuh-si-fuhk): ',
    post2ListItem8:
      '\nThe word specific has two of the letter ‘c’ pretty close to each other. That last ‘c’ sounds like a ‘k’ sound. This is not to be confused with words like Pacific which is the ocean. The word specific means something in particular or something that we’re trying to point out. If I say “I want this specific cookie,” I want a certain cookie. I want THAT one.',
    post2Word9: 'Temperature (tem-pruh-chr): ',
    post2ListItem9:
      '\nThis is another word that you might hear pronounced differently from region to region or across different countries. Here in America we say ‘tem-pruh-chr’ so that ‘er’ sound is not pronounced separately in temperature.',
    post2Word10: 'Often (aa-fn): ',
    post2ListItem10:
      '\nI know this word seems pretty basic but that ‘oft’ can be difficult to bring together. Often refers to something that occurs frequently or regularly. ',
    post2Paragraph3:
      'These words may not always come up in everyday conversation, but when they do, you‘re going to be prepared and be able to show off your English skills. Check back weekly for more English learning posts! See you soon! ',
  },
  post3: {
    post3Title: 'English Vocabulary Practice | Thought vs. Thought',
    post3Author: 'By Aleja Briles',
    post3Date: 'April 14, 2023',
    post3Paragraph1:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    post3Paragraph2:
      'Today we’re going to be focusing on one word. This word is used pretty often in English conversation and can be used in a few different ways. The word I am talking about is the word ‘thought’.  In English the word ‘thought’ can be used in two different contexts:',
    post3ListItem1:
      'It can mean an idea. For example “I had a thought about how we could solve this problem.”',
    post3ListItem2:
      'It can also mean something that you were thinking about in the past or that you believed in the past. For example “I thought your name was Ashley not Amanda.” ',
    post3Paragraph3:
      'To help you understand how to use this word in context, let’s go over 20 different phrases that use the word ‘thought’.',
    post3Subtitle1: '‘Thought’ as in a belief',
    post3ListItem3: 'I thought you were from New York.',
    post3ListItem4: 'I thought we were going to the beach today.',
    post3ListItem5: 'They thought I was from India but I’m from Pakistan.',
    post3ListItem6: 'We thought your friend was very nice.',
    post3ListItem7: 'Your sister thought I was already gone.',
    post3ListItem8: 'I thought that burger was delicious.',
    post3ListItem9: 'They thought the restaurant was nice.',
    post3ListItem10: 'We thought about it, but decided not to go.',
    post3ListItem11: 'He thought she had a sister.',
    post3ListItem12: 'I listened to the song and thought it was really good!',
    post3Paragraph4:
      'These phrases are all ‘thought’ in the context of something that you believed in the past. The word thought is really important in the context of these sentences. let’s move on to thought as in the idea or to think.',
    post3Subtitle2: '‘Thought’ as in an idea',
    post3ListItem13: 'Do you have any thoughts on the matter?',
    post3ListItem14: 'Does anyone have any thoughts on today’s lesson?',
    post3ListItem15: 'I thought we could all go to the park.',
    post3ListItem16: 'I never thought about a career in medicine.',
    post3ListItem17: 'She thought of her nephew when she saw this toy.',
    post3ListItem18: 'They thought it would be fun to travel together.',
    post3ListItem19: 'We thought going to a movie would be a great activity!',
    post3ListItem20: 'I thought a tutor might be helpful.',
    post3ListItem21: 'Does she have any more thoughts about the party?',
    post3ListItem22: 'I don’t have any additional thoughts.',
    post3Paragraph5:
      'There are many ways you can use the word thought. Can you think of any yourself? This week when you’re practicing using the word thought, make sure you’re able to use it in the two contexts listed above. Either something that you were thinking of in the past like “I thought about you” or an idea that you had like “I thought we could try this new restaurant.” For more explanation check out our video here: ',
    post3VideoLinkText: 'https://youtu.be/qE_NpSQftlY',
  },
  post4: {
    post4Title: 'Confusing English Words | Homophones Explained with Examples',
    post4Author: 'By Aleja Briles',
    post4Date: 'May 19, 2023',
    post4Paragraph1:
      'I’ll walk down the aisle on the tropical isle. The scent that he sent was only one cent. Wow! These sentences can be very confusing! Why? Because they are all about homophones!',
    post4Paragraph2:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand? A homophone is two or more words that have the same pronunciation but different spelling. It’s so important to know the definitions and pronunciations of these words so that you can use them correctly in your conversations and in writing. Let’s go over a few examples!',
    post4ListItem1: 'Ate vs. Eight',
    post4ListPara1:
      'Let’s start with the first ‘ate’. This is the simple past tense of the verb ‘to eat’. For example, “I ate an entire pizza and now I’m really full.” or “She ate dinner before she came.” The second eight, as in the number eight, is a noun and represents the number after seven and before nine. For example “Charles will wake up at eight o’clock in the morning.” There’s a popular children’s joke that goes like this - Why was six afraid of seven? Answer - Because seven, eight, nine. Yes, it’s a pretty bad joke but it helps us learn the homophones and how they sound very similar to each other, but have different spelling.',
    post4ListItem2: 'Bare vs Bear',
    post4ListPara2:
      'The first ‘bare’ is an adjective. If something is bare that means it’s not covered or not decorated. “Tom likes to walk around his house in his bare feet, he says it’s more comfortable than wearing shoes” or “The walls were pretty bare when we moved into the house.” The second ‘bear’ is a noun. A bear is a large mammal in the forest like a grizzly bear, black bear or a brown bear. “When you go camping you should be careful to not leave any food out with a scent because it will attract bears.” or “The bear was by the river with her cubs.” ',
    post4ListItem3: 'Buy vs By vs Bye',
    post4ListPara3:
      'Have you heard of NSYNC? They have a song called ‘Bye Bye Bye’. This is a good way to remember that there are three homophones that sound the same for bye. The first ‘buy’ is a verb and means to purchase something. It‘s probably one of the first verbs that you learned in English because it’s used so commonly. “I forgot my money at home, do you think you could buy me lunch and I‘ll pay you back?” The second form of ‘by’ is a preposition. This can be used in many different ways but it‘s commonly used to mean next to or near when describing a location. For example “The car is parked in the lot by the big light post.” It can also indicate who created something. “My favorite autobiography is ‘The Autobiography of Malcolm X,” it‘s written by Malcolm X and Alex Haley.” Now for the third form of ‘bye’. This is an exclamation or a shortening of goodbye. “I‘ve gotta go now, bye!” It is how you can end a conversation in a casual informal way.',
    post4ListItem4: 'Cell vs. Sell',
    post4ListPara4:
      'A ‘cell’ is a noun and refers to a small area or room usually in a prison. A cell can also be one of the smallest divisions of a living organism. “The prisoner spent 10 years confined to his cell” or “We were able to see the plant’s cells better under a microscope.” The second ‘sell’ is a verb meaning to exchange a product or service for money. Similar to the verb ‘buy’, it was probably one of the first verbs you learned. “We would like to sell our car but we don’t think we would get very much money for it.”',
    post4ListItem5: 'Dew vs. Do vs. Due',
    post4ListPara5:
      'This is another set of three homophones. Let‘s start with the first one ‘dew’ which is a noun. Dew is the name for the small drops of water that accumulate on plants and other objects outside during the night. “When I went outside early in the morning the dew on the grass made my shoes wet.” The second ‘do’ is a verb. This common verb is used to indicate an action but can also be an auxiliary verb. “What do you usually do on Friday nights?” Lastly our third form of ‘due’. This is an adjective and is used to indicate the deadline or final day that something can happen. It‘s also used to indicate when a baby is expected to be born. “My friend is pregnant, her baby is due in December.” or “The homework assignment is due on Monday.”',
    post4Paragraph3:
      'This was definitely a lot of information! Of course these aren’t the only homophones, there are many more than you can find with a quick Google search. Homophones can be tricky for ESL speakers, but it will really set you apart as a competent English speaker to start learning them! Good luck learning the different homophones! Start to use them in daily conversation and you will have them down in no time! Best of luck! Check out our video on homophones here: ',
    post4VideoLinkText: 'https://youtu.be/ttgFlw1YgYI',
  },
  post5: {
    post5Title: 'Confusing English Tongue Twisters',
    post5Author: 'By Aleja Briles',
    post5Date: 'June 8, 2023',
    post5Paragraph1:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister. Tongue twisters are tricky but they will force you to enunciate similar sounding words to make each phrase intelligible. Here are a couple great examples of tongue twisters in English. Read through them carefully, and then go back and try and read through more quickly while keeping the correct pronunciation of each sound.',
    post5ListItem1: 'Peter Piper',
    post5ListPara1:
      '“Peter Piper picked a peck of pickled peppers. A peck of pickled peppers Peter Piper picked. If Peter Piper picked a peck of pickled peppers, where’s the peck of pickled peppers Peter Piper picked?”',
    post5ListPara2:
      'How did you do? Were there any sounds that were difficult to pronounce? If you want to hear me pronounce these words head over to our youtube channel and watch our video (linked at the bottom of this post) all about tongue twisters. Alright, let`s try another one!',
    post5ListItem2: 'Fuzzy Wuzzy',
    post5ListPara3:
      '“Fuzzy Wuzzy was a bear. Fuzzy Wuzzy had no hair. Fuzzy Wuzzy wasn’t very fuzzy was he?”',
    post5ListPara4:
      'Great job! That one’s gonna help you practice that ‘z’ sound. If you’re up for it, try it again a little bit faster this time and see how you do! This next one is about a woodchuck and is a very common rhyme for children in America. ',
    post5ListItem3: 'WoodChuck',
    post5ListPara5:
      '“How much wood would a woodchuck chuck if a woodchuck could chuck wood? He would chuck, he would, as much as he could and chuck as much wood as a woodchuck would if a woodchuck could chuck wood.”',
    post5ListPara6:
      'That’s a long and challenging one! That one is going to help you practice the ‘uh’ sound. Try it one more time! This last one is about Betty Botter. This one is going to help you practice that double “t” and “d” sound. ',
    post5ListItem4: 'Betty Botter',
    post5ListPara7:
      '“Betty Botter bought some butter, but she said the butter was bitter. If I put it in my batter it will make my batter bitter. But a bit of better butter will make my batter better. So it was better Betty Botter bought a bit of better butter.”',
    post5ListPara8:
      'That one is so tough! Go back and practice each of these as many times as you want. Getting better at these will help you to perfect these different sounds in English. There are many more English tongue twisters that you can use to continue practicing. Just search “English tongue twisters” in Google and you will find many more! I hope this helps you with your pronunciation! Best of luck! ',
    post5Paragraph2: 'Check out the youtube video: ',
    post5VideoLinkText: 'https://youtu.be/2flHA664OA8',
  },
  perpetualLicenseFWRedeemPage: {
    // TODO
    greeting: 'Hello',
    enterCode: 'Please enter your {{vendor}} code below:',
    submit: 'SUBMIT CODE',
    goToAccount: 'GO TO ACCOUNT PAGE',
    createAccountPrompt:
      'Please create an account to redeem your {{vendor}} code',
    signUp: 'SIGN-UP',
    fallBackErrorMsg:
      'An error has occurred, please try again or reach out to support.',
    invalidCode:
      'The {{vendor}} code provided was invalid, please try entering your code below',
  },
  digitalGoRedeemPage: {
    creditCard: 'kredi kartı', // verify
    mobileCredit: 'mobil kredi ödemesi', // verify
    subscriptionDurationOne: '1 ay', // verify
    subscriptionPriceOne: '8,99 €', // verify
    continueButton: 'DEVAM ET', //verify
    selectOperator: 'Mobil operatörünüzü seçin', // verify
    creditCardFooterPrompt:
      'Hizmetin Şart ve Koşullarını ve Gizlilik Politikasını kabul ediyorum. Tüm teklifler otomatik olarak yenilenecektir.', // verify
    mobileCreditFooterPrompt:
      'Teklif her ay otomatik olarak yenilenecektir. 1 Hafta ücretsiz deneme.', // verify
  },
  paymentSuccessPage: {
    thankYou: 'Bize katıldığınız  için çok mutluyuz!',
    startExploring:
      "Üç boyutlu bir dünyayi kesfetmek ve yabancı dil öğrenme maceranıza hemen başlamak için FluentWorlds'u indirin",
    haveQuestions:
      'Tüm sorularınıziçin bize <0>support@fluentworlds.com</0> e-posta adresiyle ulaşabilirsiniz',
    goToAccount: 'Hesaba Git',
  },
  paymentCancelPage: {
    cancelMsg: 'Ödemeyi iptal etme', // verify
    noCharge:
      'Ödeme işlemi arasında iptal ettiğiniz için sizden ödeme alınmayacaktır.', // verify
    goToAccount: 'HESABA GERİ DÖN', // verify
    goBack: 'GO BACK',
  },
  accountPage: {
    manageSubscriptions: 'Üyelik ayarları',
    accountSettings: 'Hesap Ayarları',
    updateUsername: 'Kullanıcı adını güncelle',
    chooseSubscription:
      'Aşağıda yer alan paketlerden sizin için en uygun olanını seçin',
    confirmUsernameChange: 'Are you sure you want to change your username?', // TODO
    successUsernameChange: 'You have successfully changed your username', // TODO
    invalidUsernameLengthError:
      'Please enter a username between {{min}} and {{max}} characters', // TODO
  },
  supportPage: {
    title: 'Contact Us | App Support | FluentWorlds', // TODO
    description: '',
    header: 'Support',
    contactUs: 'You can contact us if you have any questions.', // TODO
    formSubmitted: 'Your message has been sent', // TODO
    formName: 'Name *', // TODO
    formEmail: 'Email *', // TODO
    formMessage: 'Message *', // TODO
    formSubmit: 'Submit', // TODO
  },
  couponPage: {
    prompt: 'Lütfen kupon kodunuzu aşağıya girin', // TODO: verify
    submit: 'KUPONU GÖNDER', // TODO: verify
    goToAccount: 'Hesaba Git',
  },
  promoPage: {
    prompt: 'Please enter your promotion code below for', // TODO: verify
    submit: 'SUBMIT PROMOTION CODE', // TODO: verify
    goToAccount: 'GO BACK TO ACCOUNT', // TODO: verify
  },
  downloadFluentworlds: {
    downloadPrompt: 'Download the FluentWorlds App',
  },
  subscriptionProducts: {
    fluentworldsSubscriptionDescription:
      'Günlük senaryoları keşfederek, sürükleyici bir 3D sistemde yeni diller öğrenin.',
    perfectaccentSubscriptionDescription:
      'Telafuzunu geliştirin ve kelime haznenizi genişletin!',
    bundleSubscriptionDescription:
      'FluentWorlds ve PerfectAccent’a sınırsız erişim.',
    buy: 'Satın al',
    manage: 'Ayarlar',
    monthly: 'Aylık',
    yearly: 'Yıllık',
    useCoupon: 'İndirim kodu kullan',
    tryForFree: '7 Day Free Trial', //TODO
    after: 'after', //TODO
  },
  subscriptionProvider: {
    fallBackErrorMsg:
      'Bir hata oluştu, lütfen tekrar deneyin ya da müşteri destek hattını arayın.',
  },
  youtubeEmbedded: {
    title: 'YouTube video player',
  },
  countdown: {
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    countdownFinished: "Time's up!",
  },
  index: {
    title: '3D Immersive online language learning for everyone | FluentWorlds',
    description:
      'Interactive online worlds for Spanish and English learning. Be immersed in the new language while receiving instructions in your native language & perfect your accent',
    keywords: '',
    experienceTheJoy: 'Hazzı yaşa',
    immersive: 'Üç boyutlu dil öğrenme',
    ourSuite: 'Evimiz',
    sweet: 'Sevimli',
    theMostExciting:
      'En heyecan verici, "immersive" ve kullanışlı dil öğrenme uygulaması',
    personalTutoring:
      'Birebir dersler ve  web konferansları bir tık uzağınızda',
    preciseGuidance: 'Yapay zeka destekli telafuz eğitimi',
    fluentWorldsAcademy: 'Fluentworlds Akademi',
    fluentWorldsAcademyAnd3Dmeet: 'FluentWorlds Akademi ve 3Dmeet',
    perfectAccent: 'Kusursuz telafuz',
    goToTheAcademy: 'Akademiye git',
    theWorldIsNotFlat: '',
    languageLearning: '',
    whyFluentWorldsHeader: 'Neden FluentWorlds?',
    whyFluentWorldsParagraph:
      'Çünkü modası geçmiş teknikler basitçe etkili değildir. 3D olarak yeni diller öğrenmenin keyfini çıkarın. Yenilikçi öğretim teknolojimiz, simüle edilmiş "gerçek dünya" ortamlarında daldırma ve etkileşimin gücünden yararlanır. Bu dönüştürücü yöntem öğrenmeyi hızlandırır ve hafızayı geliştirir!',
    learnMore: 'Daha fazla bilgi almak için buraya tıklayın',
    lifeIsNotTwoDimensional: 'Hayat iki boyutlu değil',
    webConferencing: 'Peki sizin web konferanslarınız neden iki boyutlu?',
    learningAndConferencing:
      'Öğrencileri içine alan ve onları iletşimini kurmaya teşvik eden üç boyutlu öğrenme ve konferans teknolojisi',
    howEffective: 'Özel öğretmeninizle dersleriniz ne kadar verimli?',
    ourTechnology: '',
    whyPerfectAccentHeader: 'Kusuruz bir tefafuz mu istiyorsun?',
    whyPerfectAccentParagraph:
      "Amazon'un Alexa'sini üreten araştımacıların elinden çıkan PerfectAccent sizin için yaratilan zeki, anlayışlıi ve sabırlı bir öğretmen! yabancı dil öğreniminizde kaydettiğiniz ilerlemeleyi takip etmek için dünyanın en akıllı ses dinleme ve tanıma teknolojisinden yararlanın!",
    openTheDoor: 'Kapıyı arala!',
    toYourFuture: 'Gelecekteki işin, terfin ve maaşın için…',
    incomeIncrease:
      "Çok dilli konuşmacıların %300'e varan bir gelir artışına sahip olma olasılığı daha yüksektir.",
    masterInContext:
      'Üç boyutlu, sanal dil öğrenme teknolojisini kullanarak yabancı dilinizi gerçek hayatta karşınıza çıkabilecek bir durumlar aracılığıyla öğrenin.',
    languageOfInternet:
      'Birden fazla dil konuşmak, çeşitli bağlamlarda fırsatlar açabilir: uluslararası ticaret ve birkaçını saymak gerekirse programlama.',
    jobOpportunities:
      'Çok dilli konuşmacılar daha iyi küresel iş fırsatları yaşarlar.',
    ourPlans: 'Paketlerimiz',
    chooseSubscription:
      'Aşağıda yer alan paketlerden sizin için en uygun olanını seçin',
  },
  becomeAmbassador: {
    title: 'Become an Ambassador | FluentWorlds',
    description: 'What is to be an FluentWorlds Ambassador',
    joinSectionHeader: 'Join Our Ambassador Program!',
    joinSectionText:
      'We are so excited that you are interested in joining our community of learners and helping others improve their lives through learning a new language in the Metaverse. Plus, it is free to join!',
    joinSectionButton: 'Demo our products or learn more here',
    oneOnOneSectionTitle: 'Demo Our Products',
    oneOnOneSectionWebinarHeader: 'Live Online Webinar',
    oneOnOneSectionWebinarSubHeader: '(Preferred Option)',
    oneOnOneSectionWebinarButtonLabel: 'Sign Up',
    oneOnOneSectionDemoHeader: 'One on One Demo',
    oneOnOneSectionDemoSubHeader: '(Back Up Option)',
    oneOnOneSectionDemoButtonLabel: 'Sign Up',
    oneOnOneSectionText:
      'Choose one of the following options. Our Live Webinar option offers a meeting with fellow ambassadors and includes a 3 month subscription to our products. One on One option includes 1 month free subscription to our products.',
    conditionSectionHeader: 'Why Be An Ambassador?',
    conditionFirstColumnHeader: '20%-30% Commission',
    conditionFirstColumnText:
      'Supplement your income by making up to 30% Commission',
    conditionSecondColumnHeader: 'Exclusive Discounts',
    conditionSecondColumnText:
      'As as an ambassador, you get exclusive discounts to save on your personal purchases and for your customers',
    conditionThirdColumnHeader: 'Insider Access',
    conditionThirdColumnText:
      "You'll receive exclusive updates and receive a free 3 month subscription to our Perfect Accent and FluentWorlds Apps",
    conditionForthColumnHeader: 'Features and Shoutouts',
    conditionForthColumnText:
      "We'll showcase your content on our social media and spotlight ambassadors to our community",
    applyBtnLabel: 'Apply Now',
    overviewHeader: 'Our Ambassador Program Overview',
    learningOnline: 'Learning Online',
    howToApply: 'How To Apply',
    howToApplySteps: [
      {
        header: 'Click The Button Below',
        text:
          'Click the "Apply Now" button below. This will take you to our ambassador program application form.',
      },
      {
        header: 'Fill Out The Application Form',
        text: 'Complete the form. We review each application very carefully!',
      },
      {
        header: 'Fill Out Payment Information',
        text:
          "You won't be charged anything, we just want your preferred method of payment for your future commissions.",
      },
      {
        header: 'Wait For An Email From Us!',
        text:
          "If you're accepted to the program, you'll receive an email from us with instructions, so be on the lookout!",
      },
    ],
    signInSectionText:
      'Already a FluentWorlds Ambassador? Log in to your account here.',
    signInSectionBtnLabel: 'Sign In',
    questionsSectionText:
      'If you have any questions or want more information before joining, please email',
  },
  products: {
    title: 'Language Learning Software | 3D world | Pronunciation App',
    description:
      'Language learning softwares for exploring common interactions, places and things in FluentWorlds and then practice your accent with PerfectAccent pronunciation tool',
    fluentWorldsAcademy: 'FluentworldsAkademi',
    thankGod: "Fluentworlds'ü yarattığınız için teşekkürler!",
    signIn: 'Giriş Yap',
    englishAnywhere: 'Her zaman, her yerde yeni diller öğrenin!',
    purchaseSubscription: 'FluentWorlds üyeliği almak için buraya tıklayın.',
    fluentWorldsApp: 'FluentWorlds App',
    mostCommonlyUsedWordsHeading: 'En sık kullanılan kelimeler',
    mostCommonlyUsedWordsParagraph:
      'Gerçekçi bir video oyunu ortamında en sık kullanılan kelimeleri öğrenin.',
    threeSkillLevelsHeading: '3 Aşama',
    threeSkillLevelsParagraph:
      '3 aşamadan birini seçin: Baslangıç, Orta ve Ileri düzey ',
    practicalDictionaryHeading: 'Gündelik kelimeler sözlüğü',
    practicalDictionaryParagraph:
      'Oyun içi sözlüğümüzdeki sözcüklere ve ifadelere hızlı bir şekilde başvurun.',
    realTimePronunciationHeading: 'Anında Telafuz Analizi',
    realTimePronunciationParagraph:
      "Amazon'un Alexa'sinin üreticileri tarafından gelistirilmiş  anında telafuz analizi",
    progressTrackerHeading:
      'Track your progress and learned words through the dictionary function.',
    progressTrackerParagraph:
      'Sözlük fonksiyonunu kullanarak kaydettiğiniz ilerlemeyi ve öğrendiğiniz kelimeri takip edin',
    realLifeSituationsHeading:
      'Gerçek hayatta karşınıza çıkabilecek senaryolar',
    realLifeSituationsParagraph:
      'Gerçek hayatta karşılışabileceğiniz senayoları kullanarak  konuşma becerilerinizi ve hafızanızı geliştirin',
    testimonyAna:
      "Daha önce Duolingo ve Babbel'i denedim ancak bir süre sonra sıkıcı bulmaya ve öğrendigim kelimeleri unutmaya basladığım için kullanmayi bıraktım. FluentWorlds  benim için cok özel ve yabancı dil öğreniminde bir çığır açacğını düşünüyorum. FluentWorlds'un üç boyutlu sanal ortamında yabancı dil öğrenmek kesinlikle daha keyifli!",
    testimonyLeonardo:
      'Her hafta Ingilizce konuşma becerilerimde inanılmaz bir ilerleme kaydediyorum. FluentWorlds yabancı dil öğrenmenin en hızlı ve keyifli yolu!',
    testimonyJuan:
      'Fluentworlds uygulamasını test ettim ve inanilmaz derecede kullanışlı ve eğlenceli buldum. Kelime haznemi genişletmemde bana cok yardımcı oldu… gerçekten mükemmel bir uygulama, cok teşekkürler! ',
    startUsingFluentWorlds: 'FluentWorlds kullanmaya hemen başlayın!',
    descriptionLearnEnglish:
      'Günlük senaryoları keşfederken, sürükleyici bir 3B ortamlarda yeni diller öğrenin.',
    downloadOn: 'Uygulamayı Indir',
    noLimitationsHeading: 'Sınırlama yok',
    noLimitationsParagraph:
      'Sandalye, masa ve tahtayla sınırlı kalmayan sınıflarda öğrenmenin tadını çıkar!',
    immersiveEducationHeading:
      '3D eğitim sistemiyle öğrendigini hiçbir şeyi unutmayacaksın!',
    immersiveEducationParagraph:
      'Muhteşem sanal dünyaları sınıf arkadaslarınızla keşfedin! Bir havaalanında seyahat etmeyi, bir restorantta yemek sipariş etmeyi ya da bir mağazada yeni bir kıyafet satin almayı öğrenin. "Immersive" sanal dil öğrenme sistemimizin öğrencilerin sınıfta öğrendiklerini hatırlamalarına yardımcı olduğu kanıtlandı.',
    registerHere: 'Kayıt Olun',
    learnEnglishHeading: 'Yeni Dilleri 3D Öğrenin',
    learnEnglishParagraph:
      'Üç boyutlu sanal dünyamız sizi içine alan bir  öğrenme deneyimi yaratiyor',
    americanInstructorsHeading: 'Eğitimli Eğitmenler',
    americanInstructorsParagraph:
      'Eğitimli eğitmenlerimiz, dil öğrenme yolculuğunuzda size ve sınıfınıza rehberlik edecek.',
    acceleratedLearningHeading: 'Hızlandırılmış öğrenme',
    acceleratedLearningParagraph:
      'Üç boyutlu sanal dünyamiz ve ses tanıma teknolojimiz size daha hızlı bir öğrenme deneyimi sunuyor',
    effectiveLearningExperienceHeading: 'Daha elverişli bir öğrenme deneyimi',
    effectiveLearningExperienceParagraph:
      'Üç boyutlu sanal bir ortamda öğrenmenin geleneksel sınıflarda öğrenmekten daha elverisli oldugu kanıtlandı',
    perfectAccent: 'PerfectAccent',
    personalizedVoicePrintHeading:
      'Sizin için özel yaratılmış bir ses izine sahip olun!',
    personalizedVoicePrintParagraph:
      'Ses iziniz telafuz biçiminizin görsel bir haritasını çıkararak gelişmenizi kıyaslayabileceginiz bir baslangıç noktası oluşturyor',
    personalizedPhrasesHeading: 'Size özel yaratılmış ifadelerden yararlanın',
    personalizedPhrasesParagraph:
      'Yapay zeka sistemimiz size telafunuzu geliştirmeniz için uzerine calismaniz gereken kelime ve cumleleri veriyor',
    industrySpecificHeading:
      'Calistiginiz sektore ait kelime ve deyimleri öğrenin',
    industrySpecificParagraph:
      "Kendi isiniz ve özel hayatinizla en ilgili oldugunu dusundugunuz kelimeleri öğrenebilmeniz için Perfect Accent'de yuzlerce kelime ve deyim kategorisi va",
    perfectPronunciation:
      'Telafuzunu geliştirin ve kelime haznenizi genişletin!',
    ourPlans: 'Paketlerimiz',
    chooseSubscription:
      'Aşağıda yer alan paketlerden sizin için en uygun olanını seçin',
  },
  footer: {
    home: 'Anasayfa',
    products: 'Ürünler',
    affiliate: "FluentWorlds Ambassador'ı (Elçisi) Ol!",
    myAccount: 'Hesabım',
    allRightsReserved: 'Tüm Hakları Saklıdır',
    termsOfUse: 'Kullanım Şartları',
    privacyPolicy: 'Gizlilik Politikası',
  },
  privacyPolicy: {
    title: 'Privacy Policy | FluentWorlds',
    description:
      'Review our privacy policy for FluentWorlds online language learning',
    header: "FluentWorlds' Privacy Policy",
    privacyPreamble:
      'Virtual Immersive Educational Worlds, Inc., dba FluentWorlds (“FluentWorlds”) is a developer of technology-based language learning systems. We take the privacy of our customers seriously. We are committed to safeguarding your privacy while providing a personalized and valuable products and services.',
    overview: {
      title: 'Overview',
      one:
        'This Privacy Policy statement explains some of the data practices of FluentWorlds. We are committed to the privacy and security of the personal information we receive and process in accordance with applicable legal requirements. If you have requests concerning your personal information or questions about these practices, please contact our Chief Privacy Officer by e-mail at chiefprivacyofficer@FluentWorlds.com. We encourage you to periodically review this Privacy Policy as it may change at any time at our sole discretion.',
      two:
        'Our website contains links to third-party websites, which are not subject to this privacy policy. FluentWorlds does not endorse and is not responsible for the privacy practices or content on other websites. We recommend that you read the privacy policy of any website that you visit.',
      three:
        'In the event that you provide us with personal information for the purposes of free registration and you later make a purchase, whether directly from us or facilitated by one of our e-commerce partners, your privacy rights will be protected according to our stated policies and the stated privacy policies of our partners, which will be posted and available for your review at the time of purchase.',
    },
    infoCollected: {
      title: 'Information Collected',
      one:
        'Some of your personal information is collected by FluentWorlds. We collect some personally identifiable information (“PII”) about you (your “Data”) through: (A) the use of registration or inquiry forms; (B) the purchase process when you buy any of our products or services; or (C) the provision of your details to us either online or offline.',
      two: {
        title: 'The elements of your Data that we collect may include:',
        name: 'Name',
        jobTitle: 'Job title',
        companyName: 'Company name',
        addresses:
          'Home, shipping and billing section list item addresses, and/or phone number',
        mobilePhone: 'Mobile telephone number',
        emailAddress: 'E-mail address',
        ipAddress: 'IP address and browser information',
        paymentDetails: 'Payment details such as credit card information',
        marketData: 'Market research data such as customer usage patterns',
      },
    },
    useAndDisclosure: {
      title: 'Use and Disclosure of Personal Information',
      one: {
        title: 'We use your Data for purposes that may include:',
        orders: 'Processing orders, registrations, and inquiries',
        personalizedService:
          'Providing our customers with a personalized service',
        measuringInterest:
          'Measuring interest in and improving our website, products, and services',
        marketResearch: 'Conducting market research and surveys',
        competitions: 'Running competitions',
        infoAboutProducts:
          'Providing you with information about products and services we offer (you may stop the delivery of marketing emails by following the instructions accompanying a particular communication)',
        resolvingDisputes:
          'Resolving disputes, collecting fees, and troubleshooting problems',
      },
      two:
        'We use the information you provide to us when placing an order to complete that order. We do not share this information with outside parties, except: (A) to the extent necessary to complete that order or to provide you with services by way of our service providers (e.g., payment processing, third-party shipping, business analytics, customer support); (B) in accordance with legal and regulatory requirements; (C) as necessary, in FluentWorlds’ sole discretion, to protect the perceived rights, safety and property of FluentWorlds, users of our websites, and the public; or (D) with affiliate or subsidiary entities of FluentWorlds to manage and control Data as joint users. Other than as set out above, you will be notified when PII about you will be shared with third-parties and you will have an opportunity to choose not to have us share such information.',
      three:
        'Also, we use information we gather in aggregate form so that no individual user is identified. This information may be used to: (A) aid strategic development; (B) build up marketing profiles; or (C) audit usage of the website.',
    },
    useAndDisclosureJapan: {
      title: 'Use and Disclosure of Personal Information in Japan',
      one:
        'In Japan, in addition to the foregoing, we may also share Data collected through digital distribution marketplaces like the Apple App Store with Joint Providers. “Joint Providers” are those companies that we have partnered with to offer, support, or manage FluentWorlds and/or Joint Provider Accounts, products or services, and to manage and control Data, subject to FluentWorlds and/or Joint Provider privacy policies. In Japan, the Joint Provider in charge of managing jointly used Data is Sourcenext Corporation.',
    },
    useOfCookies: {
      title: 'Use of Cookies and Other Tracking Devices',
      one:
        'We do not use any tracking devices to track your usage of the Internet on other websites not operated by FluentWorlds. We do use cookies and other tracking devices, including local shared objects, to help personalize your use of our sites. If you do not want to help us learn how to improve our website, products, offers and marketing strategy, you may choose to set your web browser to not accept cookies. The Help portion of the toolbar on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether.',
      two:
        'A cookie is a small piece of information which is sent to your computer’s hard drive by the web server so that the website can remember who you are. This information may include information relating to your use of our sites, information about your computer such as the computer’s IP address and browser type, demographic data and, if you arrived at our site via a link from a third party site, the URL of the linking page. If you are a registered user this may include your name and email address for verification purposes.',
      three:
        'We use information from cookies for purposes that may include: (A) identifying returning users and registrants; (B) enabling you to move more easily around our website; (C) tracking your use of our site to better develop our sites in accordance with your requirements; or (D) building up a demographic profile.',
      four:
        'From time to time we may permit third-party companies to set cookies on FluentWorlds websites for purposes that may include market research, revenue tracking, or to improve functionality of the website.',
    },
    securityPolicy: {
      title: 'Security Policy',
      one:
        'FluentWorlds has appropriate measures in place to help ensure that our users’ Data is protected against unauthorized access or use, alteration, unlawful or accidental destruction and accidental loss. Although we make reasonable efforts to protect your PII from loss, misuse, or alteration by third-parties, you should be aware that there is always some risk involved in transmitting information over the Internet. There is also some risk that thieves could find a way to thwart our security systems.',
    },
    dataAccess: {
      title: 'Access to Data and Contact Information',
      chiefPrivacyOfficer: 'Director de privacidad',
      one:
        'You may request a copy or send a correction of the personal information we hold about you by contacting the Chief Privacy Officer at chiefprivacyofficer@fluentworlds.com or writing to:',
      two:
        'Where required by applicable law, and notably by GDPR, you have the right to obtain confirmation of the existence of certain personal data relating to you, to verify its content, origin, and accuracy, as well as the right to access, review, port, delete, or to block or withdraw consent to the processing of certain personal data (without affecting the lawfulness of processing based on consent before its withdrawal), by contacting us. In particular, you have the right to object to our use of personal data for direct marketing and in certain other situations at any time. Contact us for more details. Please note that certain personal data may be retained as required or permitted by applicable law.',
      three:
        'If you are an end-user receiving access to our services through a Corporate, Governmental, Educational or other Organizational Enterprise Client of FluentWorlds, and you wish to request access, limit use, limit disclosure, or remove your End User personal data, please contact the Enterprise Client organization that submitted your personal data to us, and we will support them as needed in responding to your request.',
      four:
        'If you are contacting us to request information or action with respect to personal data we have collected about you, or to exercise your data protection rights, please submit your request(s) through our Chief Privacy Officer by email at chiefprivacyofficer@fluentworlds.com. If you have other questions or requests concerning your personal information, please contact us by email at chiefprivacyofficer@fluentworlds.com.',
      five:
        'As noted above, you can choose not to provide FluentWorlds with personal information, although it may be needed to register as a member on the service and to participate in certain features offered through the service. You may stop the delivery of commercial email communications that FluentWorlds sends by following the instructions accompanying a particular communication.',
      six: {
        one:
          'If you are a European resident with questions regarding your rights in personal data under GDPR, please contact the FluentWorlds’ Chief Privacy Officer by email at',
        two:
          'We may charge the allowable fee under applicable law for provision of this information.',
      },
    },
    children: {
      title: 'Children Under 13',
      one:
        'We do not intentionally collect any information on children under 13 years of age. We will undertake to delete any details (with the possible exception of the parent or guardian’s email address) of such users where a parent or guardian has notified us that any such details have been obtained.',
    },
    changes: {
      title: 'Changes to this Policy',
      one:
        'This policy is effective as of February 14, 2020. From time to time we may make changes to this Privacy Policy to reflect any changes to our privacy practices in accordance with changes in legislation, best practice, or product and service enhancements. We will notify you about material changes to this Privacy Policy by placing a prominent notice on our website.',
    },
    lastReviewed: 'Last reviewed: February 1, 2020',
    lastUpdated: 'Last updated and effective as of: February 14, 2020',
  },
  digitalGoIndex: {
    header1: 'Thank God for FluentWorlds! Thank you for creating it!',
    button: 'Get Access Now!',
    theWorldIsNotFlat: "The World isn't Flat.",
    languageLearning: "Language learning shouldn't be either.",
    whyFluentWorlds: {
      header: 'Why FluentWorlds?',
      p1: '4.9 Stars on iTunes with over 2000 ratings!',
      p2: 'Over 1 Million downloads of FluentWorlds products!',
      p3: 'Built-in voice recognition and analysis!',
      p4: 'Learn in real-life situations!',
      p5: 'Fast-track to learn new languages!',
    },
    features: 'Features',
    startUsingFluentWorlds: 'Start using FluentWorlds Now!',
    immersive:
      'Learn new languages in an immersive 3D system, as you explore everyday scenarios.',
    downloadOn: 'Download on',
  },
  testimonySection: {
    fastTrack: 'Fast-Track to Learn New Languages',
    hear: 'Hear what our customers are saying',
    testimony1:
      '"Every week, I am making tremendous progress on my English conversation. FluentWorlds is the fastest and more enjoyable way to learn English!" - Leonardo',
    testimony2:
      '  "I have tried Duolingo and Babbel but I really couldn\'t keep it up, because it\'s very dull and I start forgetting words. They are a flat learning journey. FluentWorlds is very unique to me, and I think it will be a disruption! I definitely feel more engaged to be in a 3D environment" - Ana',
    testimony3:
      '"I was testing the FluentWorlds app and I find it incredible! It\'s very useful and fun! It has been of great help to me to expand my vocabulary... It\'s amazing, thank you so much!" - Juan',
  },
  freeTrialCallToActionButton: {
    tryForFree: 'FluentWorlds 7 Gün Ücretsiz Deneyin',
    alreadyHaveProductAccess: 'Ürüne zaten erişiminiz var!',
  },
  appActions: {
    initialMessage: 'Taşıma isteği',
    returningToApp:
      'Uygulamaya Dönüyor. Uygulama yüklendikten sonra bu sayfayı kapatabilirsiniz.',
    unknownApp: 'Bilinmeyen uygulama belirtildi',
    tokenError: 'Jetonu alamadı',
    checkingStatus: 'Durumunu Kontrol Etme',
    actionStartLogin: 'Giriş yapmak için yönlendirilmesi ...',
    actionCompleteLogin: 'Bilgi Alma',
    actionStartLogout: 'Günlük ...',
    actionStartManage: 'Hesaba yönlendirilmesi ...',
    actionStartDefault:
      'Beklenmedik durum karşılaşıldı. Lütfen tekrar deneyin.',
  },
}

export default tr
