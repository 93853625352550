const es = {
  newsletter: {
    genericError:
      'Hubo un error al procesar su solicitud de suscripción. Si el error persiste, comuníquese con support@fluentworlds.com',
    invalidEmail:
      'Dirección de correo electrónico no válida. El correo electrónico debe tener un formato válido que incluya una @ (por ejemplo: email@domain.com)',
    successfulSubscribe: '¡Estás suscrito!',
    subscribeButton: 'Suscríbete al boletín',
  },
  modalOffer: {
    title: '¡Feliz Navidad!',
    text:
      '¡Proporcione este cupón cuando realice el pago para obtener un 50% de descuento en la aplicación de aprendizaje de idiomas líder!',
  },
  header: {
    home: 'Inicio',
    products: 'Productos',
    ambassador: 'Conviertete en un Embajador',
    myAccount: 'Mi cuenta',
    greeting: 'Hola',
    signIn: 'Ingresar',
    signOut: 'Desconectarse',
    signUp: 'Regístrate',
    redeem: 'Redeem',
    blog: 'Blog',
  },
  blog: {
    title: 'FluentWorlds Blog',
    description: 'See what the FluentWorlds community has been talking about!',
    recent1: 'How to Introduce Yourself in English | Self Introductions',
    recent1Date: 'March 23, 2023',
    recentDescription1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    recent2: '10 Common Difficult Words to Pronounce in English',
    recent2Date: 'March 28, 2023',
    recentDescription2:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English.',
    recent3: 'English Vocabulary Practice | Thought vs. Thought',
    recent3Date: 'April 14, 2023',
    recentDescription3:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    recent4: 'Confusing English Words | Homophones Explained with Examples',
    recent4Date: 'May 19, 2023',
    recentDescription4:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand?',
    recent5: 'Confusing English Tongue Twisters',
    recent5Date: 'June 8, 2023',
    recentDescription5:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister.',
  },
  post1: {
    post1Title: 'How to Introduce Yourself in English | Self Introductions',
    post1Author: 'By Aleja Briles',
    post1Date: 'March 23, 2023',
    post1Paragraph1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    post1Paragraph2:
      'Today’s topic may seem a bit beginner, but self introductions are an essential skill that you need to know in any situation. Whether you’re meeting a person for the first time, or given an assignment and need to speak to a larger group of people, this post will help you learn the best ways to introduce yourself.',
    post1Paragraph3:
      'Let’s talk about the key elements of a self-introduction. Let’s start off with a greeting, something like, “It’s so nice to meet you” or “It’s a pleasure to meet you.” The difference between these two examples is that one is slightly more formal than the other. The first example, “It’s so nice to meet you,” is the more informal of the two and is what you’re going to hear and probably say the most often. “It’s so nice to meet you.” The second example is the more formal greeting, “It’s a pleasure to meet you.” In this instance you would say this if you’re meeting someone in a business setting or for the first time. This is especially true if it’s someone you’ve been wanting to meet for a long time. If the other person is the first person to say “It’s so nice to meet you,” you can reply with “It’s nice to meet you too.”',
    post1Paragraph4:
      'Next you will want to say your name to let them know who you are. You can say this in one of two ways. The first way is “My name is _____.” and the second is “I am _____.” So for myself, I would say, “My name is Aleja.” or I could say, “I am Aleja.” Either one of those will work in any scenario to introduce yourself.',
    post1Paragraph5:
      'Now is the fun part where you get into the details about yourself. You can share things like where you’re from, what you do for a living, what brings you to the area that you’re in etc. For example, to tell someone where you are from you can say, “I’m from ____, it’s a city in ____.” Using myself as an example, I would say, “I’m from Salt Lake City, it’s a city in Utah.”',
    post1Paragraph6:
      'The next thing you could say is, “I’m currently living in _____.” This is if you’re from a specific city but you’re not currently living there. For example “I’m from New York, but I’m currently living in Dallas.” ',
    post1Paragraph7:
      'To keep the conversation going, you can continue to ask more questions. To learn about their occupation or job you can ask “what do you do for work?” Let’s pretend they work in engineering. The ‘ing’ makes it an action so they might say, “I work in engineering” but they could also say “I work as an engineer.” Those are two ways to say the same thing. Another example is “I work in education.” Or I could say “I work as a teacher or educator.”',
    post1Paragraph8:
      '“What brings you to the area?” This is a great question to ask someone, especially if you‘re meeting them at a place where people typically vacation or travel to frequently. “What brings you to the area?” you could then respond with something like “I‘m here on vacation” or “I‘m just visiting.” That‘s a very simple answer that could include a vacation, work, visiting family etc. If you‘re in a one-on-one situation it‘s also vital that you ask the other person about themselves as well, that way it‘s a two-way conversation. If you‘re only talking about yourself it can be perceived as rude or uninterested.',
    post1Paragraph9:
      'Here are some basic questions that you can ask another person about themselves:',
    post1ListItem1: 'Where are you from?',
    post1ListItem2: 'Are you from around here?',
    post1ListItem3: 'How long have you lived here?',
    post1ListItem4: 'Could you remind me of your name?',
    post1ListItem5: 'Where do you work?',
    post1ListItem6: 'Do you like your job?',
    post1ListItem7: 'Do you like this area?',
    post1ListItem8: 'What brings you to the area?',
    post1ListItem9: 'What do you like to do for fun? ',
    post1Paragraph10:
      'Now we‘re going to learn how to exit from the conversation. You can say things like “It was so nice to meet you, I hope to see you again soon” or “It was so nice getting to know you, take care!” You want to reinforce that it was nice meeting the person, and that you hope to have interactions with them in the future.',
    post1Paragraph11:
      'Let‘s do a quick review. Your introduction or first time conversation should be somewhat short, but should open up the potential to have a longer conversation. My introduction would sound something like:',
    post1Paragraph12:
      '“Hi, my name is Aleja. It‘s so nice to meet you. I‘m here in Salt Lake City visiting some friends for fun. What brings you here? I live in Dallas, but I’m from New York. I‘ve lived there for about a year. Where are you from? It was so nice getting to know you, take care!”',
    post1Paragraph13:
      'I hope that you feel confident now in understanding the basics of a self-introduction. All of the skills we learned today can be used in multiple scenarios whether you‘re meeting someone one-on-one or giving a broader explanation about yourself. If you have any additional questions or want to hear me talk more about introductions, head on over to our youtube channel and watch our video all about how to introduce yourself!',
    post1VideoLinkText: 'https://youtu.be/Y8rfZjWlKbY',
  },
  post2: {
    post2Title: '10 Common Difficult Words to Pronounce in English',
    post2Author: 'By Aleja Briles',
    post2Date: 'March 28, 2023',
    post2Paragraph1:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English. Working on pronunciation of words is one of the most important steps to gaining confidence in speaking English fluently. If you want to hear how I pronounce these words, check out this youtube video here: ',
    post2VideoLinkText: 'https://youtu.be/UgZjlGBKi6s',
    post2Paragraph2:
      'In this post, we’re going to focus on 10 words in English that might seem very difficult to pronounce for ESL learners. My goal is to have you using these words more confidently by the end of today’s lesson. Let’s jump in!',
    post2Word1: 'Colonel (kur-nuhl):',
    post2ListItem1:
      '\nThis word is pronounced ‘kernel’. This is someone in the military. You‘re going to hear this word more often than you think, especially when referencing family, friends or acquaintances that you may know that are in the military.',
    post2Word2: 'Mischievous (mis-chee-vee-us):',
    post2ListItem2:
      '\nSometimes with this word you may hear different pronunciations depending on what region you‘re in or what country you might be in. In American English more often than not you‘re going to hear ‘mis-chee-vee-uhs’.  This is if someone is sneaky or a little bit naughty or misbehaves but in an endearing way. ',
    post2Word3: 'Drought (drowt):',
    post2ListItem3:
      '\nA drought is when there is a lack of rain or water, when the land is dry or if an area has not had rain in a long time. In this word the ‘gh’ sound is silent which makes it confusing for people trying to learn English. ',
    post2Word4: 'Scissors (si-zrz):',
    post2ListItem4:
      '\nIn the words scissors the C in this word appears to be silent and that double ‘s’ is going to give you more of a ‘z’ sound. Scissors are what we use to cut paper. I‘m sure you‘ve all seen scissors before.',
    post2Word5: 'Successful (suhk-seh-sfl): ',
    post2ListItem5:
      '\nThe word successful is definitely a mouthful. What I always encourage my students to do is to act as though you‘re smiling as you say this word. That will help you get out those letters more easily and fluently. That double C makes a ‘k’ sound. ',
    post2Word6: 'Sixth (siksth): ',
    post2ListItem6:
      '\nSixth is the number order  after fifth and before seventh. That ‘xth’ can be really difficult to pronounce. ',
    post2Word7: 'Rural (rur-uhl): ',
    post2ListItem7:
      '\nThis is a word that‘s actually pretty difficult for me to say as well so don‘t be hard on yourself if you can‘t get it. This word is ‘rural’.  This word has two of those difficult ‘er’ sounds that we have a lot in English. This is when something is out in the farmland or in ranch land. Open space is typically referred to as rural.',
    post2Word8: 'Specific (spuh-si-fuhk): ',
    post2ListItem8:
      '\nThe word specific has two of the letter ‘c’ pretty close to each other. That last ‘c’ sounds like a ‘k’ sound. This is not to be confused with words like Pacific which is the ocean. The word specific means something in particular or something that we’re trying to point out. If I say “I want this specific cookie,” I want a certain cookie. I want THAT one.',
    post2Word9: 'Temperature (tem-pruh-chr): ',
    post2ListItem9:
      '\nThis is another word that you might hear pronounced differently from region to region or across different countries. Here in America we say ‘tem-pruh-chr’ so that ‘er’ sound is not pronounced separately in temperature.',
    post2Word10: 'Often (aa-fn): ',
    post2ListItem10:
      '\nI know this word seems pretty basic but that ‘oft’ can be difficult to bring together. Often refers to something that occurs frequently or regularly. ',
    post2Paragraph3:
      'These words may not always come up in everyday conversation, but when they do, you‘re going to be prepared and be able to show off your English skills. Check back weekly for more English learning posts! See you soon! ',
  },
  post3: {
    post3Title: 'English Vocabulary Practice | Thought vs. Thought',
    post3Author: 'By Aleja Briles',
    post3Date: 'April 14, 2023',
    post3Paragraph1:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    post3Paragraph2:
      'Today we’re going to be focusing on one word. This word is used pretty often in English conversation and can be used in a few different ways. The word I am talking about is the word ‘thought’.  In English the word ‘thought’ can be used in two different contexts:',
    post3ListItem1:
      'It can mean an idea. For example “I had a thought about how we could solve this problem.”',
    post3ListItem2:
      'It can also mean something that you were thinking about in the past or that you believed in the past. For example “I thought your name was Ashley not Amanda.” ',
    post3Paragraph3:
      'To help you understand how to use this word in context, let’s go over 20 different phrases that use the word ‘thought’.',
    post3Subtitle1: '‘Thought’ as in a belief',
    post3ListItem3: 'I thought you were from New York.',
    post3ListItem4: 'I thought we were going to the beach today.',
    post3ListItem5: 'They thought I was from India but I’m from Pakistan.',
    post3ListItem6: 'We thought your friend was very nice.',
    post3ListItem7: 'Your sister thought I was already gone.',
    post3ListItem8: 'I thought that burger was delicious.',
    post3ListItem9: 'They thought the restaurant was nice.',
    post3ListItem10: 'We thought about it, but decided not to go.',
    post3ListItem11: 'He thought she had a sister.',
    post3ListItem12: 'I listened to the song and thought it was really good!',
    post3Paragraph4:
      'These phrases are all ‘thought’ in the context of something that you believed in the past. The word thought is really important in the context of these sentences. let’s move on to thought as in the idea or to think.',
    post3Subtitle2: '‘Thought’ as in an idea',
    post3ListItem13: 'Do you have any thoughts on the matter?',
    post3ListItem14: 'Does anyone have any thoughts on today’s lesson?',
    post3ListItem15: 'I thought we could all go to the park.',
    post3ListItem16: 'I never thought about a career in medicine.',
    post3ListItem17: 'She thought of her nephew when she saw this toy.',
    post3ListItem18: 'They thought it would be fun to travel together.',
    post3ListItem19: 'We thought going to a movie would be a great activity!',
    post3ListItem20: 'I thought a tutor might be helpful.',
    post3ListItem21: 'Does she have any more thoughts about the party?',
    post3ListItem22: 'I don’t have any additional thoughts.',
    post3Paragraph5:
      'There are many ways you can use the word thought. Can you think of any yourself? This week when you’re practicing using the word thought, make sure you’re able to use it in the two contexts listed above. Either something that you were thinking of in the past like “I thought about you” or an idea that you had like “I thought we could try this new restaurant.” For more explanation check out our video here: ',
    post3VideoLinkText: 'https://youtu.be/qE_NpSQftlY',
  },
  post4: {
    post4Title: 'Confusing English Words | Homophones Explained with Examples',
    post4Author: 'By Aleja Briles',
    post4Date: 'May 19, 2023',
    post4Paragraph1:
      'I’ll walk down the aisle on the tropical isle. The scent that he sent was only one cent. Wow! These sentences can be very confusing! Why? Because they are all about homophones!',
    post4Paragraph2:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand? A homophone is two or more words that have the same pronunciation but different spelling. It’s so important to know the definitions and pronunciations of these words so that you can use them correctly in your conversations and in writing. Let’s go over a few examples!',
    post4ListItem1: 'Ate vs. Eight',
    post4ListPara1:
      'Let’s start with the first ‘ate’. This is the simple past tense of the verb ‘to eat’. For example, “I ate an entire pizza and now I’m really full.” or “She ate dinner before she came.” The second eight, as in the number eight, is a noun and represents the number after seven and before nine. For example “Charles will wake up at eight o’clock in the morning.” There’s a popular children’s joke that goes like this - Why was six afraid of seven? Answer - Because seven, eight, nine. Yes, it’s a pretty bad joke but it helps us learn the homophones and how they sound very similar to each other, but have different spelling.',
    post4ListItem2: 'Bare vs Bear',
    post4ListPara2:
      'The first ‘bare’ is an adjective. If something is bare that means it’s not covered or not decorated. “Tom likes to walk around his house in his bare feet, he says it’s more comfortable than wearing shoes” or “The walls were pretty bare when we moved into the house.” The second ‘bear’ is a noun. A bear is a large mammal in the forest like a grizzly bear, black bear or a brown bear. “When you go camping you should be careful to not leave any food out with a scent because it will attract bears.” or “The bear was by the river with her cubs.” ',
    post4ListItem3: 'Buy vs By vs Bye',
    post4ListPara3:
      'Have you heard of NSYNC? They have a song called ‘Bye Bye Bye’. This is a good way to remember that there are three homophones that sound the same for bye. The first ‘buy’ is a verb and means to purchase something. It‘s probably one of the first verbs that you learned in English because it’s used so commonly. “I forgot my money at home, do you think you could buy me lunch and I‘ll pay you back?” The second form of ‘by’ is a preposition. This can be used in many different ways but it‘s commonly used to mean next to or near when describing a location. For example “The car is parked in the lot by the big light post.” It can also indicate who created something. “My favorite autobiography is ‘The Autobiography of Malcolm X,” it‘s written by Malcolm X and Alex Haley.” Now for the third form of ‘bye’. This is an exclamation or a shortening of goodbye. “I‘ve gotta go now, bye!” It is how you can end a conversation in a casual informal way.',
    post4ListItem4: 'Cell vs. Sell',
    post4ListPara4:
      'A ‘cell’ is a noun and refers to a small area or room usually in a prison. A cell can also be one of the smallest divisions of a living organism. “The prisoner spent 10 years confined to his cell” or “We were able to see the plant’s cells better under a microscope.” The second ‘sell’ is a verb meaning to exchange a product or service for money. Similar to the verb ‘buy’, it was probably one of the first verbs you learned. “We would like to sell our car but we don’t think we would get very much money for it.”',
    post4ListItem5: 'Dew vs. Do vs. Due',
    post4ListPara5:
      'This is another set of three homophones. Let‘s start with the first one ‘dew’ which is a noun. Dew is the name for the small drops of water that accumulate on plants and other objects outside during the night. “When I went outside early in the morning the dew on the grass made my shoes wet.” The second ‘do’ is a verb. This common verb is used to indicate an action but can also be an auxiliary verb. “What do you usually do on Friday nights?” Lastly our third form of ‘due’. This is an adjective and is used to indicate the deadline or final day that something can happen. It‘s also used to indicate when a baby is expected to be born. “My friend is pregnant, her baby is due in December.” or “The homework assignment is due on Monday.”',
    post4Paragraph3:
      'This was definitely a lot of information! Of course these aren’t the only homophones, there are many more than you can find with a quick Google search. Homophones can be tricky for ESL speakers, but it will really set you apart as a competent English speaker to start learning them! Good luck learning the different homophones! Start to use them in daily conversation and you will have them down in no time! Best of luck! Check out our video on homophones here: ',
    post4VideoLinkText: 'https://youtu.be/ttgFlw1YgYI',
  },
  post5: {
    post5Title: 'Confusing English Tongue Twisters',
    post5Author: 'By Aleja Briles',
    post5Date: 'June 8, 2023',
    post5Paragraph1:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister. Tongue twisters are tricky but they will force you to enunciate similar sounding words to make each phrase intelligible. Here are a couple great examples of tongue twisters in English. Read through them carefully, and then go back and try and read through more quickly while keeping the correct pronunciation of each sound.',
    post5ListItem1: 'Peter Piper',
    post5ListPara1:
      '“Peter Piper picked a peck of pickled peppers. A peck of pickled peppers Peter Piper picked. If Peter Piper picked a peck of pickled peppers, where’s the peck of pickled peppers Peter Piper picked?”',
    post5ListPara2:
      'How did you do? Were there any sounds that were difficult to pronounce? If you want to hear me pronounce these words head over to our youtube channel and watch our video (linked at the bottom of this post) all about tongue twisters. Alright, let`s try another one!',
    post5ListItem2: 'Fuzzy Wuzzy',
    post5ListPara3:
      '“Fuzzy Wuzzy was a bear. Fuzzy Wuzzy had no hair. Fuzzy Wuzzy wasn’t very fuzzy was he?”',
    post5ListPara4:
      'Great job! That one’s gonna help you practice that ‘z’ sound. If you’re up for it, try it again a little bit faster this time and see how you do! This next one is about a woodchuck and is a very common rhyme for children in America. ',
    post5ListItem3: 'WoodChuck',
    post5ListPara5:
      '“How much wood would a woodchuck chuck if a woodchuck could chuck wood? He would chuck, he would, as much as he could and chuck as much wood as a woodchuck would if a woodchuck could chuck wood.”',
    post5ListPara6:
      'That’s a long and challenging one! That one is going to help you practice the ‘uh’ sound. Try it one more time! This last one is about Betty Botter. This one is going to help you practice that double “t” and “d” sound. ',
    post5ListItem4: 'Betty Botter',
    post5ListPara7:
      '“Betty Botter bought some butter, but she said the butter was bitter. If I put it in my batter it will make my batter bitter. But a bit of better butter will make my batter better. So it was better Betty Botter bought a bit of better butter.”',
    post5ListPara8:
      'That one is so tough! Go back and practice each of these as many times as you want. Getting better at these will help you to perfect these different sounds in English. There are many more English tongue twisters that you can use to continue practicing. Just search “English tongue twisters” in Google and you will find many more! I hope this helps you with your pronunciation! Best of luck! ',
    post5Paragraph2: 'Check out the youtube video: ',
    post5VideoLinkText: 'https://youtu.be/2flHA664OA8',
  },
  perpetualLicenseFWRedeemPage: {
    greeting: 'Hola',
    enterCode: 'Ingrese su código de {{vendor}} a continuación:',
    submit: 'SUMINISTRE EL CÓDIGO',
    goToAccount: 'IR A LA PAGINA DE LA CUENTA',
    createAccountPrompt:
      'Por favor cree una cuenta para redimir su código de {{vendor}}',
    signUp: 'INSCRIBIRSE',
    fallBackErrorMsg:
      'Se produjo un error. Vuelva a intentarlo o comuníquese con el servicio de asistencia.',
    invalidCode:
      'El código de {{vendor}} proporcionado no es válido, intente ingresando su código a continuación',
  },
  digitalGoRedeemPage: {
    creditCard: 'tarjeta de crédito', // verify
    mobileCredit: 'pago de crédito móvil', // verify
    subscriptionDurationOne: '1 mes', // verify
    subscriptionPriceOne: '8,99 €', // verify
    continueButton: 'SEGUIR', // verify
    selectOperator: 'Seleccione su operador de telefonía móvil', // verify
    creditCardFooterPrompt:
      'Acepto los T&C y la Política de privacidad del servicio. Todas las ofertas se renovarán automáticamente.', // verify
    mobileCreditFooterPrompt:
      'La oferta se renovará automáticamente cada mes. Prueba gratuita de 1 semana.', // verify
  },
  paymentSuccessPage: {
    thankYou: '¡Es genial tenerte!', // verify
    startExploring:
      'Empieza a explorar mundos 3D y acelera tu viaje de aprendizaje de idiomas descargando FluentWorlds.', // verify
    haveQuestions:
      'Si tiene alguna pregunta, envíenos un correo electrónico a <0>support@fluentworlds.com</0>', // verify
    goToAccount: 'IR A CUENTA', // verify
  },
  paymentCancelPage: {
    cancelMsg: 'Cancelando el pago', // verify
    noCharge: 'No se le cobrará ya que canceló entre el proceso de pago.', // verify
    goToAccount: 'VOLVER A LA CUENTA', // verify
    goBack: 'GO BACK',
  },
  accountPage: {
    manageSubscriptions: 'Administrar Suscripciones', // verify
    accountSettings: 'Configuraciones de la cuenta', // verify
    updateUsername: 'Actualizar nombre de usuario', // verify
    chooseSubscription: 'Elija su suscripción ideal a continuación',
    confirmUsernameChange: 'Are you sure you want to change your username?', // TODO
    successUsernameChange: 'You have successfully changed your username', // TODO
    invalidUsernameLengthError:
      'Please enter a username between {{min}} and {{max}} characters', // TODO
  },
  supportPage: {
    title: 'Contact Us | App Support | FluentWorlds', // TODO
    description: '',
    header: 'Support',
    contactUs: 'You can contact us if you have any questions.', // TODO
    formSubmitted: 'Your message has been sent', // TODO
    formName: 'Name *', // TODO
    formEmail: 'Email *', // TODO
    formMessage: 'Message *', // TODO
    formSubmit: 'Submit', // TODO
  },
  couponPage: {
    prompt: 'Ingrese su código de cupón a continuación para', // TODO: verify
    submit: 'ENVIAR CUPÓN', // TODO: verify
    goToAccount: 'VOLVER A LA CUENTA', // TODO: verify
  },
  promoPage: {
    prompt: 'Ingrese su código de promoción a continuación para', // TODO: verify
    submit: 'ENVIAR CÓDIGO DE PROMOCIÓN', // TODO: verify
    goToAccount: 'VOLVER A LA CUENTA', // TODO: verify
  },
  downloadFluentworlds: {
    downloadPrompt: 'Download the FluentWorlds App',
  },
  subscriptionProducts: {
    fluentworldsSubscriptionDescription:
      'Aprende nuevos idiomas en un sistema 3D inmersivo, explorando escenarios cotidianos.',
    perfectaccentSubscriptionDescription:
      'Perfecciona tu pronunciación y amplía tu vocabulario.',
    bundleSubscriptionDescription:
      'Acceso ilimitado a FluentWorlds y PerfectAccent.',
    buy: 'Buy',
    manage: 'Manage',
    monthly: 'mensual',
    yearly: 'anual',
    useCoupon: 'Usar cupon',
    freeTrial: 'Prueba 7 días gratis',
    after: 'después',
  },
  subscriptionProvider: {
    fallBackErrorMsg:
      'An error has occurred, please try again or reach out to support.',
  },
  youtubeEmbedded: {
    title: 'Reproductor de videos de YouTube',
  },
  countdown: {
    days: 'Días',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    countdownFinished: 'Se acabó el tiempo!',
  },
  index: {
    title: '3D Immersive online language learning for everyone | FluentWorlds',
    description:
      'Interactive online worlds for Spanish and English learning. Be immersed in the new language while receiving instructions in your native language & perfect your accent',
    keywords: '',
    experienceTheJoy: 'Disfruta la experiencia',
    immersive: 'Entrenamiento de idiomas inmersivo',
    ourSuite: 'Nuestro sistema',
    sweet: 'En Tercera Dimensión',
    theMostExciting:
      'La aplicación de aprendizaje de idiomas más emocionante, inmersiva y eficaz que se haya inventado.',
    personalTutoring:
      'Tutoría personal de expertos y atractivas conferencias web a su alcance.',
    preciseGuidance:
      'Guía para lograr un acento preciso y una pronunciación correcta, con avanzada tecnología de aprendizaje inteligente.',
    fluentWorldsAcademy: 'Academia FluentWorlds',
    fluentWorldsAcademyAnd3Dmeet: 'Academia FluentWorlds y 3Dmeet',
    perfectAccent: 'Acento perfecto',
    goToTheAcademy: 'Ir a la Academia',
    theWorldIsNotFlat: 'El Mundo no es PLANO.',
    languageLearning: 'El aprendizaje de idiomas tampoco debería ser plano.',
    whyFluentWorldsHeader: 'Por qué FluentWorlds?',
    whyFluentWorldsParagraph:
      'Porque las técnicas tradicionales de aprendizaje simplemente no son efectivas. Disfruta aprendiendo nuevos idiomas en 3D. Nuestra innovadora tecnología de enseñanza aprovecha el poder de la inmersión y la interacción en entornos simulados del "mundo real". Este método transformador acelera el aprendizaje y mejora la retención.',
    learnMore: 'Aprende más',
    lifeIsNotTwoDimensional: 'La vida no es bidimensional.',
    webConferencing: 'Porqué tus conferencias web deben ser bidimensionales?',
    learningAndConferencing:
      'Aprendizaje y conferencias tridimensionales en entornos realísticos que atraen audiencia y fomentan la interacción.',
    howEffective: 'Qué tan EFECTIVO es tu Tutor Personal?',
    ourTechnology: 'Nuestra tecnología habla por sí sola.',
    whyPerfectAccentHeader: 'Por qué Perfect Accent?',
    whyPerfectAccentParagraph:
      'Creado por el inventor de Amazon Alexa, PerfectAccent es un tutor portátil, amable y comprensible que te permite aprender a hablar con acento Estadounidense. Aprovecha el software de reconocimiento de voz más inteligente del mundo y acelera tu aprendizaje!',
    openTheDoor: 'Abre la PUERTA',
    toYourFuture: 'A un mejor trabajo, tu ascenso y un mejor salario.',
    incomeIncrease:
      'Hablantes multilingües tienen más probabilidades de obtener un aumento de ingresos de hasta un 300%.',
    masterInContext:
      'Este sistema de aprendizaje de idiomas virtual inmersivo en 3D, ayuda a las personas a perfeccionar el lenguaje en contexto.',
    languageOfInternet:
      'Hablar varios idiomas puede abrir oportunidades en una variedad de contextos: negocios internacionales y programación, por nombrar algunos.',
    jobOpportunities:
      'Hablantes multilingües experimentan mejores oportunidades laborales globales.',
    ourPlans: 'Nuestros planes',
    chooseSubscription: 'Elije tu suscripción ideal a continuación',
  },
  becomeAmbassador: {
    title: 'Conviértete en Embajador | FluentWorlds',
    description: 'Qué es ser Embajador de FluentWorlds?',
    joinSectionHeader: 'Unete a nuestro Programa de Embajadores!',
    joinSectionText:
      'Estamos muy emocionados de que desees unirte a nuestra comunidad de estudiantes y ayudar a otros a mejorar sus vidas aprendiendo un nuevo idioma en el Metaverso. Además, es gratis unirse!',
    joinSectionButton: 'Prueba nuestros productos u obtén más información aquí',
    oneOnOneSectionTitle: 'Ensaya nuestros productos',
    oneOnOneSectionWebinarHeader: 'Webinar en línea en vivo',
    oneOnOneSectionWebinarSubHeader: '(Opción preferida)',
    oneOnOneSectionWebinarButtonLabel: 'Inscribirte',
    oneOnOneSectionDemoHeader: 'Demostración uno a uno',
    oneOnOneSectionDemoSubHeader: '(Opción de respaldo)',
    oneOnOneSectionDemoButtonLabel: 'Inscribirte',
    oneOnOneSectionText:
      'Elije una de las siguientes opciones. Nuestra opción Webinar en Vivo ofrece una reunión con otros embajadores e incluye una suscripción de 3 meses a nuestros productos. La opción Uno a Uno incluye 1 mes de suscripción gratuita a nuestros productos.',
    conditionSectionHeader: 'Por qué ser un Embajador?',
    conditionFirstColumnHeader: '20% a 30% de Comisión',
    conditionFirstColumnText:
      'Suplementa tus ingresos ganando hasta un 30 % de comisión',
    conditionSecondColumnHeader: 'Descuentos Exclusivos',
    conditionSecondColumnText:
      'Como embajador obtienes descuentos exclusivos para ahorrar en tus compras personales y para tus clientes',
    conditionThirdColumnHeader: 'Acceso de Insider',
    conditionThirdColumnText:
      'Recibirás actualizaciones exclusivas y una suscripción gratuita de 3 meses a nuestras aplicaciones PerfectAccent y FluentWorlds.',
    conditionForthColumnHeader: 'Características y Comentarios',
    conditionForthColumnText:
      'Mostraremos tu contenido en nuestras redes sociales y destacaremos a los embajadores de nuestra comunidad.',
    applyBtnLabel: 'Aplica ya',
    overviewHeader: 'Descripción general de nuestro programa de embajadores',
    learningOnline: 'Aprendizaje en Línea',
    howToApply: 'Cómo Aplicar',
    howToApplySteps: [
      {
        header: 'Haga clic en el botón de abajo',
        text:
          'Haga clic en el botón "Aplicar Ya" que aparece a continuación. Esto te llevará a nuestro formulario de aplicación al programa de embajadores.',
      },
      {
        header: 'Completa el formulario de Aplicación',
        text:
          'Completa el formulario. Revisamos cada aplicación con mucho cuidado!',
      },
      {
        header: 'Completa la información de pago',
        text:
          'No se te cobrará nada, solo queremos tu método de pago preferido para pagarte tus futuras comisiones.',
      },
      {
        header: 'Espera un Correo Electrónico nuestro!',
        text:
          'Si eres aceptado en el programa, recibirás un correo electrónico nuestro con instrucciones, así que mantente atento!',
      },
    ],
    signInSectionText:
      'Ya eres embajador de FluentWorlds? Ingresa a tu cuenta aquí.',
    signInSectionBtnLabel: 'Iniciar sesión',
    questionsSectionText:
      'Si tienes alguna pregunta o deseas obtener más información antes de unirte, envía un correo electrónico',
  },
  products: {
    title: 'Language Learning Software | 3D world | Pronunciation App',
    description:
      'Language learning softwares for exploring common interactions, places and things in FluentWorlds and then practice your accent with PerfectAccent pronunciation tool',
    signIn: 'Ingresar',
    fluentWorldsAcademy: 'Academia FluentWorlds',
    thankGod: 'Gracias a Dios por FluentWorlds! Gracias por crearlo!',
    englishAnywhere:
      'Aprende nuevos idiomas en cualquier momento y en cualquier lugar!',
    purchaseSubscription: 'para comprar una suscripción a FluentWorlds!',
    fluentWorldsApp: 'Aplicación FluentWorlds',
    mostCommonlyUsedWordsHeading: 'Las palabras más utilizadas',
    mostCommonlyUsedWordsParagraph:
      'Aprenda las palabras más utilizadas en un entorno de videojuego realista.',
    threeSkillLevelsHeading: 'Tres niveles de habilidad',
    threeSkillLevelsParagraph:
      'Elije entre tres niveles de habilidad: principiante, intermedio y avanzado.',
    practicalDictionaryHeading: 'Referencia práctica del diccionario',
    practicalDictionaryParagraph:
      'Consulta rápidamente palabras y frases en nuestro léxico del juego.',
    realTimePronunciationHeading: 'Análisis de Pronunciación en Tiempo Real',
    realTimePronunciationParagraph:
      'Disfruta del análisis de pronunciación en tiempo real creado por el padre de Amazon Alexa.',
    progressTrackerHeading: 'Rastreador de progreso',
    progressTrackerParagraph:
      'Realiza un seguimiento a tu progreso y las palabras aprendidas a través de la función de diccionario.',
    realLifeSituationsHeading: 'Situaciones de la Vida Real',
    realLifeSituationsParagraph:
      'Aumenta tu capacidad para recordar y hablar en situaciones de la vida real.',
    testimonyAna:
      'He probado Duolingo y Babbel pero realmente no pude seguir, porque es muy aburrido y empiezo a olvidar las palabras. Ese es un método de aprendizaje plano. FluentWorlds es único para mí, y creo que será una disrupción! Definitivamente siento más compromiso al estar en un entorno 3D.',
    testimonyLeonardo:
      'Cada semana, estoy progresando enormemente en mi conversación en Inglés. FluentWorlds es la forma más rápida y divertida de aprender Inglés!',
    testimonyJuan:
      'Estuve probando la aplicación FluentWorlds y la encuentro increíble! Es muy útil y divertida! Ha sido de gran ayuda para mí ampliar mi vocabulario ... es fantástica, muchas gracias!',
    startUsingFluentWorlds: 'Comienza a usar FluentWorlds ahora!',
    descriptionLearnEnglish:
      'Aprende nuevos idiomas en un sistema 3D inmersivo, explorando escenarios cotidianos.',
    downloadOn: 'Descargar en',
    noLimitationsHeading: 'Sin limitaciones!',
    noLimitationsParagraph:
      'Disfruta del aprendizaje en aulas que no se limitan a sillas, mesas y pizarras.',
    immersiveEducationHeading:
      'Un sistema educativo inmersivo que no puedes olvidar!',
    immersiveEducationParagraph:
      'Explora magníficos mundos virtuales con tus compañeros de clase. Aprende a viajar desde el interior de un aeropuerto, a pedir comida desde el interior de un restaurante o a comprar nuevos artículos de moda en una tienda de ropa. Nuestro sistema de aprendizaje de idiomas virtual inmersivo está probado para ayudar a los estudiantes a recordar lo que aprenden en clase.',
    registerHere: 'Regístrate aquí',
    learnEnglishHeading: 'Aprende nuevos idiomas en 3D',
    learnEnglishParagraph:
      'La inmersión en nuestros entornos de mundos virtuales en línea crea una experiencia completa de aprendizaje de idiomas.',
    americanInstructorsHeading: 'Instructores capacitados',
    americanInstructorsParagraph:
      'Nuestros instructores capacitados lo guiarán a usted y a su clase en su viaje de aprendizaje de idiomas.',
    acceleratedLearningHeading: 'Aprendizaje acelerado',
    acceleratedLearningParagraph:
      'La inmersión más el reconocimiento de voz equivale a una experiencia de aprendizaje más rápida.',
    effectiveLearningExperienceHeading: 'Experiencia de aprendizaje más eficaz',
    effectiveLearningExperienceParagraph:
      'Se ha demostrado que el aprendizaje en 3D es más eficaz que las aulas tradicionales.',
    perfectAccent: 'Acento perfecto',
    personalizedVoicePrintHeading:
      'Recibe tu propia impresión de voz personalizada',
    personalizedVoicePrintParagraph:
      'Tu impresión de voz personalizada proporciona un mapa visual para evaluar tu pronunciación actual y crea una línea de base a partir de la cual puedes progresar.',
    personalizedPhrasesParagraph:
      'Our artificial intelligence-based system is customizable and provides the words and sentences you need most to refine your pronunciation.',
    industrySpecificHeading:
      'Aprende palabras y frases específicas de diferentes tipos de industrias',
    industrySpecificParagraph:
      'Perfect Accent incluye cientos de categorías específicas de palabras y frases para que puedas practicar con el contenido más relevante para tu vida y tu trabajo.',
    perfectPronunciation:
      'Perfecciona tu pronunciación y amplía tu vocabulario.',
    ourPlans: 'Nuestros planes',
    chooseSubscription: 'Elije tu suscripción ideal a continuación',
    features: 'Características',
  },
  footer: {
    home: 'Inicio',
    products: 'Productos',
    affiliate: 'Conviértete en afiliado',
    myAccount: 'Mi cuenta',
    allRightsReserved: 'Todos los derechos reservados',
    termsOfUse: 'Términos de uso',
    privacyPolicy: 'Política de privacidad',
  },
  privacyPolicy: {
    title: 'Privacy Policy | FluentWorlds',
    description:
      'Review our privacy policy for FluentWorlds online language learning',
    header: 'Política de privacidad de FluentWorlds',
    privacyPreamble:
      'Virtual Immersive Educational Worlds, Inc., dba FluentWorlds ("FluentWorlds") es un desarrollador de sistemas de aprendizaje de idiomas basados en tecnología. Nos tomamos muy en serio la privacidad de nuestros clientes. Estamos comprometidos a salvaguardar su privacidad mientras le brindamos productos y servicios personalizados y valiosos.',
    overview: {
      title: 'Visión general',
      one:
        'Esta declaración de Política de privacidad explica algunas de las prácticas de datos de FluentWorlds. Estamos comprometidos con la privacidad y seguridad de la información personal que recibimos y procesamos de acuerdo con los requisitos legales aplicables. Si tiene solicitudes relacionadas con su información personal o preguntas sobre estas prácticas, comuníquese con nuestro Director de Privacidad por correo electrónico a chiefprivacyofficer@FluentWorlds.com. Le recomendamos que revise periódicamente esta Política de privacidad, ya que puede cambiar en cualquier momento a nuestro exclusivo criterio.',
      two:
        'Nuestro sitio web contiene links a sitios web de terceros, que no están sujetos a esta política de privacidad. FluentWorlds no respalda ni es responsable de las prácticas de privacidad o el contenido de otros sitios web. Le recomendamos que lea la política de privacidad de cualquier sitio web que visite.',
      three:
        'En el caso de que nos proporcione información personal con fines de registro gratuito y luego realice una compra, ya sea directamente de nosotros o facilitada por uno de nuestros socios de comercio electrónico, sus derechos de privacidad estarán protegidos de acuerdo con nuestras políticas establecidas y las políticas de privacidad declaradas de nuestros socios, que se publicarán y estarán disponibles para su revisión en el momento de la compra.',
    },
    infoCollected: {
      title: 'Información recopilada',
      one:
        'Parte de tu información personal es recopilada por FluentWorlds. Recopilamos cierta información de identificación personal ("PII") sobre usted (sus "Datos") a través de: (A) el uso de formularios de registro o consulta; (B) el proceso de compra cuando compra cualquiera de nuestros productos o servicios; o (C) el suministro de sus datos en línea o fuera de línea.',
      two: {
        title: 'Los elementos de sus datos que recopilamos pueden incluir:',
        name: 'Nombre',
        jobTitle: 'Título profesional',
        companyName: 'Nombre de empresa',
        addresses:
          'Domicilio, envío y facturación Lista de sección Direcciones de artículo y / o número de teléfono',
        mobilePhone: 'Número de teléfono móvil',
        emailAddress: 'Dirección de correo electrónico',
        ipAddress: 'Dirección IP e información del navegador',
        paymentDetails:
          'Detalles de pago, como información de la tarjeta de crédito',
        marketData:
          'Datos de investigación de mercado, como patrones de uso de los clientes',
      },
    },
    useAndDisclosure: {
      title: 'Uso y divulgación de información personal',
      one: {
        title: 'Usamos sus datos para fines que pueden incluir:',
        orders: 'Procesamiento de pedidos, registros y consultas',
        personalizedService:
          'Brindar a nuestros clientes un servicio personalizado',
        measuringInterest:
          'Medir el interés y mejorar nuestro sitio web, productos y servicios',
        marketResearch: 'Realización de estudios y encuestas de mercado',
        competitions: 'Correr competiciones',
        infoAboutProducts:
          'Brindarle información sobre los productos y servicios que ofrecemos (puede detener la entrega de correos electrónicos de marketing siguiendo las instrucciones que acompañan a una comunicación en particular)',
        resolvingDisputes:
          'Resolver disputas, cobrar tarifas y solucionar problemas',
      },
      two:
        'Usamos la información que nos proporciona al realizar un pedido para completar ese pedido. No compartimos esta información con terceros, excepto: (A) en la medida necesaria para completar ese pedido o para brindarle servicios a través de nuestros proveedores de servicios (por ejemplo, procesamiento de pagos, envío de terceros, análisis de negocios, apoyo); (B) de acuerdo con los requisitos legales y reglamentarios; (C) según sea necesario, a exclusivo criterio de FluentWorlds, para proteger los derechos percibidos, la seguridad y la propiedad de FluentWorlds, los usuarios de nuestros sitios web y el público; o (D) con entidades afiliadas o subsidiarias de FluentWorlds para administrar y controlar los Datos como usuarios conjuntos. Aparte de lo establecido anteriormente, se le notificará cuando se comparta su PII con terceros y tendrá la oportunidad de elegir que no compartamos dicha información.',
      three:
        'Además, utilizamos la información que recopilamos en forma agregada para que ningún usuario individual sea identificado. Esta información se puede utilizar para: (A) ayudar al desarrollo estratégico; (B) crear perfiles de marketing; o (C) auditar el uso del sitio web.',
    },
    useAndDisclosureJapan: {
      title: 'Use and Disclosure of Personal Information in Japan',
      one:
        'In Japan, in addition to the foregoing, we may also share Data collected through digital distribution marketplaces like the Apple App Store with Joint Providers. “Joint Providers” are those companies that we have partnered with to offer, support, or manage FluentWorlds and/or Joint Provider Accounts, products or services, and to manage and control Data, subject to FluentWorlds and/or Joint Provider privacy policies. In Japan, the Joint Provider in charge of managing jointly used Data is Sourcenext Corporation.',
    },
    useOfCookies: {
      title: 'Uso de cookies y otros dispositivos de seguimiento',
      one:
        'No usamos ningún dispositivo de rastreo para rastrear su uso de Internet en otros sitios web no operados por FluentWorlds. Usamos cookies y otros dispositivos de seguimiento, incluidos los objetos compartidos locales, para ayudar a personalizar el uso de nuestros sitios. Si no desea ayudarnos a aprender cómo mejorar nuestro sitio web, productos, ofertas y estrategia de marketing, puede optar por configurar su navegador web para que no acepte cookies. La parte de Ayuda de la barra de herramientas en la mayoría de los navegadores le dirá cómo evitar que su navegador acepte nuevas cookies, cómo hacer que el navegador le notifique cuando reciba una nueva cookie o cómo deshabilitar las cookies por completo.',
      two:
        'Una cookie es una pequeña información que el servidor web envía al disco duro de su computadora para que el sitio web pueda recordar quién es usted. Esta información puede incluir información relacionada con su uso de nuestros sitios, información sobre su computadora, como la dirección IP de la computadora y el tipo de navegador, datos demográficos y, si llegó a nuestro sitio a través de un enlace de un sitio de terceros, la URL del página de enlace. Si es un usuario registrado, esto puede incluir su nombre y dirección de correo electrónico para fines de verificación.',
      three:
        'Usamos la información de las cookies para propósitos que pueden incluir: (A) identificar a los usuarios que regresan y a los registrantes; (B) permitiéndole moverse más fácilmente por nuestro sitio web; (C) rastrear su uso de nuestro sitio para desarrollar mejor nuestros sitios de acuerdo con sus requisitos; o (D) construir un perfil demográfico.',
      four:
        'De vez en cuando, podemos permitir que empresas de terceros establezcan cookies en los sitios web de FluentWorlds con fines que pueden incluir estudios de mercado, seguimiento de ingresos o para mejorar la funcionalidad del sitio web.',
    },
    securityPolicy: {
      title: 'Politica de seguridad',
      one:
        'FluentWorlds cuenta con las medidas adecuadas para ayudar a garantizar que los Datos de nuestros usuarios estén protegidos contra el acceso o uso no autorizado, alteración, destrucción ilegal o accidental y pérdida accidental. Aunque hacemos esfuerzos razonables para proteger su PII de pérdida, mal uso o alteración por parte de terceros, debe tener en cuenta que siempre existe algún riesgo al transmitir información a través de Internet. También existe el riesgo de que los ladrones encuentren una forma de frustrar nuestros sistemas de seguridad.',
    },
    dataAccess: {
      title: 'Acceso a datos e información de contacto',
      chiefPrivacyOfficer: 'Director de privacidad',
      one:
        'Puede solicitar una copia o enviar una corrección de la información personal que tenemos sobre usted comunicándose con el Director de Privacidad en chiefprivacyofficer@fluentworlds.com o escribiendo a:',
      two:
        'Cuando lo requiera la ley aplicable, y en particular el GDPR, tiene derecho a obtener confirmación de la existencia de ciertos datos personales relacionados con usted, para verificar su contenido, origen y precisión, así como el derecho a acceder, revisar, transferir , eliminar, bloquear o retirar el consentimiento para el procesamiento de ciertos datos personales (sin afectar la licitud del procesamiento basado en el consentimiento antes de su retiro), poniéndose en contacto con nosotros. En particular, tiene derecho a oponerse a nuestro uso de datos personales para marketing directo y en ciertas otras situaciones en cualquier momento. Contactenos para mas detalles. Tenga en cuenta que determinados datos personales pueden conservarse según lo requiera o permita la ley aplicable.',
      three:
        'Si usted es un usuario final que recibe acceso a nuestros servicios a través de un Cliente Corporativo, Gubernamental, Educativo u otra Empresa Organizacional de FluentWorlds, y desea solicitar acceso, limitar el uso, limitar la divulgación o eliminar sus datos personales de Usuario Final, comuníquese con la organización del Cliente empresarial que nos envió sus datos personales, y los apoyaremos según sea necesario para responder a su solicitud.',
      four:
        'Si se está comunicando con nosotros para solicitar información o tomar medidas con respecto a los datos personales que hemos recopilado sobre usted, o para ejercer sus derechos de protección de datos, envíe su solicitud a través de nuestro Director de Privacidad por correo electrónico a chiefprivacyofficer@fluentworlds.com. Si tiene otras preguntas o solicitudes relacionadas con su información personal, comuníquese con nosotros por correo electrónico a chiefprivacyofficer@fluentworlds.com.',
      five:
        'Como se indicó anteriormente, puede optar por no proporcionar información personal a FluentWorlds, aunque puede ser necesario registrarse como miembro en el servicio y participar en ciertas funciones que se ofrecen a través del servicio. Puede detener la entrega de comunicaciones comerciales por correo electrónico que FluentWorlds envía siguiendo las instrucciones que acompañan a una comunicación en particular.',
      six: {
        one:
          'Si usted es un residente europeo y tiene preguntas sobre sus derechos sobre los datos personales bajo GDPR, comuníquese con el Director de Privacidad de FluentWorlds por correo electrónico a ',
        two:
          'Podemos cobrar la tarifa permitida según la ley aplicable por el suministro de esta información.',
      },
    },
    children: {
      title: 'Niños menores de 13 años',
      one:
        'No recopilamos intencionalmente ninguna información sobre niños menores de 13 años. Nos comprometemos a eliminar cualquier detalle (con la posible excepción de la dirección de correo electrónico del padre o tutor) de dichos usuarios cuando un padre o tutor nos haya notificado que se han obtenido dichos datos.',
    },
    changes: {
      title: 'Cambios a esta política',
      one:
        'Esta política entra en vigencia a partir del 14 de febrero de 2020. De vez en cuando, podemos realizar cambios en esta Política de privacidad para reflejar cualquier cambio en nuestras prácticas de privacidad de acuerdo con los cambios en la legislación, las mejores prácticas o las mejoras de productos y servicios. Le notificaremos acerca de cambios sustanciales en esta Política de privacidad colocando un aviso destacado en nuestro sitio web.',
    },
    lastReviewed: 'Última revisión: 1 de febrero de 2020',
    lastUpdated:
      'Última actualización y vigencia a partir del: 14 de febrero de 2020',
  },
  termsOfUse: {
    title: 'Términos de Uso | FluentWorlds',
    description:
      'Review our terms of use and other legal information for FluentWorlds language learning',
    header: 'Términos de Uso',
    lastUpdated: 'Última actualización: 01 de febrero de 2017',
    overview: {
      one:
        'Lea estos Términos y condiciones ("Términos", "Términos y condiciones") detenidamente antes de usar el sitio web www.fluentworlds.com y la aplicación móvil FluentWorlds (juntos o individualmente, el "Servicio") operado por Virtual Immersive Educational Worlds, Inc ("nosotros", "nosotros" o "nuestro").',
      two:
        'Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estos Términos. Estos Términos se aplican a todos los visitantes, usuarios y otras personas que deseen acceder o utilizar el Servicio.',
      three:
        'Al acceder o utilizar el Servicio, acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los términos, entonces no tiene permiso para acceder al Servicio.',
    },
    purchases: {
      title: 'Compras',
      one:
        'Si desea comprar cualquier producto o servicio disponible a través del Servicio ("Compra"), es posible que se le solicite que proporcione cierta información relevante para su Compra, incluyendo, sin limitación, su número de tarjeta de crédito, la fecha de vencimiento de su tarjeta de crédito, su dirección de facturación y su información de envío.',
      two:
        'Usted declara y garantiza que: (i) tiene el derecho legal de utilizar cualquier tarjeta de crédito u otros métodos de pago en relación con cualquier Compra; y que (ii) la información que nos proporciona es verdadera, correcta y completa.',
      three:
        'Usted declara y garantiza que: (i) tiene el derecho legal de utilizar cualquier tarjeta de crédito u otros métodos de pago en relación con cualquier Compra; y que (ii) la información que nos proporciona es verdadera, correcta y completa.',
    },
    errorFixes: {
      title: 'Disponibilidad, errores e inexactitudes',
      one:
        'Actualizamos constantemente las ofertas de productos y servicios del Servicio. Es posible que experimentemos retrasos en la actualización de la información sobre el Servicio y en nuestra publicidad en otros sitios web. La información que se encuentra en el Servicio puede contener errores o inexactitudes y puede no estar completa o actualizada. Los productos o servicios pueden tener un precio incorrecto, describirse de manera inexacta o no estar disponibles en el Servicio y no podemos garantizar la precisión o integridad de la información que se encuentra en el Servicio.',
      two:
        'Por lo tanto, nos reservamos el derecho de cambiar o actualizar la información y corregir errores, inexactitudes u omisiones en cualquier momento sin previo aviso.',
    },
    subscriptions: {
      title: 'Suscripciones',
      one:
        'Algunas partes del Servicio se facturan por suscripción ("Suscripción (es)"). Se le facturará por adelantado de forma recurrente y periódica ("Ciclo de facturación"). Los ciclos de facturación se establecen mensualmente.',
      two:
        'Al final de cada ciclo de facturación, su suscripción se renovará automáticamente en las mismas condiciones exactas a menos que la cancele o Virtual Immersive Educational Worlds, Inc. la cancele. Puede cancelar la renovación de su suscripción a través de la página de administración de su cuenta en línea o comunicándose con el equipo de atención al cliente de Virtual Immersive Educational Worlds, Inc.',
      three:
        'Se requiere un método de pago válido, que incluya tarjeta de crédito o PayPal, para procesar el pago de su Suscripción. Deberá proporcionar a Virtual Immersive Educational Worlds, Inc. información de facturación precisa y completa, incluido el nombre completo, la dirección, el estado, el código postal, el número de teléfono y la información del método de pago válido. Al enviar dicha información de pago, autoriza automáticamente a Virtual Immersive Educational Worlds, Inc. a cobrar todas las tarifas de suscripción incurridas a través de su cuenta a dichos instrumentos de pago.',
      four:
        'En caso de que la facturación automática no se produzca por cualquier motivo, Virtual Immersive Educational Worlds, Inc emitirá una factura electrónica indicando que debe proceder manualmente, dentro de una fecha límite determinada, con el pago completo correspondiente al período de facturación indicado en la factura.',
    },
    freeTrial: {
      title: 'Prueba gratis',
      one:
        'Virtual Immersive Educational Worlds, Inc. puede, a su exclusivo criterio, ofrecer una Suscripción con una prueba gratuita durante un período de tiempo limitado ("Prueba gratuita").',
      two:
        'Es posible que deba ingresar su información de facturación para inscribirse en la prueba gratuita.',
      three:
        'Si ingresa su información de facturación al registrarse para la Prueba gratuita, Virtual Immersive Educational Worlds, Inc no le cobrará hasta que la Prueba gratuita haya expirado. El último día del período de prueba gratuito, a menos que cancele su suscripción, se le cobrarán automáticamente las tarifas de suscripción correspondientes al tipo de suscripción que haya seleccionado.',
      four:
        'En cualquier momento y sin previo aviso, Virtual Immersive Educational Worlds, Inc se reserva el derecho de (i) modificar los términos y condiciones de la oferta de prueba gratuita, o (ii) cancelar dicha oferta de prueba gratuita.',
    },
    feeChanges: {
      title: 'Cambios de tarifas',
      one:
        'Virtual Immersive Educational Worlds, Inc, a su exclusivo criterio y en cualquier momento, puede modificar las tarifas de suscripción para las suscripciones. Cualquier cambio en la tarifa de suscripción entrará en vigencia al final del ciclo de facturación vigente en ese momento.',
      two:
        'Virtual Immersive Educational Worlds, Inc. le proporcionará un aviso previo razonable de cualquier cambio en las tarifas de Suscripción para darle la oportunidad de cancelar su Suscripción antes de que dicho cambio entre en vigencia.',
      three:
        'Su uso continuado del Servicio después de que el cambio de tarifa de suscripción entre en vigencia constituye su acuerdo de pagar el monto de la tarifa de suscripción modificada.',
    },
    refunds: {
      title: 'Reembolsos',
      one:
        'Excepto cuando lo exija la ley, las tarifas de suscripción pagadas no son reembolsables.',
    },
    accounts: {
      title: 'Cuentas',
      one:
        'Cuando crea una cuenta con nosotros, garantiza que es mayor de 18 años y que la información que nos proporciona es precisa, completa y actualizada en todo momento. La información inexacta, incompleta u obsoleta puede resultar en la cancelación inmediata de su cuenta en el Servicio.',
      two:
        'Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, incluida, entre otras, la restricción de acceso a su computadora y / o cuenta. Usted admite aceptar la responsabilidad por todas y cada una de las actividades o acciones que ocurran bajo su cuenta y / o contraseña, ya sea que su contraseña sea de nuestro Servicio o de un servicio de terceros. Debe notificarnos de inmediato al tener conocimiento de cualquier violación de seguridad o uso no autorizado de su cuenta.',
      three:
        'Nos reservamos el derecho de rechazar el servicio, cancelar cuentas, eliminar o editar contenido o cancelar pedidos a nuestro exclusivo criterio.',
    },
    copyright: {
      title: 'Política de derechos de autor',
      one:
        'Respetamos los derechos de propiedad intelectual de otros. Es nuestra política responder a cualquier reclamo de que el Contenido publicado en el Servicio infringe los derechos de autor u otros derechos de propiedad intelectual ("Infracción") de cualquier persona o entidad.',
      two:
        'Si usted es propietario de los derechos de autor, o está autorizado en nombre de uno, y cree que el trabajo protegido por derechos de autor se ha copiado de una manera que constituye una infracción de derechos de autor, envíe su reclamo por correo electrónico a david.bradford@fluentWorlds.com, colocando en la línea de asunto: "Infracción de derechos de autor" e incluya en su reclamo una descripción detallada de la supuesta infracción como se detalla a continuación, en "Aviso de DMCA y procedimiento para reclamos de infracción de derechos de autor"',
      three:
        'Es posible que usted sea responsable de los daños (incluidos los costos y los honorarios de los abogados) por tergiversación o reclamos de mala fe sobre la infracción de cualquier Contenido encontrado en y / o a través del Servicio sobre sus derechos de autor.',
    },
    dmca: {
      title:
        'Aviso y procedimiento de la DMCA para reclamos por infracción de derechos de autor',
      one: {
        overview:
          'Puede enviar una notificación de conformidad con la Ley de derechos de autor Digital Millennium Copyright Act (DMCA) proporcionando a nuestro Agente de derechos de autor la siguiente información por escrito (consulte 17 U.S.C 512 (c) (3) para obtener más detalles):',
        listItem1:
          'una firma electrónica o física de la persona autorizada para actuar en nombre del propietario del interés de los derechos de autor;',
        listItem2:
          'una descripción del trabajo con derechos de autor que usted afirma que se ha infringido, incluida la URL (es decir, la dirección de la página web) de la ubicación donde existe el trabajo con derechos de autor o una copia del trabajo con derechos de autor;',
        listItem3:
          'identificación de la URL u otra ubicación específica en el Servicio donde se encuentra el material que usted afirma que infringe;',
        listItem4:
          'su dirección, número de teléfono y dirección de correo electrónico;',
        listItem5:
          'una declaración suya de que cree de buena fe que el uso en disputa no está autorizado por el propietario de los derechos de autor, su agente o la ley;',
        listItem6:
          'una declaración suya, hecha bajo pena de perjurio, de que la información anterior en su aviso es precisa y que usted es el propietario de los derechos de autor o está autorizado para actuar en nombre del propietario de los derechos de autor.',
      },
      two:
        'Puede ponerse en contacto con nuestro agente de derechos de autor por correo electrónico a david.bradford@fluentWorlds.com',
    },
    intellectualProperty: {
      title: 'Propiedad intelectual',
      one:
        'El Servicio y su contenido, características y funcionalidad originales son y seguirán siendo propiedad exclusiva de Virtual Immersive Educational Worlds, Inc y sus licenciantes. El Servicio está protegido por derechos de autor, marcas registradas y otras leyes tanto de los Estados Unidos como de países extranjeros. Nuestras marcas comerciales y nuestra imagen comercial no se pueden utilizar en relación con ningún producto o servicio sin el consentimiento previo por escrito de Virtual Immersive Educational Worlds, Inc.',
    },
    links: {
      title: 'Enlaces a otros sitios web',
      one:
        'Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por Virtual Immersive Educational Worlds, Inc.',
      two:
        'Virtual Immersive Educational Worlds, Inc no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web o servicios de terceros. No garantizamos las ofertas de ninguna de estas entidades / personas o sus sitios web.',
      three:
        'Usted reconoce y acepta que Virtual Immersive Educational Worlds, Inc no será responsable, directa o indirectamente, por ningún daño o pérdida causados o presuntamente causados por o en conexión con el uso o la confianza en dichos contenidos, bienes, o servicios disponibles en o a través de dichos sitios web o servicios de terceros.',
      four:
        'Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de los sitios web o servicios de terceros que visite.',
    },
    termination: {
      title: 'Terminación',
      one:
        'Podemos rescindir o suspender su cuenta y prohibir el acceso al Servicio de inmediato, sin previo aviso o responsabilidad, bajo nuestro exclusivo criterio, por cualquier motivo y sin limitación, incluido, entre otros, el incumplimiento de los Términos.',
      two:
        'Si desea cancelar su cuenta, simplemente puede dejar de usar el Servicio.',
      three:
        'Todas las disposiciones de los Términos que por su naturaleza deberían sobrevivir a la terminación sobrevivirán a la terminación, incluidas, entre otras, las disposiciones de propiedad, renuncias de garantía, indemnización y limitaciones de responsabilidad.',
    },
    indemnification: {
      title: 'Indemnización',
      one:
        'Usted acepta defender, indemnizar y eximir de responsabilidad a Virtual Immersive Educational Worlds, Inc y a sus licenciatarios y licenciantes, y a sus empleados, contratistas, agentes, funcionarios y directores, de y contra todas y cada una de las reclamaciones, daños, obligaciones, pérdidas, responsabilidades, costos o deudas, y gastos (incluidos, entre otros, los honorarios de abogados), que resulten o surjan de a) su uso y acceso al Servicio, por parte de usted o de cualquier persona que use su cuenta y contraseña, o b) una infracción de estos Condiciones.',
    },
    limitation: {
      title: 'Limitación de responsabilidad',
      one:
        'En ningún caso Virtual Immersive Educational Worlds, Inc, ni sus directores, empleados, socios, agentes, proveedores o afiliados, serán responsables de ningún daño indirecto, incidental, especial, consecuente o punitivo, incluyendo, sin limitación, la pérdida de ganancias, datos, uso, buena voluntad u otras pérdidas intangibles, como resultado de (i) su acceso o uso o incapacidad para acceder o utilizar el Servicio; (ii) cualquier conducta o contenido de un tercero en el Servicio; (iii) cualquier contenido obtenido del Servicio; y (iv) acceso no autorizado, uso o alteración de sus transmisiones o contenido, ya sea por garantía, contrato, agravio (incluida la negligencia) o cualquier otra teoría legal, ya sea que se nos haya informado o no de la posibilidad de dicho daño, e incluso si se determina que un remedio establecido en este documento no ha cumplido su propósito esencial.',
    },
    disclaimer: {
      title: 'Descargo de responsabilidad',
      one:
        'Su uso del Servicio es bajo su propio riesgo. El Servicio se proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD". El Servicio se proporciona sin garantías de ningún tipo, ya sean expresas o implícitas, incluidas, entre otras, las garantías implícitas de comerciabilidad, idoneidad para un propósito particular, no infracción o curso de desempeño.',
      two:
        'Virtual Immersive Educational Worlds, Inc, sus subsidiarias, afiliadas y sus otorgantes de licencias no garantizan que: a) el Servicio funcionará de manera ininterrumpida, segura o estará disponible en cualquier momento o lugar en particular; b) se corregirán los errores o defectos; c) el Servicio está libre de virus u otros componentes dañinos; o d) los resultados del uso del Servicio cumplirán con sus requisitos.',
    },
    exclusions: {
      title: 'Exclusiones',
      one:
        'Algunas jurisdicciones no permiten la exclusión de ciertas garantías o la exclusión o limitación de responsabilidad por daños consecuentes o incidentales, por lo que es posible que las limitaciones anteriores no se apliquen en su caso.',
    },
    governingLaw: {
      title: 'Ley que rige',
      one:
        'Estos Términos se regirán e interpretarán de acuerdo con las leyes de Utah, Estados Unidos, sin tener en cuenta sus disposiciones sobre conflicto de leyes.',
      two:
        'Nuestra falla en hacer cumplir cualquier derecho o disposición de estos Términos no se considerará una renuncia a esos derechos. Si alguna disposición de estos Términos se considera inválida o inaplicable por un tribunal, las disposiciones restantes de estos Términos permanecerán en vigor. Estos Términos constituyen el acuerdo completo entre nosotros con respecto a nuestro Servicio, y sustituyen y reemplazan cualquier acuerdo anterior que pudiéramos haber tenido entre nosotros con respecto al Servicio.',
    },
    changes: {
      title: 'Cambios',
      one:
        'Nos reservamos el derecho, a nuestra entera discreción, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es importante, proporcionaremos un aviso de al menos 30 días antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material se determinará a nuestro exclusivo criterio.',
      two:
        'Si continúa accediendo o utilizando nuestro Servicio después de que las revisiones entren en vigencia, usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, ya no está autorizado a utilizar el Servicio.',
    },
    contact: {
      title: 'Contáctenos',
      one:
        'Si tiene alguna pregunta sobre estos Términos, comuníquese con nosotros.',
    },
  },
  digitalGoIndex: {
    header1: 'Thank God for FluentWorlds! Thank you for creating it!',
    button: 'Get Access Now!',
    theWorldIsNotFlat: "The World isn't Flat.",
    languageLearning: "Language learning shouldn't be either.",
    whyFluentWorlds: {
      header: 'Why FluentWorlds?',
      p1: '4.9 Stars on iTunes with over 2000 ratings!',
      p2: 'Over 1 Million downloads of FluentWorlds products!',
      p3: 'Built-in voice recognition and analysis!',
      p4: 'Learn in real-life situations!',
      p5: 'Fast-track to learn new languages!',
    },
    features: 'Features',
    startUsingFluentWorlds: 'Start using FluentWorlds Now!',
    immersive:
      'Learn new languages in an immersive 3D system, as you explore everyday scenarios.',
    downloadOn: 'Download on',
  },
  testimonySection: {
    fastTrack: 'Fast-Track to Learn New Languages',
    hear: 'Hear what our customers are saying',
    testimony1:
      '"Every week, I am making tremendous progress on my English conversation. FluentWorlds is the fastest and more enjoyable way to learn English!" - Leonardo',
    testimony2:
      '  "I have tried Duolingo and Babbel but I really couldn\'t keep it up, because it\'s very dull and I start forgetting words. They are a flat learning journey. FluentWorlds is very unique to me, and I think it will be a disruption! I definitely feel more engaged to be in a 3D environment" - Ana',
    testimony3:
      '"I was testing the FluentWorlds app and I find it incredible! It\'s very useful and fun! It has been of great help to me to expand my vocabulary... It\'s amazing, thank you so much!" - Juan',
  },
  freeTrialCallToActionButton: {
    tryForFree: 'Prueba FluentWorlds 7 días gratis',
    alreadyHaveProductAccess: '¡Ya tienes acceso al producto!',
  },
  appActions: {
    initialMessage: 'Solicitud de manejo',
    returningToApp:
      'Volviendo a la aplicación. Puede cerrar esta página una vez que se carga la aplicación.',
    unknownApp: 'Aplicación desconocida especificada',
    tokenError: 'No se pudo recuperar token',
    checkingStatus: 'Estado de comprobación',
    actionStartLogin: 'Redirigiendo a Iniciar sesión ...',
    actionCompleteLogin: 'Recuperando la información',
    actionStartLogout: ' Saliendo de tu cuenta...',
    actionStartManage: ' Redirigiendo a la cuenta ...',
    actionStartDefault: 'Estado inesperado encontrado. Inténtalo de nuevo.',
  },
}

export default es
