// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-app-actions-js": () => import("./../../../src/pages/app-actions.js" /* webpackChunkName: "component---src-pages-app-actions-js" */),
  "component---src-pages-aquipago-redeem-js": () => import("./../../../src/pages/aquipago/redeem.js" /* webpackChunkName: "component---src-pages-aquipago-redeem-js" */),
  "component---src-pages-become-ambassador-js": () => import("./../../../src/pages/become-ambassador.js" /* webpackChunkName: "component---src-pages-become-ambassador-js" */),
  "component---src-pages-blog-blog-js": () => import("./../../../src/pages/blog/blog.js" /* webpackChunkName: "component---src-pages-blog-blog-js" */),
  "component---src-pages-blog-newsletter-subscribe-js": () => import("./../../../src/pages/blog/newsletter/Subscribe.js" /* webpackChunkName: "component---src-pages-blog-newsletter-subscribe-js" */),
  "component---src-pages-blog-posts-post-1-js": () => import("./../../../src/pages/blog/posts/post1.js" /* webpackChunkName: "component---src-pages-blog-posts-post-1-js" */),
  "component---src-pages-blog-posts-post-2-js": () => import("./../../../src/pages/blog/posts/post2.js" /* webpackChunkName: "component---src-pages-blog-posts-post-2-js" */),
  "component---src-pages-blog-posts-post-3-js": () => import("./../../../src/pages/blog/posts/post3.js" /* webpackChunkName: "component---src-pages-blog-posts-post-3-js" */),
  "component---src-pages-blog-posts-post-4-js": () => import("./../../../src/pages/blog/posts/post4.js" /* webpackChunkName: "component---src-pages-blog-posts-post-4-js" */),
  "component---src-pages-blog-posts-post-5-js": () => import("./../../../src/pages/blog/posts/post5.js" /* webpackChunkName: "component---src-pages-blog-posts-post-5-js" */),
  "component---src-pages-digital-go-account-js": () => import("./../../../src/pages/digital-go/account.js" /* webpackChunkName: "component---src-pages-digital-go-account-js" */),
  "component---src-pages-digital-go-index-js": () => import("./../../../src/pages/digital-go/index.js" /* webpackChunkName: "component---src-pages-digital-go-index-js" */),
  "component---src-pages-digital-go-payment-cancel-js": () => import("./../../../src/pages/digital-go/payment/cancel.js" /* webpackChunkName: "component---src-pages-digital-go-payment-cancel-js" */),
  "component---src-pages-digital-go-payment-success-js": () => import("./../../../src/pages/digital-go/payment/success.js" /* webpackChunkName: "component---src-pages-digital-go-payment-success-js" */),
  "component---src-pages-digital-go-redeem-js": () => import("./../../../src/pages/digital-go/redeem.js" /* webpackChunkName: "component---src-pages-digital-go-redeem-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ms-holdings-redeem-js": () => import("./../../../src/pages/ms-holdings/redeem.js" /* webpackChunkName: "component---src-pages-ms-holdings-redeem-js" */),
  "component---src-pages-payment-cancel-js": () => import("./../../../src/pages/payment/cancel.js" /* webpackChunkName: "component---src-pages-payment-cancel-js" */),
  "component---src-pages-payment-coupon-js": () => import("./../../../src/pages/payment/coupon.js" /* webpackChunkName: "component---src-pages-payment-coupon-js" */),
  "component---src-pages-payment-redeem-js": () => import("./../../../src/pages/payment/redeem.js" /* webpackChunkName: "component---src-pages-payment-redeem-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-smart-technologies-redeem-js": () => import("./../../../src/pages/smart-technologies/redeem.js" /* webpackChunkName: "component---src-pages-smart-technologies-redeem-js" */),
  "component---src-pages-sourcenext-redeem-js": () => import("./../../../src/pages/sourcenext/redeem.js" /* webpackChunkName: "component---src-pages-sourcenext-redeem-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

