import React, { useReducer } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from './UserContext'
import UserReducer from './UserReducer'
import {
  LOAD_USER_STATE,
  UPDATE_USERNAME,
  DELETE_USER,
  UPDATE_USER_PRODUCT_ACCESS,
  SET_USER_STATE_LOADING,
  TOGGLE_SHOW_DISCOUNT,
} from '../types'

const UserProvider = ({ children }) => {
  const initialState = {
    username: '',
    subscriptionInfo: null,
    productAccess: null,
    userStateLoading: false,
    showDiscount: false,
  }

  const [state, dispatch] = useReducer(UserReducer, initialState)

  const { user, logout, getTokenSilently } = useAuth0()

  const setUserStateLoading = (isUserStateLoading) =>
    dispatch({ type: SET_USER_STATE_LOADING, payload: isUserStateLoading })

  const displayBasicErrorHandling = () =>
    window.alert(
      'An error has occurred, please try again or reach out to support.',
    )

  const fetchUserProductAccess = async () => {
    try {
      setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const response = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              getUserProductAccess {
                fluentworlds
                perfectaccent
                fluentworldsBundle
              }
            }
          `,
        },
      })

      if (response.data.data.getUserProductAccess) {
        const { getUserProductAccess } = response.data.data

        await dispatch({
          type: UPDATE_USER_PRODUCT_ACCESS,
          payload: getUserProductAccess,
        })

        return setUserStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setUserStateLoading(false)
    }
  }

  const fetchUserFromFirestore = async () => {
    try {
      setUserStateLoading(true)

      const { sub } = user
      const accessToken = await getTokenSilently()

      const response = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              userById(id: "${sub}") {
                username
                subscriptionInfo {
                  customerId
                }
              }
            }
          `,
        },
      })

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.userById &&
        typeof response.data.data.userById.username !== 'undefined' &&
        response.data.data.userById.username !== undefined &&
        response.data.data.userById.username !== ''
      ) {
        const { userById } = response.data.data
        await dispatch({
          type: LOAD_USER_STATE,
          payload: userById,
        })

        return setUserStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setUserStateLoading(false)
    }
  }

  const updateUserName = async (newUserName) => {
    const { sub } = user

    try {
      setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            updateUser(input: {id: "${sub}", username:"${newUserName}"}) {
              id
              username
            }
          }
        `,
        },
      })

      if (
        firebaseResponse.data.data.updateUser &&
        firebaseResponse.data.data.updateUser.id &&
        firebaseResponse.data.data.updateUser.username
      ) {
        dispatch({
          type: UPDATE_USERNAME,
          payload: newUserName,
        })
        setUserStateLoading(false)
        return true
      } else {
        toast.error(firebaseResponse.data.errors[0].message)
        setUserStateLoading(false)
        return false
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      setUserStateLoading(false)
    }
  }

  const deleteUser = async () => {
    const { sub } = user
    const accessToken = await getTokenSilently()

    if (window.confirm(`Are you sure you want to delete your account?`)) {
      setUserStateLoading(true)

      await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            deleteUser(id: "${sub}") {
              id
            }
          }
            `,
        },
      })
        .then((firebaseResponse) => {
          /*
            TODO: adjust graphql query to return a valid response in data when deleting
          */

          setUserStateLoading(false)
          dispatch({
            type: DELETE_USER,
          })
          return logout()
        })
        .catch((error) => {
          console.error(error)
          displayBasicErrorHandling()
          return setUserStateLoading(false)
        })
    }
  }

  const toggleShowDiscount = async (boolValue) => {
    dispatch({
      type: TOGGLE_SHOW_DISCOUNT,
      payload: boolValue,
    })
  }

  return (
    <UserContext.Provider
      value={{
        username: state.username,
        subscriptionInfo: state.subscriptionInfo,
        productAccess: state.productAccess,
        userStateLoading: state.userStateLoading,
        showDiscount: state.showDiscount,
        fetchUserFromFirestore,
        fetchUserProductAccess,
        updateUserName,
        deleteUser,
        toggleShowDiscount,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
