import { LogglyTracker } from 'loggly-jslogger'

const SOURCE = 'fluentworlds.com'
const LEVELS = { log: 'info', warn: 'warning', error: 'error' }

const cleanDetails = (details) => {
  // this function converts any undefined valeus to the text "undefined" so they will show up in the remote logging tool
  let cleanDetailsObject = details
  if (typeof details === 'object' && details !== null && details) {
    for (const [key, value] of Object.entries(details)) {
      cleanDetailsObject[key] = value ?? 'undefined'
    }
  }
  return cleanDetailsObject
}

class RemoteLogger {
  constructor() {
    this.Logger =
      typeof LogglyTracker === 'undefined' ? [] : new LogglyTracker()
    this.Logger.push({
      logglyKey: process.env.GATSBY_REMOTE_LOGGER_CUSTOMER_TOKEN,
      sendConsoleErrors: true,
    })
  }

  log(message, detailsObject = undefined) {
    const cleanDetailsObject = cleanDetails(detailsObject)

    this.Logger.push({
      level: LEVELS.log,
      source: SOURCE,
      env: process.env.GATSBY_STATIC_DIR,
      message,
      details: cleanDetailsObject,
    })
  }

  warn(message, detailsObject = undefined) {
    const cleanDetailsObject = cleanDetails(detailsObject)

    this.Logger.push({
      level: LEVELS.warn,
      source: SOURCE,
      env: process.env.GATSBY_STATIC_DIR,
      message,
      details: cleanDetailsObject,
    })
  }

  error(message, detailsObject = undefined) {
    const cleanDetailsObject = cleanDetails(detailsObject)

    this.Logger.push({
      level: LEVELS.error,
      source: SOURCE,
      env: process.env.GATSBY_STATIC_DIR,
      message,
      details: cleanDetailsObject,
    })
  }
}

const remoteLogger = new RemoteLogger()

export default remoteLogger
