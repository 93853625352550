import React, { useReducer } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'

import { useAuth0 } from 'utils/react-auth0-spa'
import SubscriptionContext from './SubscriptionContext'
import SubscriptionReducer from './SubscriptionReducer'
import {
  FETCH_SUBSCRIPTIONS,
  FETCH_COUPON,
  SET_SUBSCRIPTION_STATE_LOADING,
} from '../types'

const DIGITAL_GO_USER = 'DIGITAL_GO'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const SubscriptionProvider = ({ children }) => {
  const initialState = {
    subscriptions: [],
    coupon: null,
    subscriptionStateLoading: false,
  }

  const [state, dispatch] = useReducer(SubscriptionReducer, initialState)

  const { getTokenSilently } = useAuth0()
  const { t } = useTranslation('subscriptionProvider')

  const setSubscriptionStateLoading = (isSubscriptionStateLoading) =>
    dispatch({
      type: SET_SUBSCRIPTION_STATE_LOADING,
      payload: isSubscriptionStateLoading,
    })

  const displayBasicErrorHandling = () => window.alert(t('fallBackErrorMsg'))

  const fetchAvailableSitePlans = async () => {
    try {
      setSubscriptionStateLoading(true)

      const firebaseResponse = await axios({
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        data: {
          query: `
          query {
            getPrices {
              stripePriceId
              active
              unitAmountDecimal
              billingScheme
              currency
              interval
              intervalCount
              stripePriceNickName
              product
              trialPeriodDays
            }
          }
        `,
        },
      })

      if (firebaseResponse.data.data.getPrices) {
        const { getPrices } = firebaseResponse.data.data

        await dispatch({
          type: FETCH_SUBSCRIPTIONS,
          payload: getPrices,
        })

        return setSubscriptionStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const fetchStripeCoupon = async (
    stripeCouponId,
    providedAccessToken = null,
  ) => {
    try {
      setSubscriptionStateLoading(true)

      let accessToken
      // get the user's token from the site if they are web source users
      if (providedAccessToken === null) {
        accessToken = await getTokenSilently()
      }
      // if the user is not a web source user (i.e., they came from the app) then use the provided token
      else {
        accessToken = providedAccessToken
      }

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          query{
            getCoupon(input: {stripeCouponId: "${stripeCouponId}"}) {
              stripeCouponId
              amountOff
              currency
              duration
              durationInMonths
              name
              percentOff
              timesRedeemed
              createdAt
              redeemBy
              valid
            }
          }
        `,
        },
      })

      if (firebaseResponse.data.data.getCoupon) {
        const { getCoupon } = firebaseResponse.data.data

        await dispatch({
          type: FETCH_COUPON,
          payload: getCoupon,
        })

        return setSubscriptionStateLoading(false)
      } else {
        window.alert(firebaseResponse.data.errors[0].message)
        return setSubscriptionStateLoading(false)
      }
    } catch (error) {
      console.error('Coupon Error: ', error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const createStripeSubscriptionSession = async (
    subscriptionName,
    stripeCouponId,
    numberOfFreeTrialDays = 0,
    providedAccessToken = null,
  ) => {
    try {
      setSubscriptionStateLoading(true)

      let accessToken
      // get the user's token from the site if they are web source users
      if (providedAccessToken === null) {
        accessToken = await getTokenSilently()
      }
      // if the user is not a web source user (i.e., they came from the app) then use the provided token
      else {
        accessToken = providedAccessToken
      }
      // get the id of the plan to use in the GraphQL mutation
      const { stripePriceId } = state.subscriptions.find(
        (sub) => sub.stripePriceNickName === subscriptionName,
      )

      const optionalCouponParameter = stripeCouponId
        ? `stripeCouponId: "${stripeCouponId}",`
        : ''

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            createSubscriptionSession(input: {
              ${optionalCouponParameter}
              stripePriceId: "${stripePriceId}"
              numberOfFreeTrialDays: ${numberOfFreeTrialDays}
            }) {
              stripeCheckoutSessionId
            }
          }
        `,
        },
      })

      const {
        stripeCheckoutSessionId,
      } = firebaseResponse.data.data.createSubscriptionSession

      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({
        sessionId: stripeCheckoutSessionId,
      })

      if (error) {
        throw new Error(error)
      }

      return setSubscriptionStateLoading(false)
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const createStripeSubscription = async (
    subscriptionName,
    couponCode,
    source,
    identifier,
    promoCode,
    providedAccessToken = null,
  ) => {
    try {
      const REDIRECT_ACTION = 'coupon'
      setSubscriptionStateLoading(true)

      let accessToken
      // get the user's token from the site if they are web source users
      if (providedAccessToken === null) {
        accessToken = await getTokenSilently()
      }
      // if the user is not a web source user (i.e., they came from the app) then use the provided token
      else {
        accessToken = providedAccessToken
      }

      // get the id of the plan to use in the GraphQL mutation
      const { stripePriceId } = state.subscriptions.find(
        (sub) => sub.stripePriceNickName === subscriptionName,
      )

      const optionalCouponParameter = couponCode
        ? `couponCode: "${couponCode}",`
        : ''

      const optionalPromoParameter = promoCode
        ? `promoCode: "${promoCode}",`
        : ''

      const optionalIdentifierParameter = identifier
        ? `identifier: "${identifier}",`
        : ''

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            createSubscription(input: {
              stripePriceId: "${stripePriceId}"
              ${optionalCouponParameter}
              ${optionalPromoParameter}
              source: "${source}"
              ${optionalIdentifierParameter}
            }) {
              stripeSubscriptionId
            }
          }
        `,
        },
      })

      // redirect upon success or alert of invalid coupon
      if (
        firebaseResponse.data.data.createSubscription &&
        firebaseResponse.data.data.createSubscription.stripeSubscriptionId
      ) {
        // if the user originally came from the app - direct back to the app.
        // We know they came from the app if they have the providedAccessToken variable (that's the only way it can be set currently)
        if (providedAccessToken) {
          // currently this redirect only applies to users who are redeeming a coupon code from the FluentWorlds app.
          if (typeof window !== 'undefined') {
            const protocol = process.env.GATSBY_FW_APP_PROTOCOL

            const appRedirectURL = `${protocol}://${REDIRECT_ACTION}`
            window.location.replace(appRedirectURL)
          }
        }
        // otherwise send to the success page
        else {
          navigate('/payment/success')
        }
      } else {
        window.alert(firebaseResponse.data.errors[0].message)
      }

      setSubscriptionStateLoading(false)
      return false
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      setSubscriptionStateLoading(false)
      return true
    }
  }

  const createBillingPortalSession = async (userSource) => {
    const optionalDigitalGoInputParameter =
      userSource === DIGITAL_GO_USER ? `(input: {isDigitalGo: true})` : ''

    try {
      await setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          query {
            getStripeBillingPortalSession${optionalDigitalGoInputParameter} {
              url
            }
          }
        `,
        },
      })

      const { url } = firebaseResponse.data.data.getStripeBillingPortalSession

      if (url) {
        await setSubscriptionStateLoading(false)
        return url
      } else {
        throw new Error('Error retrieving Stripe billing url')
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const getDigitalGoPaymentUrl = async (carrier) => {
    try {
      await setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            mutation {
              getPaymentUrlWrapper(input: {carrier: "${carrier}"}){
                url
              }
            }
        `,
        },
      })

      const { url } = firebaseResponse.data.data.getPaymentUrlWrapper

      if (url) {
        await setSubscriptionStateLoading(false)
        return url
      } else {
        throw new Error('Error retrieving the URL')
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const fetchDigitalGoSubInfo = async () => {
    try {
      setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              checkUserStatusWrapper{
                stripePriceNickName
                stripeSubscriptionStatus
                stripeSubscriptionPriceAmount
                stripeSubscriptionPriceCurrency
                stripeSubscriptionInterval
                stripeSubscriptionTrialStart
                stripeSubscriptionTrialEnd
                stripeSubscriptionCurrentPeriodStart
                stripeSubscriptionCurrentPeriodEnd
                stripeSubscriptionCreatedAt
                stripeSubscriptionCancelAt
                isMobileCreditUser
              }
            }
        `,
        },
      })

      if (
        firebaseResponse &&
        firebaseResponse.data &&
        firebaseResponse.data.data &&
        firebaseResponse.data.data.checkUserStatusWrapper
      ) {
        await setSubscriptionStateLoading(false)
        return firebaseResponse.data.data.checkUserStatusWrapper
      } else {
        throw new Error(
          "Error retrieving the digital go user's subscription information",
        )
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const cancelDigitalGoSubscription = async () => {
    try {
      await setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            mutation {
              cancelDigitalGoSubscriptionWrapper{
                stripeSubscriptionId
              }
            }
        `,
        },
      })

      const {
        stripeSubscriptionId,
      } = firebaseResponse.data.data.cancelDigitalGoSubscriptionWrapper

      if (stripeSubscriptionId) {
        await setSubscriptionStateLoading(false)
        return stripeSubscriptionId
      } else {
        throw new Error('Error deleting the subscription')
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions: state.subscriptions,
        coupon: state.coupon,
        subscriptionStateLoading: state.subscriptionStateLoading,
        fetchAvailableSitePlans,
        fetchStripeCoupon,
        createStripeSubscriptionSession,
        createStripeSubscription,
        createBillingPortalSession,
        getDigitalGoPaymentUrl,
        fetchDigitalGoSubInfo,
        cancelDigitalGoSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider
