import {
  FETCH_SUBSCRIPTIONS,
  FETCH_COUPON,
  SET_SUBSCRIPTION_STATE_LOADING,
} from '../types'

const SubscriptionReducer = (state, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      }
    case FETCH_COUPON:
      return {
        ...state,
        coupon: action.payload,
      }
    case SET_SUBSCRIPTION_STATE_LOADING:
      return {
        ...state,
        subscriptionStateLoading: action.payload,
      }
    default:
      return state
  }
}

export default SubscriptionReducer
