const en = {
  newsletter: {
    genericError:
      'Ocorreu um erro ao processar sua solicitação de assinatura. Se o erro persistir, entre em contato com support@fluentworlds.com',
    invalidEmail:
      'Endereço de email invalido. O e-mail deve ser um formato válido, incluindo um @ (por exemplo: email@domain.com)',
    successfulSubscribe: 'Você está inscrito!',
    subscribeButton: 'Subscrever o Boletim',
  },
  modalOffer: {
    title: 'Happy Holidays!',
    text:
      'Provide this coupon when you check out for 50% off the leading language learning app!',
  },
  header: {
    home: 'Lar',
    products: 'Produtos',
    ambassador: 'Torne-se um Embaixador',
    myAccount: 'Minha conta',
    greeting: 'Olá',
    signIn: 'Entrar',
    signOut: 'Sair',
    signUp: 'Registro',
    redeem: 'Resgatar',
    blog: 'Blogue',
  },
  blog: {
    title: 'FluentWorlds Blog',
    description: 'See what the FluentWorlds community has been talking about!',
    recent1: 'How to Introduce Yourself in English | Self Introductions',
    recent1Date: 'March 23, 2023',
    recentDescription1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    recent2: '10 Common Difficult Words to Pronounce in English',
    recent2Date: 'March 28, 2023',
    recentDescription2:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English.',
    recent3: 'English Vocabulary Practice | Thought vs. Thought',
    recent3Date: 'April 14, 2023',
    recentDescription3:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    recent4: 'Confusing English Words | Homophones Explained with Examples',
    recent4Date: 'May 19, 2023',
    recentDescription4:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand?',
    recent5: 'Confusing English Tongue Twisters',
    recent5Date: 'June 8, 2023',
    recentDescription5:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister.',
  },
  post1: {
    post1Title: 'How to Introduce Yourself in English | Self Introductions',
    post1Author: 'By Aleja Briles',
    post1Date: 'March 23, 2023',
    post1Paragraph1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    post1Paragraph2:
      'Today’s topic may seem a bit beginner, but self introductions are an essential skill that you need to know in any situation. Whether you’re meeting a person for the first time, or given an assignment and need to speak to a larger group of people, this post will help you learn the best ways to introduce yourself.',
    post1Paragraph3:
      'Let’s talk about the key elements of a self-introduction. Let’s start off with a greeting, something like, “It’s so nice to meet you” or “It’s a pleasure to meet you.” The difference between these two examples is that one is slightly more formal than the other. The first example, “It’s so nice to meet you,” is the more informal of the two and is what you’re going to hear and probably say the most often. “It’s so nice to meet you.” The second example is the more formal greeting, “It’s a pleasure to meet you.” In this instance you would say this if you’re meeting someone in a business setting or for the first time. This is especially true if it’s someone you’ve been wanting to meet for a long time. If the other person is the first person to say “It’s so nice to meet you,” you can reply with “It’s nice to meet you too.”',
    post1Paragraph4:
      'Next you will want to say your name to let them know who you are. You can say this in one of two ways. The first way is “My name is _____.” and the second is “I am _____.” So for myself, I would say, “My name is Aleja.” or I could say, “I am Aleja.” Either one of those will work in any scenario to introduce yourself.',
    post1Paragraph5:
      'Now is the fun part where you get into the details about yourself. You can share things like where you’re from, what you do for a living, what brings you to the area that you’re in etc. For example, to tell someone where you are from you can say, “I’m from ____, it’s a city in ____.” Using myself as an example, I would say, “I’m from Salt Lake City, it’s a city in Utah.”',
    post1Paragraph6:
      'The next thing you could say is, “I’m currently living in _____.” This is if you’re from a specific city but you’re not currently living there. For example “I’m from New York, but I’m currently living in Dallas.” ',
    post1Paragraph7:
      'To keep the conversation going, you can continue to ask more questions. To learn about their occupation or job you can ask “what do you do for work?” Let’s pretend they work in engineering. The ‘ing’ makes it an action so they might say, “I work in engineering” but they could also say “I work as an engineer.” Those are two ways to say the same thing. Another example is “I work in education.” Or I could say “I work as a teacher or educator.”',
    post1Paragraph8:
      '“What brings you to the area?” This is a great question to ask someone, especially if you‘re meeting them at a place where people typically vacation or travel to frequently. “What brings you to the area?” you could then respond with something like “I‘m here on vacation” or “I‘m just visiting.” That‘s a very simple answer that could include a vacation, work, visiting family etc. If you‘re in a one-on-one situation it‘s also vital that you ask the other person about themselves as well, that way it‘s a two-way conversation. If you‘re only talking about yourself it can be perceived as rude or uninterested.',
    post1Paragraph9:
      'Here are some basic questions that you can ask another person about themselves:',
    post1ListItem1: 'Where are you from?',
    post1ListItem2: 'Are you from around here?',
    post1ListItem3: 'How long have you lived here?',
    post1ListItem4: 'Could you remind me of your name?',
    post1ListItem5: 'Where do you work?',
    post1ListItem6: 'Do you like your job?',
    post1ListItem7: 'Do you like this area?',
    post1ListItem8: 'What brings you to the area?',
    post1ListItem9: 'What do you like to do for fun? ',
    post1Paragraph10:
      'Now we‘re going to learn how to exit from the conversation. You can say things like “It was so nice to meet you, I hope to see you again soon” or “It was so nice getting to know you, take care!” You want to reinforce that it was nice meeting the person, and that you hope to have interactions with them in the future.',
    post1Paragraph11:
      'Let‘s do a quick review. Your introduction or first time conversation should be somewhat short, but should open up the potential to have a longer conversation. My introduction would sound something like:',
    post1Paragraph12:
      '“Hi, my name is Aleja. It‘s so nice to meet you. I‘m here in Salt Lake City visiting some friends for fun. What brings you here? I live in Dallas, but I’m from New York. I‘ve lived there for about a year. Where are you from? It was so nice getting to know you, take care!”',
    post1Paragraph13:
      'I hope that you feel confident now in understanding the basics of a self-introduction. All of the skills we learned today can be used in multiple scenarios whether you‘re meeting someone one-on-one or giving a broader explanation about yourself. If you have any additional questions or want to hear me talk more about introductions, head on over to our youtube channel and watch our video all about how to introduce yourself!',
    post1VideoLinkText: 'https://youtu.be/Y8rfZjWlKbY',
  },
  post2: {
    post2Title: '10 Common Difficult Words to Pronounce in English',
    post2Author: 'By Aleja Briles',
    post2Date: 'March 28, 2023',
    post2Paragraph1:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English. Working on pronunciation of words is one of the most important steps to gaining confidence in speaking English fluently. If you want to hear how I pronounce these words, check out this youtube video here: ',
    post2VideoLinkText: 'https://youtu.be/UgZjlGBKi6s',
    post2Paragraph2:
      'In this post, we’re going to focus on 10 words in English that might seem very difficult to pronounce for ESL learners. My goal is to have you using these words more confidently by the end of today’s lesson. Let’s jump in!',
    post2Word1: 'Colonel (kur-nuhl):',
    post2ListItem1:
      '\nThis word is pronounced ‘kernel’. This is someone in the military. You‘re going to hear this word more often than you think, especially when referencing family, friends or acquaintances that you may know that are in the military.',
    post2Word2: 'Mischievous (mis-chee-vee-us):',
    post2ListItem2:
      '\nSometimes with this word you may hear different pronunciations depending on what region you‘re in or what country you might be in. In American English more often than not you‘re going to hear ‘mis-chee-vee-uhs’.  This is if someone is sneaky or a little bit naughty or misbehaves but in an endearing way. ',
    post2Word3: 'Drought (drowt):',
    post2ListItem3:
      '\nA drought is when there is a lack of rain or water, when the land is dry or if an area has not had rain in a long time. In this word the ‘gh’ sound is silent which makes it confusing for people trying to learn English. ',
    post2Word4: 'Scissors (si-zrz):',
    post2ListItem4:
      '\nIn the words scissors the C in this word appears to be silent and that double ‘s’ is going to give you more of a ‘z’ sound. Scissors are what we use to cut paper. I‘m sure you‘ve all seen scissors before.',
    post2Word5: 'Successful (suhk-seh-sfl): ',
    post2ListItem5:
      '\nThe word successful is definitely a mouthful. What I always encourage my students to do is to act as though you‘re smiling as you say this word. That will help you get out those letters more easily and fluently. That double C makes a ‘k’ sound. ',
    post2Word6: 'Sixth (siksth): ',
    post2ListItem6:
      '\nSixth is the number order  after fifth and before seventh. That ‘xth’ can be really difficult to pronounce. ',
    post2Word7: 'Rural (rur-uhl): ',
    post2ListItem7:
      '\nThis is a word that‘s actually pretty difficult for me to say as well so don‘t be hard on yourself if you can‘t get it. This word is ‘rural’.  This word has two of those difficult ‘er’ sounds that we have a lot in English. This is when something is out in the farmland or in ranch land. Open space is typically referred to as rural.',
    post2Word8: 'Specific (spuh-si-fuhk): ',
    post2ListItem8:
      '\nThe word specific has two of the letter ‘c’ pretty close to each other. That last ‘c’ sounds like a ‘k’ sound. This is not to be confused with words like Pacific which is the ocean. The word specific means something in particular or something that we’re trying to point out. If I say “I want this specific cookie,” I want a certain cookie. I want THAT one.',
    post2Word9: 'Temperature (tem-pruh-chr): ',
    post2ListItem9:
      '\nThis is another word that you might hear pronounced differently from region to region or across different countries. Here in America we say ‘tem-pruh-chr’ so that ‘er’ sound is not pronounced separately in temperature.',
    post2Word10: 'Often (aa-fn): ',
    post2ListItem10:
      '\nI know this word seems pretty basic but that ‘oft’ can be difficult to bring together. Often refers to something that occurs frequently or regularly. ',
    post2Paragraph3:
      'These words may not always come up in everyday conversation, but when they do, you‘re going to be prepared and be able to show off your English skills. Check back weekly for more English learning posts! See you soon! ',
  },
  post3: {
    post3Title: 'English Vocabulary Practice | Thought vs. Thought',
    post3Author: 'By Aleja Briles',
    post3Date: 'April 14, 2023',
    post3Paragraph1:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    post3Paragraph2:
      'Today we’re going to be focusing on one word. This word is used pretty often in English conversation and can be used in a few different ways. The word I am talking about is the word ‘thought’.  In English the word ‘thought’ can be used in two different contexts:',
    post3ListItem1:
      'It can mean an idea. For example “I had a thought about how we could solve this problem.”',
    post3ListItem2:
      'It can also mean something that you were thinking about in the past or that you believed in the past. For example “I thought your name was Ashley not Amanda.” ',
    post3Paragraph3:
      'To help you understand how to use this word in context, let’s go over 20 different phrases that use the word ‘thought’.',
    post3Subtitle1: '‘Thought’ as in a belief',
    post3ListItem3: 'I thought you were from New York.',
    post3ListItem4: 'I thought we were going to the beach today.',
    post3ListItem5: 'They thought I was from India but I’m from Pakistan.',
    post3ListItem6: 'We thought your friend was very nice.',
    post3ListItem7: 'Your sister thought I was already gone.',
    post3ListItem8: 'I thought that burger was delicious.',
    post3ListItem9: 'They thought the restaurant was nice.',
    post3ListItem10: 'We thought about it, but decided not to go.',
    post3ListItem11: 'He thought she had a sister.',
    post3ListItem12: 'I listened to the song and thought it was really good!',
    post3Paragraph4:
      'These phrases are all ‘thought’ in the context of something that you believed in the past. The word thought is really important in the context of these sentences. let’s move on to thought as in the idea or to think.',
    post3Subtitle2: '‘Thought’ as in an idea',
    post3ListItem13: 'Do you have any thoughts on the matter?',
    post3ListItem14: 'Does anyone have any thoughts on today’s lesson?',
    post3ListItem15: 'I thought we could all go to the park.',
    post3ListItem16: 'I never thought about a career in medicine.',
    post3ListItem17: 'She thought of her nephew when she saw this toy.',
    post3ListItem18: 'They thought it would be fun to travel together.',
    post3ListItem19: 'We thought going to a movie would be a great activity!',
    post3ListItem20: 'I thought a tutor might be helpful.',
    post3ListItem21: 'Does she have any more thoughts about the party?',
    post3ListItem22: 'I don’t have any additional thoughts.',
    post3Paragraph5:
      'There are many ways you can use the word thought. Can you think of any yourself? This week when you’re practicing using the word thought, make sure you’re able to use it in the two contexts listed above. Either something that you were thinking of in the past like “I thought about you” or an idea that you had like “I thought we could try this new restaurant.” For more explanation check out our video here: ',
    post3VideoLinkText: 'https://youtu.be/qE_NpSQftlY',
  },
  post4: {
    post4Title: 'Confusing English Words | Homophones Explained with Examples',
    post4Author: 'By Aleja Briles',
    post4Date: 'May 19, 2023',
    post4Paragraph1:
      'I’ll walk down the aisle on the tropical isle. The scent that he sent was only one cent. Wow! These sentences can be very confusing! Why? Because they are all about homophones!',
    post4Paragraph2:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand? A homophone is two or more words that have the same pronunciation but different spelling. It’s so important to know the definitions and pronunciations of these words so that you can use them correctly in your conversations and in writing. Let’s go over a few examples!',
    post4ListItem1: 'Ate vs. Eight',
    post4ListPara1:
      'Let’s start with the first ‘ate’. This is the simple past tense of the verb ‘to eat’. For example, “I ate an entire pizza and now I’m really full.” or “She ate dinner before she came.” The second eight, as in the number eight, is a noun and represents the number after seven and before nine. For example “Charles will wake up at eight o’clock in the morning.” There’s a popular children’s joke that goes like this - Why was six afraid of seven? Answer - Because seven, eight, nine. Yes, it’s a pretty bad joke but it helps us learn the homophones and how they sound very similar to each other, but have different spelling.',
    post4ListItem2: 'Bare vs Bear',
    post4ListPara2:
      'The first ‘bare’ is an adjective. If something is bare that means it’s not covered or not decorated. “Tom likes to walk around his house in his bare feet, he says it’s more comfortable than wearing shoes” or “The walls were pretty bare when we moved into the house.” The second ‘bear’ is a noun. A bear is a large mammal in the forest like a grizzly bear, black bear or a brown bear. “When you go camping you should be careful to not leave any food out with a scent because it will attract bears.” or “The bear was by the river with her cubs.” ',
    post4ListItem3: 'Buy vs By vs Bye',
    post4ListPara3:
      'Have you heard of NSYNC? They have a song called ‘Bye Bye Bye’. This is a good way to remember that there are three homophones that sound the same for bye. The first ‘buy’ is a verb and means to purchase something. It‘s probably one of the first verbs that you learned in English because it’s used so commonly. “I forgot my money at home, do you think you could buy me lunch and I‘ll pay you back?” The second form of ‘by’ is a preposition. This can be used in many different ways but it‘s commonly used to mean next to or near when describing a location. For example “The car is parked in the lot by the big light post.” It can also indicate who created something. “My favorite autobiography is ‘The Autobiography of Malcolm X,” it‘s written by Malcolm X and Alex Haley.” Now for the third form of ‘bye’. This is an exclamation or a shortening of goodbye. “I‘ve gotta go now, bye!” It is how you can end a conversation in a casual informal way.',
    post4ListItem4: 'Cell vs. Sell',
    post4ListPara4:
      'A ‘cell’ is a noun and refers to a small area or room usually in a prison. A cell can also be one of the smallest divisions of a living organism. “The prisoner spent 10 years confined to his cell” or “We were able to see the plant’s cells better under a microscope.” The second ‘sell’ is a verb meaning to exchange a product or service for money. Similar to the verb ‘buy’, it was probably one of the first verbs you learned. “We would like to sell our car but we don’t think we would get very much money for it.”',
    post4ListItem5: 'Dew vs. Do vs. Due',
    post4ListPara5:
      'This is another set of three homophones. Let‘s start with the first one ‘dew’ which is a noun. Dew is the name for the small drops of water that accumulate on plants and other objects outside during the night. “When I went outside early in the morning the dew on the grass made my shoes wet.” The second ‘do’ is a verb. This common verb is used to indicate an action but can also be an auxiliary verb. “What do you usually do on Friday nights?” Lastly our third form of ‘due’. This is an adjective and is used to indicate the deadline or final day that something can happen. It‘s also used to indicate when a baby is expected to be born. “My friend is pregnant, her baby is due in December.” or “The homework assignment is due on Monday.”',
    post4Paragraph3:
      'This was definitely a lot of information! Of course these aren’t the only homophones, there are many more than you can find with a quick Google search. Homophones can be tricky for ESL speakers, but it will really set you apart as a competent English speaker to start learning them! Good luck learning the different homophones! Start to use them in daily conversation and you will have them down in no time! Best of luck! Check out our video on homophones here: ',
    post4VideoLinkText: 'https://youtu.be/ttgFlw1YgYI',
  },
  post5: {
    post5Title: 'Confusing English Tongue Twisters',
    post5Author: 'By Aleja Briles',
    post5Date: 'June 8, 2023',
    post5Paragraph1:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister. Tongue twisters are tricky but they will force you to enunciate similar sounding words to make each phrase intelligible. Here are a couple great examples of tongue twisters in English. Read through them carefully, and then go back and try and read through more quickly while keeping the correct pronunciation of each sound.',
    post5ListItem1: 'Peter Piper',
    post5ListPara1:
      '“Peter Piper picked a peck of pickled peppers. A peck of pickled peppers Peter Piper picked. If Peter Piper picked a peck of pickled peppers, where’s the peck of pickled peppers Peter Piper picked?”',
    post5ListPara2:
      'How did you do? Were there any sounds that were difficult to pronounce? If you want to hear me pronounce these words head over to our youtube channel and watch our video (linked at the bottom of this post) all about tongue twisters. Alright, let`s try another one!',
    post5ListItem2: 'Fuzzy Wuzzy',
    post5ListPara3:
      '“Fuzzy Wuzzy was a bear. Fuzzy Wuzzy had no hair. Fuzzy Wuzzy wasn’t very fuzzy was he?”',
    post5ListPara4:
      'Great job! That one’s gonna help you practice that ‘z’ sound. If you’re up for it, try it again a little bit faster this time and see how you do! This next one is about a woodchuck and is a very common rhyme for children in America. ',
    post5ListItem3: 'WoodChuck',
    post5ListPara5:
      '“How much wood would a woodchuck chuck if a woodchuck could chuck wood? He would chuck, he would, as much as he could and chuck as much wood as a woodchuck would if a woodchuck could chuck wood.”',
    post5ListPara6:
      'That’s a long and challenging one! That one is going to help you practice the ‘uh’ sound. Try it one more time! This last one is about Betty Botter. This one is going to help you practice that double “t” and “d” sound. ',
    post5ListItem4: 'Betty Botter',
    post5ListPara7:
      '“Betty Botter bought some butter, but she said the butter was bitter. If I put it in my batter it will make my batter bitter. But a bit of better butter will make my batter better. So it was better Betty Botter bought a bit of better butter.”',
    post5ListPara8:
      'That one is so tough! Go back and practice each of these as many times as you want. Getting better at these will help you to perfect these different sounds in English. There are many more English tongue twisters that you can use to continue practicing. Just search “English tongue twisters” in Google and you will find many more! I hope this helps you with your pronunciation! Best of luck! ',
    post5Paragraph2: 'Check out the youtube video: ',
    post5VideoLinkText: 'https://youtu.be/2flHA664OA8',
  },
  perpetualLicenseFWRedeemPage: {
    greeting: 'Hello',
    enterCode: 'Please enter your {{vendor}} code below:',
    submit: 'SUBMIT CODE',
    goToAccount: 'GO TO ACCOUNT PAGE',
    createAccountPrompt:
      'Please create an account to redeem your {{vendor}} code',
    signUp: 'SIGN-UP',
    fallBackErrorMsg:
      'An error has occurred, please try again or reach out to support.',
    invalidCode:
      'The {{vendor}} code provided was invalid, please try entering your code below',
  },
  digitalGoRedeemPage: {
    creditCard: 'credit card',
    mobileCredit: 'mobile credit payment',
    subscriptionDurationOne: '1 Month',
    subscriptionPriceOne: '8,99 €',
    promoTextOne: '1 week free',
    promoTextTwo: '€ 8.99 / month',
    continueButton: 'SUBSCRIBE',
    selectOperator: 'Select your mobile operator',
    creditCardFooterPrompt:
      'I accept the T&C and the Privacy Policy of the service. All offers will be automaticaly renewed.',
    mobileCreditFooterPrompt:
      'The offer will be renewed automatically every month. 1 Week free trial.',
  },
  paymentSuccessPage: {
    thankYou: "It's great to have you!",
    startExploring:
      'Start exploring 3D worlds and jumpstart your language learning journey by downloading FluentWorlds.',
    haveQuestions:
      'If you have any questions, send us an email to <0>support@fluentworlds.com</0>',
    goToAccount: 'GO TO ACCOUNT',
  },
  paymentCancelPage: {
    cancelMsg: 'Cancelling checkout',
    noCharge:
      'You will not be charged since you cancelled in-between the checkout process.',
    goToAccount: 'GO BACK TO ACCOUNT',
    goBack: 'GO BACK',
  },
  accountPage: {
    manageSubscriptions: 'Manage Subscriptions',
    accountSettings: 'Account Settings',
    updateUsername: 'Update Username',
    chooseSubscription: 'Choose your ideal subscription below',
    confirmUsernameChange: 'Are you sure you want to change your username?',
    successUsernameChange: 'You have successfully changed your username.',
    invalidUsernameLengthError:
      'Please enter a username between {{min}} and {{max}} characters',
  },
  supportPage: {
    title: 'Contact Us | App Support | FluentWorlds',
    description: '',
    header: 'Support',
    contactUs: 'You can contact us if you have any questions.',
    formSubmitted: 'Your message has been sent',
    formName: 'Name *',
    formEmail: 'Email *',
    formMessage: 'Message *',
    formSubmit: 'Submit',
  },
  couponPage: {
    prompt: 'Please enter your coupon code below for',
    submit: 'SUBMIT COUPON',
    goToAccount: 'GO BACK TO ACCOUNT',
  },
  promoPage: {
    prompt: 'Please enter your promotion code below for',
    submit: 'SUBMIT PROMOTION CODE',
    goToAccount: 'GO BACK TO ACCOUNT',
  },
  downloadFluentworlds: {
    downloadPrompt: 'Download the FluentWorlds App',
  },
  subscriptionProducts: {
    fluentworldsSubscriptionDescription:
      'Learn new languages in an immersive 3D system, exploring everyday scenarios.',
    perfectaccentSubscriptionDescription:
      'Perfect your pronunciation and expand your specialized vocabulary.',
    bundleSubscriptionDescription:
      'Unlimited access to FluentWorlds and PerfectAccent.',
    buy: 'Buy',
    manage: 'Manage',
    monthly: 'monthly',
    yearly: 'yearly',
    useCoupon: 'Use coupon',
    status: 'Status',
    priceAmount: 'Price',
    interval: 'month',
    trialStart: 'Trial Start',
    trialEnd: 'Trial End',
    periodEnd: 'Next Charge',
    cancelAt: 'Cancel At',
    createdAt: 'Created At',
    cancel: 'Cancel Subscription',
    cancelSuccess: 'Your subscription has been cancelled',
    dgoPromoOne: '1 week free',
    dgoPromoTwo: '8,99€ / month',
    inactive: 'inactive',
    activeSubscription: 'active',
    trialingSubscription: 'trialing',
    freeTrial: '7 Day Free Trial',
    after: 'after',
  },
  subscriptionProvider: {
    fallBackErrorMsg:
      'An error has occurred, please try again or reach out to support.',
  },
  youtubeEmbedded: {
    title: 'YouTube video player',
  },
  countdown: {
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    countdownFinished: "Time's up!",
  },
  index: {
    title: '3D Immersive online language learning for everyone | FluentWorlds',
    description:
      'Interactive online worlds for Spanish and English learning. Be immersed in the new language while receiving instructions in your native language & perfect your accent',
    keywords: '',
    experienceTheJoy: 'Experience the joy',
    immersive: 'Immersive language training',
    ourSuite: 'Our Suite',
    sweet: 'Sweet',
    theMostExciting:
      'The most exciting, immersive, and effective language-learning app ever.',
    personalTutoring:
      'Expert personal tutoring and engaging web conferencing at your fingertips.',
    preciseGuidance:
      'Precise and gentle accent guidance powered by advanced machine learning.',
    fluentWorldsAcademy: 'FluentWorlds Academy',
    fluentWorldsAcademyAnd3Dmeet: 'FluentWorlds Academy and 3Dmeet',
    perfectAccent: 'Perfect Accent',
    goToTheAcademy: 'Go to the Academy',
    theWorldIsNotFlat: "The World isn't Flat.",
    languageLearning: "Language learning shouldn't be either.",
    whyFluentWorldsHeader: 'Why FluentWorlds?',
    whyFluentWorldsParagraph:
      'Because outdated techniques are simply not effective. Enjoy learning new languages in 3D. Our innovative teaching technology leverages the power of immersion and interaction in simulated "real world" environments. This transformative method accelerates learning and enhances memory.',
    learnMore: 'Learn More',
    lifeIsNotTwoDimensional: "Life isn't two-dimensional.",
    webConferencing: 'Why is your web conferencing?',
    learningAndConferencing:
      'Full 3-dimensional learning and conferencing within environments that engage audiences and encourage interaction.',
    howEffective: 'How EFFECTIVE is your Personal Tutor?',
    ourTechnology: 'Our technology speaks for itself.',
    whyPerfectAccentHeader: 'Why Perfect Accent?',
    whyPerfectAccentParagraph:
      "Created by the brains of the man behind the Amazon Alexa, PerfectAccent is a brilliant, kind, and gentle tutor for your American accent. Take advantage of the world's smartest voice-recognition software and fast-track your learning!",
    openTheDoor: 'Open the DOOR',
    toYourFuture: 'To your future job, promotion, and salary.',
    incomeIncrease:
      'Multi-lingual speakers are more likely to have an income increase of up to 300%.',
    masterInContext:
      'This immersive, 3D, virtual language-learning system helps people master a language in context.',
    languageOfInternet:
      'Speaking multiple languages can open oportunities in a variety of contexts: international business, and programming to name a few.',
    jobOpportunities:
      'Multi-lingual speakers experience better global job opportunities.',
    ourPlans: 'Our Plans',
    chooseSubscription: 'Choose your ideal subscription below',
  },
  becomeAmbassador: {
    title: 'Become an Ambassador | FluentWorlds',
    description: 'What is to be an FluentWorlds Ambassador',
    joinSectionHeader: 'Join Our Ambassador Program!',
    joinSectionText:
      'We are so excited that you are interested in joining our community of learners and helping others improve their lives through learning a new language in the Metaverse. Plus, it is free to join!',
    joinSectionButton: 'Demo our products or learn more here',
    oneOnOneSectionTitle: 'Demo Our Products',
    oneOnOneSectionWebinarHeader: 'Live Online Webinar',
    oneOnOneSectionWebinarSubHeader: '(Preferred Option)',
    oneOnOneSectionWebinarButtonLabel: 'Sign Up',
    oneOnOneSectionDemoHeader: 'One on One Demo',
    oneOnOneSectionDemoSubHeader: '(Back Up Option)',
    oneOnOneSectionDemoButtonLabel: 'Sign Up',
    oneOnOneSectionText:
      'Choose one of the following options. Our Live Webinar option offers a meeting with fellow ambassadors and includes a 3 month subscription to our products. One on One option includes 1 month free subscription to our products.',
    conditionSectionHeader: 'Why Be An Ambassador?',
    conditionFirstColumnHeader: '20%-30% Commission',
    conditionFirstColumnText:
      'Supplement your income by making up to 30% Commission',
    conditionSecondColumnHeader: 'Exclusive Discounts',
    conditionSecondColumnText:
      'As as an ambassador, you get exclusive discounts to save on your personal purchases and for your customers',
    conditionThirdColumnHeader: 'Insider Access',
    conditionThirdColumnText:
      "You'll receive exclusive updates and receive a free 3 month subscription to our Perfect Accent and FluentWorlds Apps",
    conditionForthColumnHeader: 'Features and Shoutouts',
    conditionForthColumnText:
      "We'll showcase your content on our social media and spotlight ambassadors to our community",
    applyBtnLabel: 'Apply Now',
    overviewHeader: 'Our Ambassador Program Overview',
    learningOnline: 'Learning Online',
    howToApply: 'How To Apply',
    howToApplySteps: [
      {
        header: 'Click The Button Below',
        text:
          'Click the "Apply Now" button below. This will take you to our ambassador program application form.',
      },
      {
        header: 'Fill Out The Application Form',
        text: 'Complete the form. We review each application very carefully!',
      },
      {
        header: 'Fill Out Payment Information',
        text:
          "You won't be charged anything, we just want your preferred method of payment for your future commissions.",
      },
      {
        header: 'Wait For An Email From Us!',
        text:
          "If you're accepted to the program, you'll receive an email from us with instructions, so be on the lookout!",
      },
    ],
    signInSectionText:
      'Already a FluentWorlds Ambassador? Log in to your account here.',
    signInSectionBtnLabel: 'Sign In',
    questionsSectionText:
      'If you have any questions or want more information before joining, please email',
  },
  products: {
    title: 'Language Learning Software | 3D world | Pronunciation App',
    description:
      'Language learning softwares for exploring common interactions, places and things in FluentWorlds and then practice your accent with PerfectAccent pronunciation tool',
    fluentWorldsAcademy: 'FluentWorldsAcademy',
    thankGod: 'Thank God for FluentWorlds! Thank you for creating it!',
    signIn: 'Sign In',
    englishAnywhere: 'Learn new languages anytime, anywhere!',
    purchaseSubscription: 'to purchase purchase a FluentWorlds subscription!',
    fluentWorldsApp: 'FluentWorlds App',
    mostCommonlyUsedWordsHeading: 'The Most Commonly Used Words',
    mostCommonlyUsedWordsParagraph:
      'Learn the most commonly used words in a realistic video game setting.',
    threeSkillLevelsHeading: 'Three Skill Levels',
    threeSkillLevelsParagraph:
      'Choose from three skill levels: Beginner, Intermediate, and Advanced.',
    practicalDictionaryHeading: 'Practical Dictionary Reference',
    practicalDictionaryParagraph:
      'Quickly reference words and phrases in our in-game lexicon.',
    realTimePronunciationHeading: 'Real Time Pronunciation Analysis',
    realTimePronunciationParagraph:
      'Enjoy real-time pronunciation analysis built by the father of Amazon Alexa.',
    progressTrackerHeading: 'Progress Tracker',
    progressTrackerParagraph:
      'Track your progress and learned words through the dictionary function.',
    realLifeSituationsHeading: 'Real Life Situations',
    realLifeSituationsParagraph:
      'Increase your ability to remember and speak in real-life situations.',
    testimonyAna:
      "I have tried Duolingo and Babbel but I really couldn't keep it up, because it's very dull and I start forgetting words. They are a flat learning journey. FluentWorlds is very unique to me, and I think it will be a disruption! I definitely feel more engaged to be in a 3D environment.",
    testimonyLeonardo:
      'Every week, I am making tremendous progress on my English conversation. FluentWorlds is the fastest and more enjoyable way to learn English!',
    testimonyJuan:
      "I was testing the Fluentworlds app and I find it incredible! It's very useful and fun! It has been of great help to me to expand my vocabulary...it is amazing, thank you so much!",
    startUsingFluentWorlds: 'Start using FluentWorlds now!',
    descriptionLearnEnglish:
      'Learn new languages in an immersive 3D environments, as you explore everyday scenarios.',
    downloadOn: 'Download on',
    noLimitationsHeading: 'No Limitations!',
    noLimitationsParagraph:
      'Enjoy learning in classrooms that are not restricted to chairs, tables, and blackboards.',
    immersiveEducationHeading:
      "An immersive education system you can't forget!",
    immersiveEducationParagraph:
      'Explore magnificent virtual worlds with your classmates. Learn about traveling from inside an airport, about ordering food from inside a restaurant, or about purchasing new fashion items at a clothing store. Our immersive virtual language-learning system is proven to help students remember what they learn in class.',
    registerHere: 'Register Here',
    learnEnglishHeading: 'Learn New Languages in 3D',
    learnEnglishParagraph:
      'Immersion in our online, virtual-world environments creates a complete language learning experience.',
    americanInstructorsHeading: 'Trained Instructors',
    americanInstructorsParagraph:
      'Our trained instructors will guide you and your class on your language learning journey.',
    acceleratedLearningHeading: 'Accelerated Learning',
    acceleratedLearningParagraph:
      'Immersion plus speech recognition equals a faster learning experience.',
    effectiveLearningExperienceHeading: 'More Effective Learning Experience',
    effectiveLearningExperienceParagraph:
      '3D learning is proven to be more effective than traditional classrooms.',
    perfectAccent: 'PerfectAccent',
    personalizedVoicePrintHeading: 'Receive your own personalized voice print',
    personalizedVoicePrintParagraph:
      'Your custom voice print provides a visual map to evaluate your current pronunciation and creates baseline from which to progress.',
    personalizedPhrasesHeading: 'Receive personalized phrases to suit you',
    personalizedPhrasesParagraph:
      'Our artificial intelligence-based system is customizable and provides the words and sentences you need most to refine your pronunciation.',
    industrySpecificHeading: 'Learn industry-specific words and phrases',
    industrySpecificParagraph:
      'Perfect Accent includes hundreds of specific categories of words and phrases so you can practice with the most relevant content for your life and work.',
    perfectPronunciation:
      'Perfect your pronunciation and expand your specialized vocabulary.',
    ourPlans: 'Our Plans',
    chooseSubscription: 'Choose your ideal subscription below',
    features: 'Features',
  },
  footer: {
    home: 'Home',
    products: 'Products',
    affiliate: 'Become An Affiliate',
    myAccount: 'My Account',
    allRightsReserved: 'All Rights Reserved',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
  },
  privacyPolicy: {
    title: 'Política de Privacidade  | FluentWorlds',
    description:
      'Revise nossa política de privacidade para o aprendizado de idiomas online FluentWorlds',
    header: 'Política de Privacidade da FluentWorlds',
    privacyPreamble:
      'Virtual Immersive Educational Worlds, Inc., dba FluentWorlds ("FluentWorlds") é um desenvolvedor de sistemas de aprendizagem de línguas baseados na tecnologia. Levamos a sério a privacidade dos nossos clientes. Estamos empenhados em salvaguardar a sua privacidade ao mesmo tempo que fornecemos produtos e serviços personalizados e valiosos.',
    overview: {
      title: 'Visão geral',
      one:
        'Esta declaração de Política de Privacidade explica algumas das práticas de dados da FluentWorlds. Estamos empenhados na privacidade e segurança das informações pessoais que recebemos e processamos de acordo com os requisitos legais aplicáveis. Se tiver pedidos relativos às suas informações pessoais ou perguntas sobre estas práticas, por favor contacte o nosso Director de Privacidade através do e-mail chiefprivacyofficer@FluentWorlds.com. Encorajamo-lo a rever periodicamente esta Política de Privacidade, uma vez que pode ser alterada a qualquer momento, a nosso exclusivo critério.',
      two:
        'O nosso website contém links para websites de terceiros, que não estão sujeitos a esta política de privacidade. A FluentWorlds não subscreve e não é responsável pelas práticas de privacidade ou conteúdo de outros websites. Recomendamos que leia a política de privacidade de qualquer website que visite. ',
      three:
        'No caso de nos fornecer informações pessoais para efeitos de registo gratuito e de mais tarde efetuar uma compra, quer diretamente de nós ou facilitada por um dos nossos parceiros de comércio eletrónico, os seus direitos de privacidade serão protegidos de acordo com as nossas políticas declaradas e as políticas de privacidade declaradas dos nossos parceiros, que serão publicadas e estarão disponíveis para a sua revisão no momento da compra.',
    },
    infoCollected: {
      title: 'Informação recolhida',
      one:
        'Algumas das suas informações pessoais são recolhidas pela FluentWorlds. Recolhemos algumas informações pessoais identificáveis ("PII") sobre si (os seus "Dados") através: (A) da utilização de formulários de registo ou de inquérito; (B) do processo de compra quando compra algum dos nossos produtos ou serviços; ou (C) do fornecimento dos seus dados online ou offline.',
      two: {
        title: 'Os elementos dos seus Dados que recolhemos podem incluir:',
        name: 'Nome',
        jobTitle: 'Título do trabalho',
        companyName: 'Nome da empresa',
        addresses:
          'Endereços dos itens de casa, da secção de expedição e faturação, e/ou número de telefone',
        mobilePhone: 'Número de telefone móvel',
        emailAddress: 'Endereço de correio eletrónico',
        ipAddress: 'Endereço IP e informação do navegador',
        paymentDetails:
          'Detalhes de pagamento tais como informação de cartão de crédito',
        marketData:
          'Dados de pesquisa de mercado, tais como padrões de utilização do cliente',
      },
    },
    useAndDisclosure: {
      title: 'Utilização e Divulgação de Informação Pessoal',
      one: {
        title: 'Utilizamos os seus Dados para fins que podem incluir:',
        orders: 'Processamento de pedidos, registros e consultas',
        personalizedService:
          'Proporcionar aos nossos clientes um serviço personalizado',
        measuringInterest:
          'Medir o interesse e melhorar o nosso website, produtos e serviços',
        marketResearch: 'Realização de estudos e pesquisas de mercado',
        competitions: 'Concursos de corrida',
        infoAboutProducts:
          'Fornecer-lhe informações sobre os produtos e serviços que oferecemos (pode interromper a entrega de e-mails de marketing, seguindo as instruções que acompanham uma determinada comunicação)',
        resolvingDisputes:
          'Resolução de disputas, cobrança de taxas, e resolução de problemas',
      },
      two:
        'Utilizamos a informação que nos fornece ao fazer uma encomenda para completar essa encomenda. Não partilhamos estas informações com terceiros, exceto: (A) na medida do necessário para completar essa encomenda ou para lhe fornecer serviços através dos nossos fornecedores de serviços (por exemplo processamento de pagamentos, expedição por terceiros, análise comercial, apoio ao cliente); (B) de acordo com os requisitos legais e regulamentares; (C) se necessário, à discrição exclusiva da FluentWorlds, para proteger os direitos percebidos, segurança e propriedade da FluentWorlds, dos utilizadores dos nossos websites, e do público; ou (D) com entidades afiliadas ou subsidiárias da FluentWorlds para gerir e controlar Dados como utilizadores conjuntos. Para além do acima exposto, o utilizador será notificado quando os seus Dados de Identificação Pessoal forem partilhados com terceiros e terá a oportunidade de optar por não nos fazer partilhar tais informações.',
      three:
        'Além disso, utilizamos a informação que recolhemos de forma agregada para que nenhum utilizador individual seja identificado. Esta informação pode ser utilizada para o efeito: (A) ajudar ao desenvolvimento estratégico; (B) construir perfis de marketing; ou (C) auditar a utilização do website.',
    },
    useAndDisclosureJapan: {
      title: 'Utilização e Divulgação de Informação Pessoal no Japão',
      one:
        'No Japão, para além do acima referido, podemos também partilhar dados recolhidos através de mercados de distribuição digital como a Apple App Store com Provedores Conjuntos. Os "Joint Providers" são as empresas com as quais estabelecemos parcerias para oferecer, apoiar ou gerir a FluentWorlds e/ou Contas de Fornecedores Conjuntos, produtos ou serviços, e para gerir e controlar Dados, sujeitos às políticas de privacidade da FluentWorlds e/ou dos Fornecedores Conjuntos. No Japão, o Fornecedor Conjunto responsável pela gestão de Dados utilizados em conjunto é a Sourcenext Corporation.',
    },
    useOfCookies: {
      title: 'Utilização de Cookies e Outros Dispositivos de Rastreio',
      one:
        'Não utilizamos quaisquer dispositivos de rastreio para rastrear a sua utilização da Internet em outros websites não operados pela FluentWorlds. Utilizamos cookies e outros dispositivos de rastreio, incluindo objetos locais partilhados, para ajudar a personalizar a sua utilização dos nossos sites. Se não quiser ajudar-nos a aprender como melhorar o nosso website, produtos, ofertas e estratégia de marketing, pode optar por configurar o seu navegador da web para não aceitar cookies. A parte de Ajuda da barra de ferramentas na maioria dos browsers indicar-lhe-á como evitar que o seu browser aceite novos cookies, como fazer com que o browser o notifique quando receber um novo cookie, ou como desativar totalmente os cookies.',
      two:
        'Um cookie é uma pequena peça de informação que é enviada para o disco rígido do seu computador pelo servidor web para que o sítio web se possa lembrar de quem você é. Estas informações podem incluir informações relativas à sua utilização dos nossos sites, informações sobre o seu computador, tais como o endereço IP do computador e tipo de navegador, dados demográficos e, se chegou ao nosso site através de uma ligação de um site de terceiros, o URL da página de ligação. Se for um utilizador registado, isto pode incluir o seu nome e endereço de correio eletrónico para fins de verificação.',
      three:
        'Utilizamos informações de cookies para fins que podem incluir: (A) identificação de utilizadores e registantes que regressaram; (B) permitir-lhe deslocar-se mais facilmente pelo nosso sítio; (C) rastrear a sua utilização do nosso sítio para melhor desenvolver os nossos sítios de acordo com as suas exigências; ou (D) construir um perfil demográfico.',
      four:
        'De tempos a tempos, podemos permitir que empresas terceiras instalem cookies nos websites da FluentWorlds para fins que podem incluir pesquisa de mercado, rastreio de receitas, ou para melhorar a funcionalidade do website.',
    },
    securityPolicy: {
      title: 'Política de Segurança',
      one:
        'A FluentWorlds dispõe de medidas adequadas para ajudar a assegurar que os dados dos nossos utilizadores são protegidos contra acesso ou utilização não autorizada, alteração, destruição ilegal ou acidental e perda acidental. Embora façamos esforços razoáveis para proteger os seus Dados de Identificação Pessoal contra perda, utilização indevida, ou alteração por terceiros, deve estar ciente de que existe sempre algum risco envolvido na transmissão de informações através da Internet. Há também algum risco de que os ladrões possam encontrar uma forma de frustrar os nossos sistemas de segurança.',
    },
    dataAccess: {
      title: 'Acesso a Dados e Informações de Contacto',
      chiefPrivacyOfficer: 'Director de Privacidade',
      one:
        'Pode solicitar uma cópia ou enviar uma correção das informações pessoais que temos sobre si, contactando o Chief Privacy Officer em chiefprivacyofficer@fluentworlds.com ou escrevendo para',
      two:
        'Quando exigido pela lei aplicável, e nomeadamente pela GDPR, o utilizador tem o direito de obter confirmação da existência de certos dados pessoais que lhe dizem respeito, de verificar o seu conteúdo, origem e exatidão, bem como o direito de aceder, rever, portar, apagar, ou bloquear ou retirar o consentimento para o tratamento de certos dados pessoais (sem afetar a legalidade do tratamento baseado no consentimento antes da sua retirada), contactando-nos. Em particular, tem o direito de se opor à nossa utilização de dados pessoais para marketing direto e em determinadas outras situações, a qualquer momento. Contacte-nos para obter mais detalhes. Note que determinados dados pessoais podem ser retidos conforme requerido ou permitido pela lei aplicável.',
      three:
        'Se for um utilizador final a receber acesso aos nossos serviços através de uma Empresa, Governo, Educação ou outra Organização Cliente Empresarial da FluentWorlds, e desejar solicitar acesso, limitar a utilização, limitar a divulgação, ou remover os seus dados pessoais de Utilizador Final, queira contactar a organização Cliente Empresarial que nos enviou os seus dados pessoais, e apoiá-la-emos na medida do necessário para responder ao seu pedido.',
      four:
        'Se estiver a contactar-nos para solicitar informações ou acão relativamente aos dados pessoais que recolhemos sobre si, ou para exercer os seus direitos de proteção de dados, envie o(s) seu(s) pedido(s) através do nosso Chief Privacy Officer por correio eletrónico para chiefprivacyofficer@fluentworlds.com. Se tiver outras questões ou pedidos relativos às suas informações pessoais, queira contactar-nos por correio eletrónico para chiefprivacyofficer@fluentworlds.com.',
      five:
        'Como acima referido, pode optar por não fornecer informações pessoais à FluentWorlds, embora possa ser necessário registar-se como membro no serviço e participar em certas funcionalidades oferecidas através do serviço. Pode interromper a entrega de comunicações comerciais por correio eletrónico que a FluentWorlds envia, seguindo as instruções que acompanham uma determinada comunicação.',
      six: {
        one:
          'Se for um residente europeu com questões relativas aos seus direitos em matéria de dados pessoais ao abrigo da GDPR, por favor contacte o Chief Privacy Officer da FluentWorlds por e-mail para',
        two:
          'Podemos cobrar a taxa admissível ao abrigo da lei aplicável pelo fornecimento desta informação.',
      },
    },
    children: {
      title: 'Crianças com menos de 13 anos',
      one:
        'Não recolhemos intencionalmente qualquer informação sobre crianças com menos de 13 anos de idade. Comprometemo-nos a eliminar quaisquer dados (com a possível exceção do endereço de correio eletrónico dos pais ou tutores) de tais utilizadores quando um dos pais ou tutores nos tenha notificado de que tais dados foram obtidos.',
    },
    changes: {
      title: 'Alterações a esta Política',
      one:
        'Esta política é eficaz a partir de 14 de fevereiro de 2020. De tempos a tempos poderemos fazer alterações a esta Política de Privacidade para refletir quaisquer alterações às nossas práticas de privacidade de acordo com alterações na legislação, melhores práticas, ou melhorias de produtos e serviços. Iremos notificá-lo sobre alterações materiais a esta Política de Privacidade, colocando um aviso em destaque no nosso website.',
    },
    lastReviewed: 'Última revisão: 1º de Fevereiro de 2020',
    lastUpdated:
      'Última atualização e eficácia a partir de: 14 de Fevereiro de 2020',
  },
  termsOfUse: {
    title: 'Termos de Uso | FluentWorlds',
    description:
      'Revise nossos Termos de Uso e outras informações legais para o Fluentworlds Language Learning',
    header: 'Termos de Uso',
    lastUpdated: 'Ultima atualização: 01 de Fevereiro de 2017',
    overview: {
      one:
        'Por favor, leia estes Termos e Condições (“Termos”, “Termos e Condições”) cuidadosamente antes de usar o site www.fluentworlds.com e o aplicativo móvel FluentWorlds (junto ou individualmente, o “Serviço”) operado pela Virtual Immersive Educational Worlds, Inc (“nós” ou “nosso”).',
      two:
        'Seu acesso e uso do Serviço estão condicionados à sua aceitação e conformidade com estes Termos. Estes Termos se aplicam a todos os visitantes, usuários e outros que desejam acessar ou usar o Serviço.',
      three:
        'Ao acessar ou usar o Serviço, você concorda em ficar vinculado a estes Termos. Se você discordar de qualquer parte dos termos, não terá permissão para acessar o Serviço.',
    },
    purchases: {
      title: 'Compras',
      one:
        'Se você deseja comprar qualquer produto ou serviço disponibilizado através do Serviço (“Compra”), pode ser solicitado que você forneça certas informações relevantes para sua compra, incluindo, sem limitação, o número do seu cartão de crédito, a data de validade do seu cartão de crédito, seu endereço de cobrança e suas informações de envio.',
      two:
        'Você declara e garante que: (i) tem o direito legal de usar qualquer cartão de crédito ou outro(s) método(s) de pagamento em conexão com qualquer Compra; e que (ii) as informações que você nos fornece são verdadeiras, corretas e completas.',
      three:
        'O serviço pode empregar o uso de serviços de terceiros com a finalidade de facilitar o pagamento e a conclusão das Compras. Ao enviar suas informações, você nos concede o direito de fornecer as informações a esses terceiros sujeitos à nossa Política de Privacidade.',
    },
    errorFixes: {
      title: 'Disponibilidade, Erros e Imprecisões',
      one:
        'Estamos constantemente atualizando ofertas de produtos e serviços no Serviço. Podemos enfrentar atrasos na atualização de informações sobre o Serviço e em nossa publicidade em outros sites. As informações encontradas no Serviço podem conter erros ou imprecisões e podem não estar completas ou atualizadas. Os produtos ou serviços podem ter preços incorretos, descritos de forma imprecisa ou indisponíveis no Serviço e não podemos garantir a precisão ou integridade de qualquer informação encontrada no Serviço.',
      two:
        'Portanto, nos reservamos o direito de alterar ou atualizar informações e corrigir erros, imprecisões ou omissões a qualquer momento sem aviso prévio.',
    },
    subscriptions: {
      title: 'Assinaturas',
      one:
        'Algumas partes do Serviço são cobradas com base em assinatura (“Assinatura(s)”). Você será cobrado antecipadamente de forma recorrente e periódica (“Ciclo de Cobrança”). Os ciclos de cobrança são definidos mensalmente.',
      two:
        'No final de cada Ciclo de Faturamento, sua Assinatura será renovada automaticamente exatamente nas mesmas condições, a menos que você a cancele ou a Virtual Immersive Educational Worlds, Inc a cancele. Você pode cancelar a renovação da sua Assinatura por meio da página de gerenciamento de conta on-line ou entrando em contato com a equipe de suporte ao cliente da Virtual Immersive Educational Worlds, Inc.',
      three:
        'Um método de pagamento válido, incluindo cartão de crédito ou PayPal, é necessário para processar o pagamento de sua Assinatura. Você deve fornecer à Virtual Immersive Educational Worlds, Inc informações de cobrança precisas e completas, incluindo nome completo, endereço, estado, código postal, número de telefone e informações válidas sobre o método de pagamento. Ao enviar essas informações de pagamento, você autoriza automaticamente a Virtual Immersive Educational Worlds, Inc a cobrar todas as taxas de assinatura incorridas em sua conta em qualquer um desses instrumentos de pagamento.',
      four:
        'Caso a cobrança automática não ocorra por qualquer motivo, a Virtual Immersive Educational Worlds, Inc emitirá uma fatura eletrônica indicando que você deve proceder manualmente, dentro de um determinado prazo, com o pagamento integral correspondente ao período de cobrança indicado na fatura.',
    },
    freeTrial: {
      title: 'Teste grátis',
      one:
        'A Virtual Immersive Educational Worlds, Inc pode, a seu exclusivo critério, oferecer uma Assinatura com uma avaliação gratuita por um período de tempo limitado (“Avaliação Gratuita”).',
      two:
        'Você pode ser solicitado a inserir suas informações de cobrança para se inscrever na avaliação gratuita.',
      three:
        'Se você inserir suas informações de cobrança ao se inscrever para a avaliação gratuita, você não será cobrado pela Virtual Immersive Educational Worlds, Inc até que a avaliação gratuita tenha expirado. No último dia do período de Teste Gratuito, a menos que você cancele sua Assinatura, serão cobradas automaticamente as taxas de Assinatura aplicáveis para o tipo de Assinatura que você selecionou.',
      four:
        'A qualquer momento e sem aviso prévio, a Virtual Immersive Educational Worlds, Inc reserva-se o direito de (i) modificar os termos e condições da oferta de avaliação gratuita ou (ii) cancelar tal oferta de avaliação gratuita.',
    },
    feeChanges: {
      title: 'Alterações de Taxas',
      one:
        'Virtual Immersive Educational Worlds, Inc, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.',
      two:
        'Virtual Immersive Educational Worlds, Inc, a seu exclusivo critério e a qualquer momento, pode modificar as taxas de assinatura para as assinaturas. Qualquer alteração na taxa de assinatura entrará em vigor no final do ciclo de cobrança atual.',
      three:
        'Seu uso continuado do Serviço após a alteração da taxa de Assinatura entrar em vigor constitui sua concordância em pagar o valor da taxa de Assinatura modificada.',
    },
    refunds: {
      title: 'Reembolsos',
      one:
        'Exceto quando exigido por lei, as taxas de Assinatura pagas não são reembolsáveis.',
    },
    accounts: {
      title: 'Accounts',
      one:
        'Ao criar uma conta conosco, você garante que tem mais de 18 anos e que as informações que você nos fornece são precisas, completas e atualizadas o tempo todo. Informações imprecisas, incompletas ou obsoletas podem resultar no cancelamento imediato de sua conta no Serviço.',
      two:
        'Você é responsável por manter a confidencialidade de sua conta e senha, incluindo, entre outros, a restrição de acesso ao seu computador e/ou conta. Você concorda em aceitar a responsabilidade por todas e quaisquer atividades ou ações que ocorram em sua conta e/ou senha, seja sua senha com nosso Serviço ou um serviço de terceiros. Você deve nos notificar imediatamente ao tomar conhecimento de qualquer violação de segurança ou uso não autorizado de sua conta.',
      three:
        'Reservamo-nos o direito de recusar serviços, encerrar contas, remover ou editar conteúdo ou cancelar pedidos a nosso exclusivo critério.',
    },
    copyright: {
      title: 'Política de Direitos Autorais',
      one:
        'Respeitamos os direitos de propriedade intelectual de terceiros. É nossa política responder a qualquer reclamação de que o Conteúdo postado no Serviço infringe os direitos autorais ou outros direitos de propriedade intelectual (“Violação”) de qualquer pessoa ou entidade.',
      two:
        'Se você é um proprietário de direitos autorais, ou autorizado em nome de um, e acredita que o trabalho protegido por direitos autorais foi copiado de forma que constitua violação de direitos autorais, envie sua reivindicação por e-mail para david.bradford@fluentWorlds.com, com o assunto linha: "Copyright Infringement" e inclua em sua reivindicação uma descrição detalhada da suposta violação, conforme detalhado abaixo, em "Aviso e procedimento DMCA para reivindicações de violação de direitos autorais"',
      three:
        'Você pode ser responsabilizado por danos (incluindo custos e honorários advocatícios) por declarações falsas ou reivindicações de má-fé sobre a violação de qualquer Conteúdo encontrado no e/ou por meio do Serviço sobre seus direitos autorais.',
    },
    dmca: {
      title:
        'Aviso DMCA e Procedimento para Reivindicações de Violação de Direitos Autorais',
      one: {
        overview:
          'Você pode enviar uma notificação de acordo com a Lei de Direitos Autorais do Milênio Digital (DMCA) fornecendo ao nosso Agente de Direitos Autorais as seguintes informações por escrito (consulte 17 U.S.C 512(c)(3) para obter mais detalhes):',
        listItem1:
          'uma assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos autorais;',
        listItem2:
          'uma descrição do trabalho protegido por direitos autorais que você alega ter sido infringido, incluindo o URL (ou seja, endereço da página da Web) do local onde o trabalho protegido por direitos autorais existe ou uma cópia do trabalho protegido por direitos autorais;',
        listItem3:
          'identificação da URL ou outro local específico no Serviço onde está localizado o material que você alega estar infringindo;',
        listItem4: 'seu endereço, número de telefone, e endereço de e-mail;',
        listItem5:
          'uma declaração sua de que você acredita de boa fé que o uso contestado não foi autorizado pelo proprietário dos direitos autorais, seu agente ou pela lei;',
        listItem6:
          'uma declaração sua, feita sob pena de perjúrio, de que as informações acima em seu aviso são precisas e que você é o proprietário dos direitos autorais ou está autorizado a agir em nome do proprietário dos direitos autorais.',
      },
      two:
        'Você pode entrar em contato com nosso agente de direitos autorais por e-mail em david.bradford@fluentWorlds.com.',
    },
    intellectualProperty: {
      title: 'Propriedade Intelectual',
      one:
        'O Serviço e seu conteúdo, recursos e funcionalidades originais são e continuarão sendo propriedade exclusiva da Virtual Immersive Educational Worlds, Inc e de seus licenciadores. O Serviço é protegido por direitos autorais, marcas registradas e outras leis dos Estados Unidos e de outros países. Nossas marcas registradas e identidade visual não podem ser usadas em conexão com nenhum produto ou serviço sem o consentimento prévio por escrito da Virtual Immersive Educational Worlds, Inc.',
    },
    links: {
      title: 'Links para outros sites',
      one:
        'Nosso Serviço pode conter links para sites ou serviços de terceiros que não são de propriedade ou controlados pela Virtual Immersive Educational Worlds, Inc.',
      two:
        'A Virtual Immersive Educational Worlds, Inc não tem controle e não assume nenhuma responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros. Não garantimos as ofertas de nenhuma dessas entidades/indivíduos ou seus sites.',
      three:
        'Você reconhece e concorda que a Virtual Immersive Educational Worlds, Inc não será responsável, direta ou indiretamente, por qualquer dano ou perda causada ou supostamente causada por ou em conexão com o uso ou confiança em qualquer conteúdo, bens ou serviços disponível em ou através de tais sites ou serviços de terceiros.',
      four:
        'Aconselhamos fortemente que você leia os termos e condições e políticas de privacidade de quaisquer sites ou serviços de terceiros que você visitar.',
    },
    termination: {
      title: 'Terminação',
      one:
        'Podemos encerrar ou suspender sua conta e barrar o acesso ao Serviço imediatamente, sem aviso prévio ou responsabilidade, a nosso exclusivo critério, por qualquer motivo e sem limitação, incluindo, entre outros, uma violação dos Termos.',
      two:
        'Se desejar encerrar sua conta, você pode simplesmente interromper o uso do Serviço.',
      three:
        'Todas as disposições dos Termos que, por sua natureza, devam sobreviver à rescisão, sobreviverão à rescisão, incluindo, sem limitação, disposições de propriedade, isenções de garantia, indenização e limitações de responsabilidade.',
    },
    indemnification: {
      title: 'Indenização',
      one:
        'Você concorda em defender, indenizar e isentar Virtual Immersive Educational Worlds, Inc e seus licenciados e licenciadores, e seus funcionários, contratados, agentes, executivos e diretores, de e contra todas e quaisquer reclamações, danos, obrigações, perdas, responsabilidades, custos ou dívida e despesas (incluindo, entre outros, honorários advocatícios), resultantes ou decorrentes de a) seu uso e acesso ao Serviço, por você ou por qualquer pessoa que use sua conta e senha, ou b) violação destes Termos.',
    },
    limitation: {
      title: 'Limitação de Responsabilidade',
      one:
        'Em nenhum caso a Virtual Immersive Educational Worlds, Inc, nem seus diretores, funcionários, parceiros, agentes, fornecedores ou afiliados, serão responsáveis por quaisquer danos indiretos, incidentais, especiais, consequentes ou punitivos, incluindo, sem limitação, perda de lucros, dados , uso, boa vontade ou outras perdas intangíveis, resultantes de (i) seu acesso ou uso ou incapacidade de acessar ou usar o Serviço; (ii) qualquer conduta ou conteúdo de terceiros no Serviço; (iii) qualquer conteúdo obtido do Serviço; e (iv) acesso não autorizado, uso ou alteração de suas transmissões ou conteúdo, seja com base em garantia, contrato, ato ilícito (incluindo negligência) ou qualquer outra teoria legal, tenhamos ou não sido informados da possibilidade de tal dano, e até mesmo se um recurso aqui estabelecido falhar em seu propósito essencial.',
    },
    disclaimer: {
      title: 'Isenção de responsabilidade',
      one:
        'Seu uso do Serviço é por sua conta e risco. O Serviço é fornecido “COMO ESTÁ” e “COMO DISPONÍVEL”. O Serviço é fornecido sem garantias de qualquer tipo, expressas ou implícitas, incluindo, entre outras, garantias implícitas de comercialização, adequação a uma finalidade específica, não violação ou curso de desempenho.',
      two:
        'A Virtual Immersive Educational Worlds, Inc, suas subsidiárias, afiliadas e seus licenciadores não garantem que a) o Serviço funcionará ininterruptamente, seguro ou disponível em qualquer horário ou local específico; b) quaisquer erros ou defeitos serão corrigidos; c) o Serviço está livre de vírus ou outros componentes nocivos; ou d) os resultados do uso do Serviço atenderão às suas necessidades.',
    },
    exclusions: {
      title: 'Exclusões',
      one:
        'Algumas jurisdições não permitem a exclusão de certas garantias ou a exclusão ou limitação de responsabilidade por danos consequentes ou incidentais, portanto as limitações acima podem não se aplicar a você.',
    },
    governingLaw: {
      title: 'Lei Aplicável',
      one:
        'Estes Termos serão regidos e interpretados de acordo com as leis de Utah, Estados Unidos, independentemente de conflitos de provisões legais.',
      two:
        'Nossa falha em aplicar qualquer direito ou disposição destes Termos não será considerada uma renúncia a esses direitos. Se qualquer disposição destes Termos for considerada inválida ou inexequível por um tribunal, as demais disposições destes Termos permanecerão em vigor. Estes Termos constituem o acordo integral entre nós em relação ao nosso Serviço e substituem quaisquer acordos anteriores que possamos ter tido entre nós em relação ao Serviço.',
    },
    changes: {
      title: 'Mudanças',
      one:
        'Reservamo-nos o direito, a nosso exclusivo critério, de modificar ou substituir estes Termos a qualquer momento. Se uma revisão for importante, forneceremos um aviso com pelo menos 30 dias de antecedência para que quaisquer novos termos entrem em vigor. O que constitui uma mudança material será determinado a nosso exclusivo critério.',
      two:
        'Ao continuar acessando ou usando nosso Serviço após qualquer revisão entrar em vigor, você concorda em ficar vinculado aos termos revisados. Se você não concordar com os novos termos, não estará mais autorizado a usar o Serviço.',
    },
    contact: {
      title: 'Contate-nos',
      one:
        'Se você tiver alguma dúvida sobre estes Termos, entre em contato conosco.',
    },
  },
  digitalGoIndex: {
    header1: 'Thank God for FluentWorlds! Thank you for creating it!',
    button: 'GET ACCESS NOW!',
    learnMore: 'Learn More',
    theWorldIsNotFlat: "The World isn't Flat.",
    languageLearning: "Language learning shouldn't be either.",
    whyFluentWorlds: {
      header: 'Why FluentWorlds?',
      p1: '4.9 Stars on iTunes with over 2000 ratings!',
      p2: 'Over 1 Million downloads of FluentWorlds products!',
      p3: 'Built-in voice recognition and analysis!',
      p4: 'Learn in real-life situations!',
      p5: 'Fast-track to learn new languages!',
    },
    features: 'Features',
    startUsingFluentWorlds: 'Start using FluentWorlds Now!',
    immersive:
      'Learn new languages in an immersive 3D system, as you explore everyday scenarios.',
    downloadOn: 'Download on',
  },
  testimonySection: {
    fastTrack: 'Fast-Track to Learn New Languages',
    hear: 'Hear what our customers are saying',
    testimony1:
      '"Every week, I am making tremendous progress on my English conversation. FluentWorlds is the fastest and more enjoyable way to learn English!" - Leonardo',
    testimony2:
      '  "I have tried Duolingo and Babbel but I really couldn\'t keep it up, because it\'s very dull and I start forgetting words. They are a flat learning journey. FluentWorlds is very unique to me, and I think it will be a disruption! I definitely feel more engaged to be in a 3D environment" - Ana',
    testimony3:
      '"I was testing the FluentWorlds app and I find it incredible! It\'s very useful and fun! It has been of great help to me to expand my vocabulary... It\'s amazing, thank you so much!" - Juan',
  },
  freeTrialCallToActionButton: {
    tryForFree: 'Try FluentWorlds 7 Days For Free',
    alreadyHaveProductAccess: 'You already have access to the product!',
  },
  appActions: {
    initialMessage: 'Handling request',
    returningToApp:
      'Returning to app. You may close this page once the app loads.',
    unknownApp: 'Unknown app specified',
    tokenError: 'Could not retrieve token',
    checkingStatus: 'Checking status',
    actionStartLogin: 'Redirecting to login...',
    actionCompleteLogin: 'Retrieving info',
    actionStartLogout: 'Logging out...',
    actionStartManage: 'Redirecting to account...',
    actionStartDefault: 'Unexpected state encountered. Please try again.',
  },
}

export default en
