const ja = {
  newsletter: {
    genericError:
      'サブスクリプション リクエストの処理中にエラーが発生しました。エラーが続く場合は、support@fluentworlds.com までご連絡ください。',
    invalidEmail:
      '無効なメールアドレス。電子メールは @ を含む有効な形式である必要があります (例: email@domain.com)',
    successfulSubscribe: 'あなたは購読しています！',
    subscribeButton: 'ニュースレターを購読します',
  },
  modalOffer: {
    title: 'メリークリスマス',
    text:
      'チェックアウト時にこのクーポンを提示すると、主要な語学学習アプリが 50% オフになります!',
  },
  header: {
    home: 'ホームページ',
    products: '製品',
    ambassador: 'Become An Ambassador',
    myAccount: '私のアカウント',
    greeting: 'こんにちは',
    signOut: 'サインアウト',
    redeem: 'Redeem',
    blog: 'Blog',
  },
  blog: {
    title: 'FluentWorlds Blog',
    description: 'See what the FluentWorlds community has been talking about!',
    recent1: 'How to Introduce Yourself in English | Self Introductions',
    recent1Date: 'March 23, 2023',
    recentDescription1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    recent2: '10 Common Difficult Words to Pronounce in English',
    recent2Date: 'March 28, 2023',
    recentDescription2:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English.',
    recent3: 'English Vocabulary Practice | Thought vs. Thought',
    recent3Date: 'April 14, 2023',
    recentDescription3:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    recent4: 'Confusing English Words | Homophones Explained with Examples',
    recent4Date: 'May 19, 2023',
    recentDescription4:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand?',
    recent5: 'Confusing English Tongue Twisters',
    recent5Date: 'June 8, 2023',
    recentDescription5:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister.',
  },
  post1: {
    post1Title: 'How to Introduce Yourself in English | Self Introductions',
    post1Author: 'By Aleja Briles',
    post1Date: 'March 23, 2023',
    post1Paragraph1:
      'Have you ever met someone and wanted to introduce yourself, but you weren’t quite sure what to say or how to keep the conversation going?',
    post1Paragraph2:
      'Today’s topic may seem a bit beginner, but self introductions are an essential skill that you need to know in any situation. Whether you’re meeting a person for the first time, or given an assignment and need to speak to a larger group of people, this post will help you learn the best ways to introduce yourself.',
    post1Paragraph3:
      'Let’s talk about the key elements of a self-introduction. Let’s start off with a greeting, something like, “It’s so nice to meet you” or “It’s a pleasure to meet you.” The difference between these two examples is that one is slightly more formal than the other. The first example, “It’s so nice to meet you,” is the more informal of the two and is what you’re going to hear and probably say the most often. “It’s so nice to meet you.” The second example is the more formal greeting, “It’s a pleasure to meet you.” In this instance you would say this if you’re meeting someone in a business setting or for the first time. This is especially true if it’s someone you’ve been wanting to meet for a long time. If the other person is the first person to say “It’s so nice to meet you,” you can reply with “It’s nice to meet you too.”',
    post1Paragraph4:
      'Next you will want to say your name to let them know who you are. You can say this in one of two ways. The first way is “My name is _____.” and the second is “I am _____.” So for myself, I would say, “My name is Aleja.” or I could say, “I am Aleja.” Either one of those will work in any scenario to introduce yourself.',
    post1Paragraph5:
      'Now is the fun part where you get into the details about yourself. You can share things like where you’re from, what you do for a living, what brings you to the area that you’re in etc. For example, to tell someone where you are from you can say, “I’m from ____, it’s a city in ____.” Using myself as an example, I would say, “I’m from Salt Lake City, it’s a city in Utah.”',
    post1Paragraph6:
      'The next thing you could say is, “I’m currently living in _____.” This is if you’re from a specific city but you’re not currently living there. For example “I’m from New York, but I’m currently living in Dallas.” ',
    post1Paragraph7:
      'To keep the conversation going, you can continue to ask more questions. To learn about their occupation or job you can ask “what do you do for work?” Let’s pretend they work in engineering. The ‘ing’ makes it an action so they might say, “I work in engineering” but they could also say “I work as an engineer.” Those are two ways to say the same thing. Another example is “I work in education.” Or I could say “I work as a teacher or educator.”',
    post1Paragraph8:
      '“What brings you to the area?” This is a great question to ask someone, especially if you‘re meeting them at a place where people typically vacation or travel to frequently. “What brings you to the area?” you could then respond with something like “I‘m here on vacation” or “I‘m just visiting.” That‘s a very simple answer that could include a vacation, work, visiting family etc. If you‘re in a one-on-one situation it‘s also vital that you ask the other person about themselves as well, that way it‘s a two-way conversation. If you‘re only talking about yourself it can be perceived as rude or uninterested.',
    post1Paragraph9:
      'Here are some basic questions that you can ask another person about themselves:',
    post1ListItem1: 'Where are you from?',
    post1ListItem2: 'Are you from around here?',
    post1ListItem3: 'How long have you lived here?',
    post1ListItem4: 'Could you remind me of your name?',
    post1ListItem5: 'Where do you work?',
    post1ListItem6: 'Do you like your job?',
    post1ListItem7: 'Do you like this area?',
    post1ListItem8: 'What brings you to the area?',
    post1ListItem9: 'What do you like to do for fun? ',
    post1Paragraph10:
      'Now we‘re going to learn how to exit from the conversation. You can say things like “It was so nice to meet you, I hope to see you again soon” or “It was so nice getting to know you, take care!” You want to reinforce that it was nice meeting the person, and that you hope to have interactions with them in the future.',
    post1Paragraph11:
      'Let‘s do a quick review. Your introduction or first time conversation should be somewhat short, but should open up the potential to have a longer conversation. My introduction would sound something like:',
    post1Paragraph12:
      '“Hi, my name is Aleja. It‘s so nice to meet you. I‘m here in Salt Lake City visiting some friends for fun. What brings you here? I live in Dallas, but I’m from New York. I‘ve lived there for about a year. Where are you from? It was so nice getting to know you, take care!”',
    post1Paragraph13:
      'I hope that you feel confident now in understanding the basics of a self-introduction. All of the skills we learned today can be used in multiple scenarios whether you‘re meeting someone one-on-one or giving a broader explanation about yourself. If you have any additional questions or want to hear me talk more about introductions, head on over to our youtube channel and watch our video all about how to introduce yourself!',
    post1VideoLinkText: 'https://youtu.be/Y8rfZjWlKbY',
  },
  post2: {
    post2Title: '10 Common Difficult Words to Pronounce in English',
    post2Author: 'By Aleja Briles',
    post2Date: 'March 28, 2023',
    post2Paragraph1:
      'Trying to understand English can be a headache, even for native speakers. That’s why I want to cover 10 common difficult words to say in American English. Working on pronunciation of words is one of the most important steps to gaining confidence in speaking English fluently. If you want to hear how I pronounce these words, check out this youtube video here: ',
    post2VideoLinkText: 'https://youtu.be/UgZjlGBKi6s',
    post2Paragraph2:
      'In this post, we’re going to focus on 10 words in English that might seem very difficult to pronounce for ESL learners. My goal is to have you using these words more confidently by the end of today’s lesson. Let’s jump in!',
    post2Word1: 'Colonel (kur-nuhl):',
    post2ListItem1:
      '\nThis word is pronounced ‘kernel’. This is someone in the military. You‘re going to hear this word more often than you think, especially when referencing family, friends or acquaintances that you may know that are in the military.',
    post2Word2: 'Mischievous (mis-chee-vee-us):',
    post2ListItem2:
      '\nSometimes with this word you may hear different pronunciations depending on what region you‘re in or what country you might be in. In American English more often than not you‘re going to hear ‘mis-chee-vee-uhs’.  This is if someone is sneaky or a little bit naughty or misbehaves but in an endearing way. ',
    post2Word3: 'Drought (drowt):',
    post2ListItem3:
      '\nA drought is when there is a lack of rain or water, when the land is dry or if an area has not had rain in a long time. In this word the ‘gh’ sound is silent which makes it confusing for people trying to learn English. ',
    post2Word4: 'Scissors (si-zrz):',
    post2ListItem4:
      '\nIn the words scissors the C in this word appears to be silent and that double ‘s’ is going to give you more of a ‘z’ sound. Scissors are what we use to cut paper. I‘m sure you‘ve all seen scissors before.',
    post2Word5: 'Successful (suhk-seh-sfl): ',
    post2ListItem5:
      '\nThe word successful is definitely a mouthful. What I always encourage my students to do is to act as though you‘re smiling as you say this word. That will help you get out those letters more easily and fluently. That double C makes a ‘k’ sound. ',
    post2Word6: 'Sixth (siksth): ',
    post2ListItem6:
      '\nSixth is the number order  after fifth and before seventh. That ‘xth’ can be really difficult to pronounce. ',
    post2Word7: 'Rural (rur-uhl): ',
    post2ListItem7:
      '\nThis is a word that‘s actually pretty difficult for me to say as well so don‘t be hard on yourself if you can‘t get it. This word is ‘rural’.  This word has two of those difficult ‘er’ sounds that we have a lot in English. This is when something is out in the farmland or in ranch land. Open space is typically referred to as rural.',
    post2Word8: 'Specific (spuh-si-fuhk): ',
    post2ListItem8:
      '\nThe word specific has two of the letter ‘c’ pretty close to each other. That last ‘c’ sounds like a ‘k’ sound. This is not to be confused with words like Pacific which is the ocean. The word specific means something in particular or something that we’re trying to point out. If I say “I want this specific cookie,” I want a certain cookie. I want THAT one.',
    post2Word9: 'Temperature (tem-pruh-chr): ',
    post2ListItem9:
      '\nThis is another word that you might hear pronounced differently from region to region or across different countries. Here in America we say ‘tem-pruh-chr’ so that ‘er’ sound is not pronounced separately in temperature.',
    post2Word10: 'Often (aa-fn): ',
    post2ListItem10:
      '\nI know this word seems pretty basic but that ‘oft’ can be difficult to bring together. Often refers to something that occurs frequently or regularly. ',
    post2Paragraph3:
      'These words may not always come up in everyday conversation, but when they do, you‘re going to be prepared and be able to show off your English skills. Check back weekly for more English learning posts! See you soon! ',
  },
  post3: {
    post3Title: 'English Vocabulary Practice | Thought vs. Thought',
    post3Author: 'By Aleja Briles',
    post3Date: 'April 14, 2023',
    post3Paragraph1:
      'A great way to expand your English vocabulary is to isolate one word and dive into its definitions while learning how to use it in context.',
    post3Paragraph2:
      'Today we’re going to be focusing on one word. This word is used pretty often in English conversation and can be used in a few different ways. The word I am talking about is the word ‘thought’.  In English the word ‘thought’ can be used in two different contexts:',
    post3ListItem1:
      'It can mean an idea. For example “I had a thought about how we could solve this problem.”',
    post3ListItem2:
      'It can also mean something that you were thinking about in the past or that you believed in the past. For example “I thought your name was Ashley not Amanda.” ',
    post3Paragraph3:
      'To help you understand how to use this word in context, let’s go over 20 different phrases that use the word ‘thought’.',
    post3Subtitle1: '‘Thought’ as in a belief',
    post3ListItem3: 'I thought you were from New York.',
    post3ListItem4: 'I thought we were going to the beach today.',
    post3ListItem5: 'They thought I was from India but I’m from Pakistan.',
    post3ListItem6: 'We thought your friend was very nice.',
    post3ListItem7: 'Your sister thought I was already gone.',
    post3ListItem8: 'I thought that burger was delicious.',
    post3ListItem9: 'They thought the restaurant was nice.',
    post3ListItem10: 'We thought about it, but decided not to go.',
    post3ListItem11: 'He thought she had a sister.',
    post3ListItem12: 'I listened to the song and thought it was really good!',
    post3Paragraph4:
      'These phrases are all ‘thought’ in the context of something that you believed in the past. The word thought is really important in the context of these sentences. let’s move on to thought as in the idea or to think.',
    post3Subtitle2: '‘Thought’ as in an idea',
    post3ListItem13: 'Do you have any thoughts on the matter?',
    post3ListItem14: 'Does anyone have any thoughts on today’s lesson?',
    post3ListItem15: 'I thought we could all go to the park.',
    post3ListItem16: 'I never thought about a career in medicine.',
    post3ListItem17: 'She thought of her nephew when she saw this toy.',
    post3ListItem18: 'They thought it would be fun to travel together.',
    post3ListItem19: 'We thought going to a movie would be a great activity!',
    post3ListItem20: 'I thought a tutor might be helpful.',
    post3ListItem21: 'Does she have any more thoughts about the party?',
    post3ListItem22: 'I don’t have any additional thoughts.',
    post3Paragraph5:
      'There are many ways you can use the word thought. Can you think of any yourself? This week when you’re practicing using the word thought, make sure you’re able to use it in the two contexts listed above. Either something that you were thinking of in the past like “I thought about you” or an idea that you had like “I thought we could try this new restaurant.” For more explanation check out our video here: ',
    post3VideoLinkText: 'https://youtu.be/qE_NpSQftlY',
  },
  post4: {
    post4Title: 'Confusing English Words | Homophones Explained with Examples',
    post4Author: 'By Aleja Briles',
    post4Date: 'May 19, 2023',
    post4Paragraph1:
      'I’ll walk down the aisle on the tropical isle. The scent that he sent was only one cent. Wow! These sentences can be very confusing! Why? Because they are all about homophones!',
    post4Paragraph2:
      'Today’s post is completely focused on exploring homophones! So what is a homophone and why is it so important to understand? A homophone is two or more words that have the same pronunciation but different spelling. It’s so important to know the definitions and pronunciations of these words so that you can use them correctly in your conversations and in writing. Let’s go over a few examples!',
    post4ListItem1: 'Ate vs. Eight',
    post4ListPara1:
      'Let’s start with the first ‘ate’. This is the simple past tense of the verb ‘to eat’. For example, “I ate an entire pizza and now I’m really full.” or “She ate dinner before she came.” The second eight, as in the number eight, is a noun and represents the number after seven and before nine. For example “Charles will wake up at eight o’clock in the morning.” There’s a popular children’s joke that goes like this - Why was six afraid of seven? Answer - Because seven, eight, nine. Yes, it’s a pretty bad joke but it helps us learn the homophones and how they sound very similar to each other, but have different spelling.',
    post4ListItem2: 'Bare vs Bear',
    post4ListPara2:
      'The first ‘bare’ is an adjective. If something is bare that means it’s not covered or not decorated. “Tom likes to walk around his house in his bare feet, he says it’s more comfortable than wearing shoes” or “The walls were pretty bare when we moved into the house.” The second ‘bear’ is a noun. A bear is a large mammal in the forest like a grizzly bear, black bear or a brown bear. “When you go camping you should be careful to not leave any food out with a scent because it will attract bears.” or “The bear was by the river with her cubs.” ',
    post4ListItem3: 'Buy vs By vs Bye',
    post4ListPara3:
      'Have you heard of NSYNC? They have a song called ‘Bye Bye Bye’. This is a good way to remember that there are three homophones that sound the same for bye. The first ‘buy’ is a verb and means to purchase something. It‘s probably one of the first verbs that you learned in English because it’s used so commonly. “I forgot my money at home, do you think you could buy me lunch and I‘ll pay you back?” The second form of ‘by’ is a preposition. This can be used in many different ways but it‘s commonly used to mean next to or near when describing a location. For example “The car is parked in the lot by the big light post.” It can also indicate who created something. “My favorite autobiography is ‘The Autobiography of Malcolm X,” it‘s written by Malcolm X and Alex Haley.” Now for the third form of ‘bye’. This is an exclamation or a shortening of goodbye. “I‘ve gotta go now, bye!” It is how you can end a conversation in a casual informal way.',
    post4ListItem4: 'Cell vs. Sell',
    post4ListPara4:
      'A ‘cell’ is a noun and refers to a small area or room usually in a prison. A cell can also be one of the smallest divisions of a living organism. “The prisoner spent 10 years confined to his cell” or “We were able to see the plant’s cells better under a microscope.” The second ‘sell’ is a verb meaning to exchange a product or service for money. Similar to the verb ‘buy’, it was probably one of the first verbs you learned. “We would like to sell our car but we don’t think we would get very much money for it.”',
    post4ListItem5: 'Dew vs. Do vs. Due',
    post4ListPara5:
      'This is another set of three homophones. Let‘s start with the first one ‘dew’ which is a noun. Dew is the name for the small drops of water that accumulate on plants and other objects outside during the night. “When I went outside early in the morning the dew on the grass made my shoes wet.” The second ‘do’ is a verb. This common verb is used to indicate an action but can also be an auxiliary verb. “What do you usually do on Friday nights?” Lastly our third form of ‘due’. This is an adjective and is used to indicate the deadline or final day that something can happen. It‘s also used to indicate when a baby is expected to be born. “My friend is pregnant, her baby is due in December.” or “The homework assignment is due on Monday.”',
    post4Paragraph3:
      'This was definitely a lot of information! Of course these aren’t the only homophones, there are many more than you can find with a quick Google search. Homophones can be tricky for ESL speakers, but it will really set you apart as a competent English speaker to start learning them! Good luck learning the different homophones! Start to use them in daily conversation and you will have them down in no time! Best of luck! Check out our video on homophones here: ',
    post4VideoLinkText: 'https://youtu.be/ttgFlw1YgYI',
  },
  post5: {
    post5Title: 'Confusing English Tongue Twisters',
    post5Author: 'By Aleja Briles',
    post5Date: 'June 8, 2023',
    post5Paragraph1:
      'Have you ever heard of tongue twisters before? A tongue twister is a sequence of words or sounds that are difficult to pronounce quickly and correctly. It seems that almost every language has some form of a tongue twister. Tongue twisters are tricky but they will force you to enunciate similar sounding words to make each phrase intelligible. Here are a couple great examples of tongue twisters in English. Read through them carefully, and then go back and try and read through more quickly while keeping the correct pronunciation of each sound.',
    post5ListItem1: 'Peter Piper',
    post5ListPara1:
      '“Peter Piper picked a peck of pickled peppers. A peck of pickled peppers Peter Piper picked. If Peter Piper picked a peck of pickled peppers, where’s the peck of pickled peppers Peter Piper picked?”',
    post5ListPara2:
      'How did you do? Were there any sounds that were difficult to pronounce? If you want to hear me pronounce these words head over to our youtube channel and watch our video (linked at the bottom of this post) all about tongue twisters. Alright, let`s try another one!',
    post5ListItem2: 'Fuzzy Wuzzy',
    post5ListPara3:
      '“Fuzzy Wuzzy was a bear. Fuzzy Wuzzy had no hair. Fuzzy Wuzzy wasn’t very fuzzy was he?”',
    post5ListPara4:
      'Great job! That one’s gonna help you practice that ‘z’ sound. If you’re up for it, try it again a little bit faster this time and see how you do! This next one is about a woodchuck and is a very common rhyme for children in America. ',
    post5ListItem3: 'WoodChuck',
    post5ListPara5:
      '“How much wood would a woodchuck chuck if a woodchuck could chuck wood? He would chuck, he would, as much as he could and chuck as much wood as a woodchuck would if a woodchuck could chuck wood.”',
    post5ListPara6:
      'That’s a long and challenging one! That one is going to help you practice the ‘uh’ sound. Try it one more time! This last one is about Betty Botter. This one is going to help you practice that double “t” and “d” sound. ',
    post5ListItem4: 'Betty Botter',
    post5ListPara7:
      '“Betty Botter bought some butter, but she said the butter was bitter. If I put it in my batter it will make my batter bitter. But a bit of better butter will make my batter better. So it was better Betty Botter bought a bit of better butter.”',
    post5ListPara8:
      'That one is so tough! Go back and practice each of these as many times as you want. Getting better at these will help you to perfect these different sounds in English. There are many more English tongue twisters that you can use to continue practicing. Just search “English tongue twisters” in Google and you will find many more! I hope this helps you with your pronunciation! Best of luck! ',
    post5Paragraph2: 'Check out the youtube video: ',
    post5VideoLinkText: 'https://youtu.be/2flHA664OA8',
  },
  perpetualLicenseFWRedeemPage: {
    greeting: 'こんにちは',
    enterCode: '{{vendor}}に使うパスワードを下に入力してください。',
    submit: 'パスワードを登録してください。',
    goToAccount: 'アカウントのページに行ってください。',
    createAccountPrompt:
      '{{vendor}}のパスワードを作る為にアカウントを作成して下さい。',
    signUp: '新規登録',
    fallBackErrorMsg:
      'エラーがありました。もう一度やってみてください。サポートリクエスト報告してください。',
    invalidCode:
      '入力された{{vendor}}コードは正しくありません。もう一度入力してください。',
  },
  paymentSuccessPage: {
    thankYou: 'いらっしゃいませ',
    startExploring:
      'FluentWorldsを早速ダウンロードして、3Dで言語を学びましょう！',
    haveQuestions:
      '質問があれば、<0>support@fluentworlds.com</0>の方にメールして下さい。',
    goToAccount: 'アカウントのページに行ってください。',
  },
  paymentCancelPage: {
    cancelMsg: 'Cancelling checkout',
    noCharge:
      'You will not be charged since you cancelled in-between the checkout process.',
    goToAccount: 'GO BACK TO ACCOUNT',
    goBack: 'GO BACK',
  },
  accountPage: {
    manageSubscriptions: '定期購入の管理',
    accountSettings: 'アカウントの設定',
    updateUsername: 'ユーザーネームのアップデート',
    confirmUsernameChange: 'Are you sure you want to change your username?', // TODO
    successUsernameChange: 'You have successfully changed your username', // TODO
    invalidUsernameLengthError:
      'Please enter a username between {{min}} and {{max}} characters', // TODO
  },
  supportPage: {
    title: 'Contact Us | App Support | FluentWorlds', // TODO
    description: '',
    header: 'Support',
    contactUs: 'You can contact us if you have any questions.', // TODO
    formSubmitted: 'Your message has been sent', // TODO
    formName: 'Name *', // TODO
    formEmail: 'Email *', // TODO
    formMessage: 'Message *', // TODO
    formSubmit: 'Submit', // TODO
  },
  downloadFluentworlds: {
    downloadPrompt: 'FluentWorldsのアプリをダウンロードして下さい。',
  },
  subscriptionProducts: {
    fluentworldsSubscriptionDescription:
      'いつでもあるようなシナリオで実体験しているように英語を学んでみましょう。',
    perfectaccentSubscriptionDescription:
      'Perfect your pronunciation and expand your specialized vocabulary.', // TODO
    bundleSubscriptionDescription:
      'Unlimited access to FluentWorlds and PerfectAccent.', // TODO
    buy: '購入',
    manage: '管理',
    freeTrial: '7 Day Free Trial', //TODO
    after: 'after', //TODO
  },
  subscriptionProvider: {
    fallBackErrorMsg:
      'エラーがありました。もう一度やってみてください。サポートリクエスト報告してください。',
  },
  digitalGoIndex: {
    header1: 'Thank God for FluentWorlds! Thank you for creating it!',
    button: 'Get Access Now!',
    theWorldIsNotFlat: "The World isn't Flat.",
    languageLearning: "Language learning shouldn't be either.",
    whyFluentWorlds: {
      header: 'Why FluentWorlds?',
      p1: '4.9 Stars on iTunes with over 2000 ratings!',
      p2: 'Over 1 Million downloads of FluentWorlds products!',
      p3: 'Built-in voice recognition and analysis!',
      p4: 'Learn in real-life situations!',
      p5: 'Fast-track to learn English!',
    },
    features: 'Features',
    startUsingFluentWorlds: 'Start using FluentWorlds Now!',
    immersive:
      'Learn English in an immersive 3D system, as you explore everyday scenarios.',
    downloadOn: 'Download on',
  },
  testimonySection: {
    fastTrack: 'Fast-Track to Learn English',
    hear: 'Hear what our customers are saying',
    testimony1:
      '"Every week, I am making tremendous progress on my English conversation. FluentWorlds is the fastest and more enjoyable way to learn English!" - Leonardo',
    testimony2:
      '  "I have tried Duolingo and Babbel but I really couldn\'t keep it up, because it\'s very dull and I start forgetting words. They are a flat learning journey. FluentWorlds is very unique to me, and I think it will be a disruption! I definitely feel more engaged to be in a 3D environment" - Ana',
    testimony3:
      '"I was testing the FluentWorlds app and I find it incredible! It\'s very useful and fun! It has been of great help to me to expand my vocabulary... It\'s amazing, thank you so much!" - Juan',
  },
  freeTrialCallToActionButton: {
    tryForFree: 'Try FluentWorlds 7 Days For Free', //TODO
    alreadyHaveProductAccess: 'You already have access to the product!', //TODO
  },
  youtubeEmbedded: {
    title: 'YouTube video player',
  },
  countdown: {
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    countdownFinished: "Time's up!",
  },
  becomeAmbassador: {
    title: 'Become an Ambassador | FluentWorlds',
    description: 'What is to be an FluentWorlds Ambassador',
    joinSectionHeader: 'Join Our Ambassador Program!',
    joinSectionText:
      'We are so excited that you are interested in joining our community of learners and helping others improve their lives through learning a new language in the Metaverse. Plus, it is free to join!',
    joinSectionButton: 'Demo our products or learn more here',
    oneOnOneSectionTitle: 'Demo Our Products',
    oneOnOneSectionWebinarHeader: 'Live Online Webinar',
    oneOnOneSectionWebinarSubHeader: '(Preferred Option)',
    oneOnOneSectionWebinarButtonLabel: 'Sign Up',
    oneOnOneSectionDemoHeader: 'One on One Demo',
    oneOnOneSectionDemoSubHeader: '(Back Up Option)',
    oneOnOneSectionDemoButtonLabel: 'Sign Up',
    oneOnOneSectionText:
      'Choose one of the following options. Our Live Webinar option offers a meeting with fellow ambassadors and includes a 3 month subscription to our products. One on One option includes 1 month free subscription to our products.',
    conditionSectionHeader: 'Why Be An Ambassador?',
    conditionFirstColumnHeader: '20%-30% Commission',
    conditionFirstColumnText:
      'Supplement your income by making up to 30% Commission',
    conditionSecondColumnHeader: 'Exclusive Discounts',
    conditionSecondColumnText:
      'As as an ambassador, you get exclusive discounts to save on your personal purchases and for your customers',
    conditionThirdColumnHeader: 'Insider Access',
    conditionThirdColumnText:
      "You'll receive exclusive updates and receive a free 3 month subscription to our Perfect Accent and FluentWorlds Apps",
    conditionForthColumnHeader: 'Features and Shoutouts',
    conditionForthColumnText:
      "We'll showcase your content on our social media and spotlight ambassadors to our community",
    applyBtnLabel: 'Apply Now',
    overviewHeader: 'Our Ambassador Program Overview',
    learningOnline: 'Learning Online',
    howToApply: 'How To Apply',
    howToApplySteps: [
      {
        header: 'Click The Button Below',
        text:
          'Click the "Apply Now" button below. This will take you to our ambassador program application form.',
      },
      {
        header: 'Fill Out The Application Form',
        text: 'Complete the form. We review each application very carefully!',
      },
      {
        header: 'Fill Out Payment Information',
        text:
          "You won't be charged anything, we just want your preferred method of payment for your future commissions.",
      },
      {
        header: 'Wait For An Email From Us!',
        text:
          "If you're accepted to the program, you'll receive an email from us with instructions, so be on the lookout!",
      },
    ],
    signInSectionText:
      'Already a FluentWorlds Ambassador? Log in to your account here.',
    signInSectionBtnLabel: 'Sign In',
    questionsSectionText:
      'If you have any questions or want more information before joining, please email',
  },
  footer: {
    home: 'Home',
    products: 'Products',
    affiliate: 'Become An Affiliate',
    myAccount: 'My Account',
    allRightsReserved: 'All Rights Reserved',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
  },
  appActions: {
    initialMessage: '取り扱い要求',
    returningToApp:
      'アプリに戻ります。アプリが読み込まれたら、このページを閉じることができます',
    unknownApp: '不明なアプリが指定されています',
    tokenError: 'トークンを取得できませんでした',
    checkingStatus: 'ステータスを確認します',
    actionStartLogin: 'ログインにリダイレクトする...',
    actionCompleteLogin: '情報を取得します',
    actionStartLogout: ' ログアウト...',
    actionStartManage: ' アカウントへのリダイレクト...',
    actionStartDefault:
      '予期せぬ状態で発生しました。もう一度やり直してください。',
  },
}

export default ja
